import React from 'react'
import memoize from 'promise-memoize'
import { useSelector, useDispatch } from 'react-redux'
import {
  setSelfTourInitialized,
  getSelfTourSuccess,
  getSelfTourFailure,
  addUnitToFavorites,
  removeUnitFromFavorites,
  setUserHasSignedSelfTourAgreement,
  setUserVerificationComplete,
  setUserMoveInDates,
  setUserMoveInDatesFailure,
  setOccupants,
  setOccupantsFailure,
  setBedAndBaths,
  setBedAndBathsFailure,
  setMaxPrice,
  setMaxPriceFailure,
} from './self-tour.reducer'
import {
  selectTourInitialized,
  selectGuestCardUuid,
  selectCommunity,
  selectPrimaryProspect,
} from './store'
import { setPureChatId } from './pure-chat.reducer'
import {
  useSelfTourAPIClient,
  useLocalStorage,
  selfTourAgreementKey,
} from '../service'
import { select } from './self-tour.selectors'

export const useSelfTour = (uuid) => {
  const initialized = useSelector(selectTourInitialized)
  const api = useSelfTourAPIClient()
  const dispatch = useDispatch()
  const localStore = useLocalStorage()

  React.useEffect(() => {
    if (!initialized && uuid) {
      api
        .get(uuid)
        .then((tour) => {
          // Check to see if the user has previously signed a self tour agreement
          // for this guest card. Not all guest cards require an agreement but
          // we always check before rendering the app.
          const userHasSignedSelfTourAgreement = !!localStore.getItem(
            selfTourAgreementKey(uuid)
          )

          dispatch(
            getSelfTourSuccess({
              guestCardUuid: uuid,
              tour: tour,
              userHasSignedSelfTourAgreement,
            })
          )

          // Set the PureChat id on the chat slice of the store.
          dispatch(setPureChatId(tour.config.pureChatClientId))

          dispatch(setSelfTourInitialized({}))

          api.postUserAccessTracker(uuid)
        })
        .catch((error) => {
          dispatch(getSelfTourFailure(error))
        })
    } else {
      dispatch(
        getSelfTourFailure(new Error('You must specify a guest card UUID'))
      )
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}

/**
 * Store the user's acknowledgment of the self tour agreement
 * in the user's device local storage.
 */
export function useSignSelfTourUserAgreement() {
  const guestCardUuid = useSelector(selectGuestCardUuid)
  const dispatch = useDispatch()
  const localStore = useLocalStorage()

  return () => {
    if (guestCardUuid) {
      // Save the agreement to local storage.
      localStore.setItem(selfTourAgreementKey(guestCardUuid), true)

      // Update the store that the user has signed.
      dispatch(setUserHasSignedSelfTourAgreement(true))
    } else {
      console.warn(
        'Cannot save Self Tour User Agreement because Guest Card UUID does not exist.'
      )
    }
  }
}

export function useSaveUserIdVerification() {
  const initialized = useSelector(selectTourInitialized)
  const guestCardId = useSelector(selectGuestCardUuid)
  const user = useSelector(selectPrimaryProspect)
  const api = useSelfTourAPIClient()
  const dispatch = useDispatch()

  return React.useCallback(
    (inquiryId) => {
      if (initialized) {
        return api
          .setUserVerificationComplete(guestCardId, user.id, inquiryId)
          .then((response) => {
            console.log('Saved user verification id', inquiryId, response)
            dispatch(setUserVerificationComplete(inquiryId))
            // Also return the response in case the caller wants to know when the
            // request is completed.
            return response
          })
      } else {
        return Promise.reject(
          `Unable to store user id verification because the self tour has not been initialized yet.`
        )
      }
    },
    [initialized, api, guestCardId, dispatch, user?.id]
  )
}

/**
 * @callback OptimisticAPICallback
 * @param {object} api - The self tour service API instance.
 * @param {function} dispatch - The dispatch function from the redux store.
 * @param {string} guestCardUuid - The uuid of the user's guest card.
 * @param {*} data - Any data you've passed to the function returned by your hook.
 */
/**
 * Create a hook that can be called to make an optimistic API request.
 * The hook will call your start function which should return a request
 * Promise. If the promise rejects, then your reject function will be
 * called.
 *
 * The returned hook works as follows:
 * 1. Returns a function you can call to make the desired request. This
 *    function is memoized so it only makes the API request if the parameters
 *    have changed since the last request. Otherwise, you get the previous
 *    results without making the API request.
 * 2. The returned function starts by cancelling the previous request if
 *    there is one still in flight.
 * 3. Then it calls your `start` function which should return a Promise.
 * 4. If your promise rejects, the function will call your reject callback.
 *    Otherwise, it just cleans up its internal state before passing you
 *    the results.
 *
 * @param {OptimisticAPICallback} start - A function that will be called to start
 *   the optimistic request to the server. You will receive the API
 *   instance, the dispatch method so you can dispatch the optimistic
 *   update to the store, the guest card UUID and the data passed when
 *   the function was called.
 * @param {OptimisticAPICallback} reject - A function that will be called if the
 *   API request fails. You will receive the API instance, dispatch
 *   method so you can roll back the optimistic update, the guest card
 *   UUID, the data passed when the function was called and the error.
 * @returns {function} A hook function that you can use in your components.
 *   The hook returns a function that you can call to make the API
 *   request.
 */
function makeOptimisticAPIHook(start, reject) {
  let lastRequest = null

  return (apiParam, dispatchParam, guestCardUuidParam) => {
    let guestCardUuid = useSelector(selectGuestCardUuid)
    let dispatch = useDispatch()
    let api = useSelfTourAPIClient()

    // Allow passing the dependencies during testing in
    // such a way that the hooks are still called on each render.
    if (apiParam) api = apiParam
    if (dispatchParam) dispatch = dispatchParam
    if (guestCardUuidParam) guestCardUuid = guestCardUuidParam

    return memoize(
      (data) => {
        // Cancel the last request if there was one.
        if (lastRequest && lastRequest.cancel) {
          lastRequest.cancel()
          console.debug(`[${start.name}] Cancelled previous request.`)
        }

        lastRequest = start(api, dispatch, guestCardUuid, data)

        return lastRequest
          .then((result) => {
            lastRequest = null
            return result
          })
          .catch((error) => {
            lastRequest = null

            reject(api, dispatch, guestCardUuid, data, error)

            console.error(`[${start.name}] Request failed`, error)
            throw error
          })
      },
      { resolve: 'json' }
    )
  }
}

/**
 * @callback FavoriteUnitCallback
 * @param {object} data
 * @param {number} data.unitId
 */
/**
 * Returns a function you can use to add a unit to the user's list of favorites.
 * @returns {FaovriteUnitCallback} A function you can call to make
 * the API request. You should pass an object with a `unitId` property
 * when calling it.
 */
export const useAddUnitToFavorites = makeOptimisticAPIHook(
  // Optimistically add the unit to the user's favorites and
  // then make the API request to do the same.
  (api, dispatch, guestCardUuid, { unitId }) => {
    dispatch(addUnitToFavorites({ unitId }))
    return api.addUnitToFavorites(guestCardUuid, unitId)
  },
  // If the API request fails, rollback the optimistic
  // update to the store.
  (api, dispatch, guestCardUuid, { unitId }, error) => {
    dispatch(removeUnitFromFavorites({ guestCardUuid, unitId, error }))
  }
)

/**
 * Returns a function you can use to remove a unit to the user's list of favorites.
 * @returns {FavoriteUnitCallback} A function you can call to make
 * the API request. You should pass an object with a `unitId` property
 * when calling it.
 */
export const useRemoveUnitFromFavorites = makeOptimisticAPIHook(
  // Optimistically add the unit to the user's favorites and
  // then make the API request to do the same.
  (api, dispatch, guestCardUuid, { unitId }) => {
    dispatch(removeUnitFromFavorites({ unitId }))
    return api.removeUnitFromFavorites(guestCardUuid, unitId)
  },
  // If the API request fails, rollback the optimistic
  // update to the store.
  (api, dispatch, guestCardUuid, { unitId }, error) => {
    dispatch(addUnitToFavorites({ guestCardUuid, unitId, error }))
  }
)

/**
 * @callback MoveInDatesCallback
 * @param {object} data
 * @param {string} data.earliestMoveIn - The earliest date the user can move.
 * @param {string} data.lastestMoveIn - The latest date the user can move.
 * @returns {Promise<object>} Resolves to the API response. However, it
 * will also dispatch the results to the Redux store so you should not
 * need the response.
 */
/**
 * Returns a function that can be used to store the user's
 * move in date preferences.
 *
 * @returns {MoveInDatesCallback}
 */
export const useSetMoveInDates = makeOptimisticAPIHook(
  (api, dispatch, guestCardUuid, data) => {
    dispatch(setUserMoveInDates(data))
    return api.setGuestCardDetails(guestCardUuid, data)
  },
  (api, dispatch, guestCardUuid, data, error) => {
    dispatch(setUserMoveInDatesFailure({ guestCardUuid, ...data, error }))
  }
)

/**
 * @callback OccupantsCallback
 * @param {object} data
 * @param {number} data.totalTenants - Total tenants moving in.
 * @param {number} data.totalDogs - Total Dogs the user has.
 * @param {number} data.totalCats - Totoal cats the user has.
 * @param {number} data.totalOtherAnimals - Total other animals the user has.
 * @returns {Promise<object>} Resolves to the API response. However, it
 * will also dispatch the results to the Redux store so you should not
 * need the response.
 */
/**
 * Returns a function that can be used to store the user's
 * occupant preferences.
 *
 * @returns {OccupantsCallback}
 */
export const useSetOccupants = makeOptimisticAPIHook(
  (api, dispatch, guestCardUuid, data) => {
    dispatch(setOccupants(data))
    return api.setGuestCardDetails(guestCardUuid, data)
  },
  (api, dispatch, guestCardUuid, data, error) => {
    dispatch(setOccupantsFailure({ guestCardUuid, ...data, error }))
  }
)

/**
 * @callback BathroomCallback
 * @param {object} data
 * @param {number} data.bedBathIds - Id's of plans that match the users preferences.
 * @returns {Promise<object>} Resolves to the API response. However, it
 * will also dispatch the results to the Redux store so you should not
 * need the response.
 */
/**
 * Returns a function that can be used to store the user's
 * bed and bath preferences.
 *
 * @returns {BathroomCallback}
 */
export const useSetBedBathIds = makeOptimisticAPIHook(
  (api, dispatch, guestCardUuid, data) => {
    dispatch(setBedAndBaths(data))
    return api.setGuestCardDetails(guestCardUuid, data)
  },
  (api, dispatch, guestCardUuid, data, error) => {
    dispatch(setBedAndBathsFailure({ guestCardUuid, ...data, error }))
  }
)

export function useFloorPlanFilter() {
  const community = useSelector(selectCommunity)
  const initalFloorPlans = select.floorPlans({ data: { community } })
  const [filteredFloorPlans, setFilteredFloorPlans] =
    React.useState(initalFloorPlans)
  const allFloorPlans = initalFloorPlans

  return {
    getFiltedFloorPlans: React.useCallback(
      (filter) => {
        setFilteredFloorPlans(
          select.floorPlans({ data: { community } }, filter)
        )
        return filteredFloorPlans
      },
      [filteredFloorPlans, setFilteredFloorPlans, community]
    ),
    filteredFloorPlans,
    allFloorPlans,
  }
}

/**
 * @callback MaxPriceCallback
 * @param {object} data
 * @param {string} data.maxRent - The highest rent preference.
 * @returns {Promise<object>} Resolves to the API response. However, it
 * will also dispatch the results to the Redux store so you should not
 * need the response.
 */
/**
 * Returns a function that can be used to store the user's
 * rent preferences.
 *
 * @returns {MaxPriceCallback}
 */
export const useSetMaxPrice = makeOptimisticAPIHook(
  (api, dispatch, guestCardUuid, data) => {
    dispatch(setMaxPrice(data))
    return api.setGuestCardDetails(guestCardUuid, data)
  },
  (api, dispatch, guestCardUuid, data, error) => {
    dispatch(setMaxPriceFailure({ guestCardUuid, ...data, error }))
  }
)
