import { Provider } from 'react-redux'

import {
  env,
  SelfTourAPIProvider,
  LocalStorageProvider,
  Analytics,
  AnalyticsProvider,
  UUID,
} from '~/service'
import SelfTourService from '~/service/self-tour.service'
import store from '~/store'
import { findRouteMatchingLocation } from '~/pages'

import Main, { MainConnectedProps } from './Main'

export interface WithServerProps extends MainConnectedProps {
  guestCardUuid?: UUID
  selfTourAPI?: SelfTourService
  localStorage?: Storage
  location?: Location
  analytics?: Analytics
}

export function WithServer({
  selfTourAPI,
  localStorage = window.localStorage,
  location = window.location,
  guestCardUuid,
  analytics = new Analytics(),
  ...rest
}: WithServerProps) {
  const route = findRouteMatchingLocation(location.pathname)
  if (!guestCardUuid) guestCardUuid = (route?.match.params as any).uuid

  return (
    <AnalyticsProvider value={analytics}>
      <SelfTourAPIProvider
        value={selfTourAPI || new SelfTourService(env.verbose)}
      >
        <LocalStorageProvider value={localStorage}>
          <Provider store={store}>
            <Main guestCardUuid={guestCardUuid} {...rest} />
          </Provider>
        </LocalStorageProvider>
      </SelfTourAPIProvider>
    </AnalyticsProvider>
  )
}
