import React from 'react'
import PropTypes from 'prop-types'

import { combineClasses } from '~/util'

import styles from './HTMLPin.module.scss'

export function HTMLPin({ className, children, ...rest }) {
  return (
    <div
      data-testid="HTMLPin"
      className={combineClasses(styles.HTMLPin, className)}
      {...rest}
    >
      <div className={styles.label}>{children}</div>
    </div>
  )
}

HTMLPin.propTypes = {
  /**
   * Generally you should pass a char as the
   * child data to display inside the pin.
   */
  children: PropTypes.node.isRequired,
}
