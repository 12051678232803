import React from 'react'
import PropTypes from 'prop-types'

import { combineClasses } from '~/util'

import styles from './FloorPlanFeaturesForm.module.scss'
import { OptionsSelector } from '~/components/options-selector'
import { Title } from '~/components/titles'

/**
 * `<FloorPlanFeaturesForm>`
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} [props.title]
 * @param {array} [props.selectedFloorPlanFeatures]
 * @param {function} [props.setSelectedFloorPlanFeatures]
 * @param {array} [props.communityFeatures]
 * @param {string} [props.groupName]
 */
export function FloorPlanFeaturesForm({
  className,
  title = 'Select the features you would like in a floor plan?',
  selectedFloorPlanFeatures,
  setSelectedFloorPlanFeatures,
  communityFeatures,
  groupName = 'FloorPlanFeaturesForm',
  ...rest
}) {
  //TODO DS ADD PAGINATION
  const firstTenFeatures = []
  communityFeatures?.forEach((feature, index) => {
    if (index < 15) firstTenFeatures.push(feature)
  })
  return (
    <div
      data-testid="FloorPlanFeaturesForm"
      className={combineClasses(styles.FloorPlanFeaturesForm, className)}
      {...rest}
    >
      <Title data-testid="title" className={styles.title}>
        {title}
      </Title>
      <OptionsSelector
        groupName={groupName}
        options={firstTenFeatures}
        value={selectedFloorPlanFeatures}
        onChange={setSelectedFloorPlanFeatures}
        isMultiSelect={true}
      />
    </div>
  )
}

FloorPlanFeaturesForm.propTypes = {
  communityFeatures: PropTypes.array,
}
