import React from 'react'
import PropTypes from 'prop-types'

import { MapFloorPlatePropType } from '~/store'
import { combineClasses } from '~/util'
import { NextAction, PreviousAction } from '../../buttons'
import { SmallTitle } from '../../titles'
import { ANALYTICS_CATEGORIES } from '~/service'

import styles from './MapSwitcher.module.scss'

/**
 * A component that will render different child content based on
 * the "active" prop.
 * @param {object} props
 * @param {boolean}  [props.active]
 * @param {object[]} [props.maps]
 * @param {number}   [props.loaderDelay]
 * @param {string}   [props.className]
 * @param {*}        [props.children]
 * @param {object}   [props.firstChildRef] - A reference to the first
 *   focusable element in the map. This is used to make the map a
 *   focus trap if it is expanded to cover the page.
 */
export function MapSwitcher({
  active = 0,
  maps,
  loaderDelay = 3000,
  className,
  children,
  firstFocusableRef,
  ...rest
}) {
  const [index, setActive] = React.useState(active)
  const map = maps[index]

  const onPrevious = () => {
    setActive(index === 0 ? maps.length - 1 : index - 1)
  }

  const onNext = () => {
    setActive(index === maps.length - 1 ? 0 : index + 1)
  }

  return (
    <div
      name="mapSwitcher"
      className={combineClasses(styles.MapSwitcher, className)}
      {...rest}
    >
      <div className={combineClasses(styles.mapWrapper, 'visible')}>
        {children({
          index,
          firstFocusableRef,
          ...map,
        })}
      </div>
      <div name="controls" className={styles.controls}>
        {maps.length > 1 && (
          <PreviousAction
            name="previous"
            button
            transparent
            unrounded
            size="l"
            className={styles.previous}
            aria-label="Previous Floor"
            onClick={onPrevious}
            category={ANALYTICS_CATEGORIES.MAP}
            action="Switch Floor"
            label="Previous Floor"
          />
        )}
        <div className={styles.mapTitle} name="mapTitle">
          <SmallTitle className={styles.floorName}>
            {map.floorPlate.name}
          </SmallTitle>
        </div>
        {maps.length > 1 && (
          <NextAction
            name="next"
            button
            transparent
            unrounded
            size="l"
            className={styles.next}
            aria-label="Next Floor"
            onClick={onNext}
            category={ANALYTICS_CATEGORIES.MAP}
            action="Switch Floor"
            label="Next Floor"
          />
        )}
      </div>
    </div>
  )
}

MapSwitcher.propTypes = {
  /**
   * The index of the initially selected map.
   */
  active: PropTypes.number,
  /**
   * The list of floor plates to show. @see `MapFloorPlatePropType`
   */
  maps: PropTypes.arrayOf(MapFloorPlatePropType).isRequired,
  /**
   * How long to keep the loader visible after the content
   * has rendered. This allows you to fade your content in
   * over the loader. Also useful during testing to immediately
   * remove the loader.
   */
  loaderDelay: PropTypes.number,
  /**
   * A callback that receives a properties object with the following:
   * @param {number} index - The index of the current map
   * @param {FloorPlatePropType} floorPlate
   * @param {MarkerPropType[]} markers
   * @param {MarkerPropType[]} units
   * @param {MarkerPropType[]} amenities
   */
  children: PropTypes.func.isRequired,
}
