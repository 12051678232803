import React from 'react'
import PropTypes from 'prop-types'
import { combineClasses } from '~/util'
import { IFrameModal } from '../modal'
import { Action } from '../buttons'
import ThreeDSVG from '~/assets/icons/3D.svg?react'
import styles from './IframeActionLink.module.scss'

/**
 * `<IframeActionLink>`
 * Displays External links within an IframeActionLink.
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} [props.title]
 * @param {string} [props.src]
 * @param {React.ReactElement} [props.buttonIcon]
 * @param {string} [props.buttonText]
 * @param {object} [props.actionRest]
 * @param {object} [props.modalProps]
 * @param {object} [props.iframeProps]
 */
export function IframeActionLink({
  className,
  title,
  src,
  buttonIcon = <ThreeDSVG />,
  buttonText = '3D Virtual Tour',
  actionRest,
  modalProps,
  iframeProps,
}) {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <>
      <div className={styles.btnContainer}>
        <Action
          className={combineClasses(styles.iframeButton, className)}
          data-testid="iframeButton"
          icon={buttonIcon}
          button
          feel="button"
          display="primary"
          onClick={() => setIsOpen(true)}
          {...actionRest}
        >
          {buttonText}
        </Action>
      </div>

      <IFrameModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        label={title}
        url={src}
        iframeProps={iframeProps}
        {...modalProps}
      />
    </>
  )
}

IframeActionLink.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  src: PropTypes.string.isRequired,
  buttonIcon: PropTypes.element,
  buttonText: PropTypes.string,
  actionRest: PropTypes.object,
  modalProps: PropTypes.object,
  iframeProps: PropTypes.object,
}
