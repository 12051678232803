import * as React from "react";
const SvgLoader = (props) => /* @__PURE__ */ React.createElement("svg", { id: "loader", className: "loader page", width: "100%", height: "100%", viewBox: "0 0 47 47", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.41421
}, ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { className: "background", id: "background", d: "M38.264,46.828l-29.503,0c-4.805,0 -8.737,-3.919 -8.737,-8.709l0,-29.41c0,-4.79 3.932,-8.709 8.737,-8.709l29.503,0c4.805,0 8.736,3.919 8.736,8.709l0,29.41c0,4.79 -3.931,8.709 -8.736,8.709" }), /* @__PURE__ */ React.createElement("path", { className: "circleLine", id: "circleLine", style: {
  fill: "none",
  strokeWidth: 4
}, d: "M34.03,14.374c0.173,0.16 4.235,3.971 4.293,10.248c0.036,3.837 -1.609,7.802 -4.398,10.609c-2.68,2.697 -6.205,4.133 -10.198,4.155l-0.095,0c-9.643,0 -14.845,-7.598 -14.845,-14.747c0,-6.108 4.031,-10.112 4.203,-10.279" }), /* @__PURE__ */ React.createElement("path", { className: "line1", id: "line1", d: "M20.291,9.074l-2.761,-1.093c-0.215,-0.084 -0.46,-0.058 -0.653,0.072c-0.192,0.13 -0.307,0.347 -0.307,0.579l0,17.392c0,0.386 0.314,0.699 0.701,0.699l2.762,0c0.387,0 0.701,-0.313 0.701,-0.699l0,-16.3c0,-0.286 -0.175,-0.544 -0.443,-0.65" }), /* @__PURE__ */ React.createElement("path", { className: "line2", id: "line2", d: "M25.202,4.792l-2.763,-1.093c-0.214,-0.084 -0.46,-0.058 -0.652,0.072c-0.192,0.13 -0.307,0.346 -0.307,0.578l0,21.675c0,0.386 0.314,0.7 0.701,0.7l2.762,0c0.388,0 0.701,-0.314 0.701,-0.7l0,-20.582c0,-0.287 -0.175,-0.545 -0.442,-0.65" }), /* @__PURE__ */ React.createElement("path", { className: "line3", id: "line3", d: "M30.112,9.074l-2.762,-1.093c-0.216,-0.084 -0.46,-0.058 -0.653,0.072c-0.192,0.13 -0.307,0.347 -0.307,0.579l0,17.392c0,0.386 0.313,0.699 0.701,0.699l2.762,0c0.387,0 0.701,-0.313 0.701,-0.699l0,-16.3c0,-0.286 -0.175,-0.544 -0.442,-0.65" })));
export default SvgLoader;
