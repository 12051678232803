// import { Set } from 'global';

// Firefox throws an error when using dateStyle and year/month/day together
// so test for support here.
const supportsDateStyle = (function () {
  try {
    return !!new Intl.DateTimeFormat(undefined, {
      dateStyle: 'short',
      year: '2-digit',
    })
  } catch (e) {
    return false
  }
})()

/**
 * Takes a DateObject and returns a date string of that object with no timezone.
 * This mimics the Dates returned from the server.
 *
 * @param {Date} dateObject date object.
 * @returns {string} retuns a date string with no time zone.  Ex: YYYY-MM-DD
 */
export function getDateStringNoTimeZoneFromDate(dateObject) {
  //month is +1 becuase month count starts at 0.
  return `${dateObject.getFullYear()}-${
    dateObject.getMonth() + 1
  }-${dateObject.getDate()}`
}

/**
 * Pad a number with leading 0s if needed.
 */
const padTime = (t) => {
  t = Number(t)
  if (isNaN(t)) t = 0
  let p = '00' + t
  return p.substr(p.length - 2)
}

/**
 * Convert a GMT timezone offset like GMT-6
 * to an ISO timezone offset like -06:00
 */
const gmtToISO = (timezone) => {
  const asString = timezone.split('GMT')[1]
  const abs = asString.replace('+', '').replace('-', '')
  const prefix = Number(asString) < 0 ? '-' : '+'
  return prefix + padTime(abs) + ':00'
}

/**
 * Convert a timezone-less ISO date (YYYY-MM-DD) into a date/time
 * relative to the given GMT timezone string.
 * @param {string} date - The ISO date without timezone (ie. 'YYYY-MM-DD')
 * @param {string} timezone - The GMT timezone offset (ex. 'GMT-7')
 * @returns {string}
 */
export function addTimezoneToDate(
  date,
  timezone = 'GMT+0',
  hours = 0,
  minutes = 0,
  seconds = 0
) {
  return new Date(
    date +
      `T${padTime(hours)}:${padTime(minutes)}:${padTime(seconds)}` +
      gmtToISO(timezone)
  ).toISOString()
}

/**
 * Format a date string for display.
 *
 * @param {string} date The date string to parse and format.
 * @param {string} [locale] This should only be passed during testing
 *   unless you have a really good reason to specify the locale.
 * @returns {string} The formatted date string or else an empty string if the date is falsy.
 */
export function formatDateShort(
  date,
  locale = navigator.language,
  dateStyle = 'short'
) {
  if (!date) {
    return ''
  }
  const fallbackOptions = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
  }

  if (dateStyle === 'long') {
    fallbackOptions.year = 'numeric'
    fallbackOptions.weekday = 'short'
    fallbackOptions.month = 'long'
  }

  const options = supportsDateStyle
    ? { dateStyle, ...fallbackOptions }
    : fallbackOptions

  const formatter = new Intl.DateTimeFormat(locale, options)
  return formatter.format(new Date(date))
}

/**
 * Format a date string for display as time only.
 *
 * @param {string|Date} date The date string to parse and format.
 * @param {string} [locale] This should only be passed during testing.
 * @param {string} [timeZone] This should only be passed during testing.
 * @returns {string} The fromatted date string or else an empty string if the date is falsy.
 */
export function formatTime(date, locale = navigator.language, timeZone) {
  const options = {
    timeStyle: 'short',
  }
  if (timeZone) {
    options.timeZone = timeZone
  }
  const formatter = new Intl.DateTimeFormat(locale, options)
  return formatter.format(new Date(date))
}

/**
 * Format a date string Ex. Tuesday May, 29th
 *
 * @param {string|Date} date The date string to parse and format.
 * @param {string} [locale] This should only be passed during testing.
 * @param {string} [timeZone] This should only be passed during testing.
 * @returns {string} The fromatted date string or else an empty string if the date is falsy.
 */
export function formatDate(date, locale = navigator.language, timeZone) {
  const options = { weekday: 'long', month: 'long', day: 'numeric' }
  if (timeZone) {
    options.timeZone = timeZone
  }
  let result = new Date(date).toLocaleDateString(locale, options)
  return result !== 'Invalid Date' ? result : undefined
}

/**
 * Format a date string Ex. Tuesday May, 29th 9.00AM
 *
 * @param {string|Date} date The date string to parse and format.
 * @param {string} [locale] This should only be passed during testing.
 * @param {string} [timeZone] This should only be passed during testing.
 * @returns {string} The fromatted date string or else an empty string if the date is falsy.
 */
export function formatDateWithTime(
  date,
  locale = navigator.language,
  timeZone
) {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  if (timeZone) {
    options.timeZone = timeZone
  }
  let result = new Date(date).toLocaleDateString(locale, options)
  return result !== 'Invalid Date' ? result : undefined
}

/**
 * Determines if a date is between two dates inclusively.
 *
 * @param {string|Date} date The date string to check.
 * @param {string} minDate The start of the date range.
 * @param {string} maxDate The end of the date range.
 */
export function isDateBetween(date, minDate, maxDate) {
  const time = new Date(date).getTime()
  const minTime = new Date(minDate).getTime()
  const maxTime = new Date(maxDate).getTime()
  if (minTime > maxTime) {
    console.warn('The minDate should be before the maxDate')
    return false
  }
  return minTime <= time && time <= maxTime
}

/**
 * Determines if the dates are equal.
 *
 * @param {*} date - date to compare to now.
 * @returns {boolean}
 */
export const isDateToday = (date) => {
  const today = new Date()
  const dateToCompare = new Date(date)
  if (
    today.getDay() === dateToCompare.getDay() &&
    today.getDate() === dateToCompare.getDate() &&
    today.getFullYear() === dateToCompare.getFullYear()
  ) {
    return true
  } else {
    return false
  }
}

/**
 * Format a currency value for display.
 *
 * @param {number} pennies - The value in pennies to parse into a currency.
 * @param {string} currency - The currency to use (ex. USD, GBP).
 * @param {string} [locale] - This should only be passed during testing
 *   unless you have a really good reason to specify the locale.
 */
export function formatCurrency(
  pennies,
  currency = 'USD',
  locale = navigator.language
) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(pennies / 100)
}

/**
 * Format a number for display in the user's locale (ex 1,000.00)
 */
export function formatNumber(value, locale = navigator.language) {
  const num = new Intl.NumberFormat(locale)
  return num.format(value)
}

/**
 * Format a number of beds into a string for display.
 */
export function formatBeds(beds) {
  return beds && beds > 0 ? `${beds} bd` : 'Studio'
}

/**
 * Format a number of baths into a string for display.
 */
export function formatBaths(baths) {
  return baths ? baths + ' ba' : ''
}

/**
 * Format a square footage into a string for display.
 */
export function formatFootage(feet) {
  return feet ? formatNumber(feet) + ' sqft' : ''
}

/**
 * Format a lease term in months.
 */
export function formatLeaseTerm(months, locale = navigator.language) {
  const duration = months > 1 ? 'months' : 'month'
  return `${months} ${duration}`
}

/**
 * Given a unit object, render a readable name with
 * the apartment number and associated floorplan name
 * and building number. If no floorplan is passed,
 * just the apartment number will be used. If
 * includeBuildingNumber is not specifically set to true,
 * the building number will not be included.
 */
export function formatUnitName(unit, includeBuildingNumber = false) {
  let unitNumber = unit?.unitNumber ? unit?.unitNumber : ''
  let floorPlanName = unit?.floorPlan?.name ? unit?.floorPlan.name : ''

  let name =
    unit?.floorPlan && unit?.floorPlan.name
      ? `${unitNumber} "${floorPlanName}"`
      : unitNumber

  if (
    includeBuildingNumber &&
    unit?.buildingNumber &&
    unit?.buildingNumber.toLowerCase() !== 'n/a'
  ) {
    name = `${unit?.buildingNumber} - ${name}`
  }

  return name
}

export function formatDoorCode(code) {
  // Ensure we don't add the pound sign twice
  return trimChar(String(code), '#') + '#'
}

const DIGITS_ONLY_REGEX = /[^0-9]+/g

/**
 * Given a phone number and type (tel or sms), return an
 * href that can be used to call the number.
 */
export function formatPhoneNumberURL(number, type = '') {
  type = type.toLowerCase()
  const phoneNumberType = type !== 'tel' && type !== 'sms' ? 'tel' : type
  const phoneDigits = String(number).replace(DIGITS_ONLY_REGEX, '')
  return `${phoneNumberType}:${phoneDigits}`
}

/**
 * Ensure that a URL starts with a protocol.
 */
export function formatLink(url) {
  return url.indexOf('//') === -1 ? `http://${url}` : url
}

/**
 * Combine two class strings, removing duplicates and handling
 * empty, null or undefined strings.
 * @returns {string}
 */
export function combineClasses(...classes) {
  // If we recieved 0 or 1 arguments, return immediately.
  if (arguments.length <= 1) return arguments[0]

  const list = classes.reduce((acc, arg) => {
    if (typeof arg === 'string') {
      const c = arg.trim().split(' ')
      if (c.length > 0) acc = acc.concat(c)
    }
    return acc
  }, [])

  if (list.length > 0) {
    return [...new Set(list)].join(' ').trim()
  }
}

/**
 * Escape RegExp special characters in a string.
 * This is useful for constructing dynamic RegExp
 * objects from arbitrary strings.
 * @param {string} s - The string that may contain
 *   special characters.
 * @returns {string}
 */
export function escapeRegexChars(s) {
  return s.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
}

/**
 * Trim the specified character from the begining and end of
 * the given string.
 * @param {string} s - String to trim
 * @param {string} char - Character to trim from strimg
 * @returns {string}
 */
export function trimChar(s, char) {
  char = escapeRegexChars(char)
  const exp = new RegExp(`^${char}+|${char}+$`, 'g')
  return s.replace(exp, '')
}
