/**
 * @typedef {import('~/service').Community} Community
 * @typedef {import('~/service').CommunityConfig} CommunityConfig
 * @typedef {import('~/service').GuestCard} GuestCard
 */

const filterCategoryItems = (items, type, unit) => {
  if (!unit || !unit.quoteCategoryItemIds) {
    return []
  }
  return items.filter(
    (i) => i.feeType === type && unit.quoteCategoryItemIds.indexOf(i.id) > -1
  )
}

const findCategoryItemsByFeeType = (quoteCategories, type, unit) => {
  return quoteCategories.flatMap((c) =>
    filterCategoryItems(c.items, type, unit)
  )
}

export const select = {
  initialized(state) {
    return state.initialized
  },

  notices(state) {
    return state.notices
  },

  uuid(state) {
    return state.guestCardUuid
  },

  /**
   * @returns {GuestCard}
   */
  guestCard(state) {
    return state.data?.guestCard || {}
  },

  guestCardId(state) {
    return select.guestCard(state).id
  },

  appointments(state) {
    return select.guestCard(state).appointments
  },

  primaryProspect(state) {
    return select.guestCard(state).primaryProspect
  },

  /**
   * @returns {Community}
   */
  community(state) {
    return state.data?.community || {}
  },

  communityId(state) {
    return select.community(state).id
  },

  communityName(state) {
    return select.community(state).name
  },

  petPolicy(state) {
    return select.communityConfig(state).petPolicy
  },

  communityLogoUrl(state) {
    return select.communityConfig(state).logoUrl || ''
  },

  communityVoicePhoneNumber(state) {
    return select.communityConfig(state).voicePhoneNumber
  },

  // This is not sent, do we want it?
  communitySmsPhoneNumber(state) {
    return select.community(state).smsPhoneNumber
  },

  // This is not sent, do we want it?
  communitySmsContactName(state) {
    return select.communityConfig(state).smsContactName
  },

  communityAddress(state) {
    return select.communityConfig(state).address
  },

  // See #679
  communityWebsite(state) {
    return select.communityConfig(state).url
  },
  licenseNumber(state) {
    return select.communityConfig(state).licenseNumber
  },

  // This is not sent, do we want it?
  communityEmail(state) {
    return select.communityConfig(state).email
  },

  communityTimeZoneOffset(state) {
    return select.communityConfig(state).timeZoneOffset
  },

  communityPrimaryColor(state) {
    return select.communityConfig(state).primaryColor
  },

  communitySecondaryColor(state) {
    return select.communityConfig(state).secondaryColor
  },

  communityMediaGalleries(state) {
    return select.community(state).mediaGalleries
  },

  amenitiesMediaGallery(state) {
    return (
      select
        .communityMediaGalleries(state)
        ?.filter((g) => g.name === 'Amenities') || []
    )
  },

  /**
   * Select all of the amenity objects in the community.amenities
   * property and merge in their relationships. This list will
   * include all amenities, including special ones like the
   * leasing office. In most cases you will want to use
   * the `communityAmenities`, `amenitiesInTour` or one of the
   * special amenity type selectors like `communityLeasingOffices`.
   */
  // TODO Remove this
  amenitiesWithDetails(state) {
    // return select.community(state).amenities;
    return select.community(state)?.amenities?.map((a) => ({
      ...a,
      // Merge in floorPlate data for ease of use.
      floorPlate: select.floorPlate(state, a.floorPlateId),
      // Merge in special type details
      specialType: a.specialTypeId
        ? select.communityAmenitySpecialType(state, a.specialTypeId)
        : null,
    }))
  },

  /**
   * Get the list of all amenities that
   * should be displayed on the Amenities page.
   * This list is the full list of amenities including
   * any that cannot be shown on the map (ex. "Free Wifi")
   * but excluding any with a specialTypeId.
   */
  communityAmenities(state) {
    return select.community(state)?.communityAmenities || []
  },

  communityAmenitiesFromGuestCardIds(state) {
    let { amenities } = select.community(state)
    let { amenityIds } = select.guestCard(state)
    let result =
      amenities?.filter((amenity) => amenityIds.includes(amenity.id)) || []
    return result
  },

  /**
   * Find a community amenity by its id with its relationships.
   */
  communityAmenity(state, id) {
    return select.community(state)?.communityAmenities?.find((a) => a.id === id)
  },

  /**
   * Types of amenities that should be treated differently
   * such as the leasing office, stairs, elevators, etc.
   * Amenities under communityAmenities may be tagged with
   * one of these special types using the `specialTypeId` flag.
   */
  communityAmenitySpecialTypes(state) {
    return select.community(state).amenitySpecialTypes
  },

  /**
   * Get an amenity special type by its id.
   */
  communityAmenitySpecialType(state, id) {
    return select.communityAmenitySpecialTypes(state)?.find((t) => t.id === id)
  },

  /**
   * The leasing office for this community if there is one configured.
   * It is an amenity under the hood so will have all of the same
   * properties.
   */
  communityLeasingOffices(state) {
    return select.community(state).leasingOffices || []
  },

  communityFeatures(state) {
    return select.community(state).features || []
  },

  communityFeature(state, id) {
    return select.communityFeatures(state).find((f) => f.id === id)
  },

  /** @param {object} state */
  communityConfig(state) {
    // Return an empty config object if none exists so that
    // other selectors don't have to check for it's existance.
    return state.data.config || {}
  },

  communityBedBaths(state) {
    return select.community(state).bedBaths || []
  },

  /** @param {object} state */
  communityHasChat(state) {
    return select.usePureChat(state) || !!select.chatURL(state)
  },

  /** @param {object} state */
  pureChatId(state) {
    return select.communityConfig(state).pureChatClientId
  },

  /** @param {object} state */
  usePureChat(state) {
    return !!select.pureChatId(state)
  },

  /** @param {object} state */
  chatURL(state) {
    return select.communityConfig(state).chatBotUrl
  },

  engrainAssetId(state) {
    return select.communityConfig(state).engrainAssetId
  },

  engrainMapId(state) {
    return select.communityConfig(state).engrainUnitMapId
  },

  useEngrainMaps(state) {
    return !!select.engrainMapId(state)
  },

  isVariableRentEnabled(state) {
    return select.communityConfig(state).isVariableRentEnabled
  },

  isShowPriceOnModel(state) {
    return select.communityConfig(state).isShowPriceOnModel
  },

  /**
   * Determine whether or not to show unit pricing for this community.
   */
  showUnitPrices(state) {
    const isVariableRentEnabled = select.isVariableRentEnabled(state)
    const hasQuotes = select.hasQuotes(state)
    // We can show unit prices if:
    // 1) Variable rent is disabled
    // 2) Variable rent is enabled but the user has no quotes.
    return !isVariableRentEnabled || (isVariableRentEnabled && !hasQuotes)
  },

  unit(state, unitId) {
    return select.units(state).find((u) => u.id === unitId)
  },

  units(state) {
    return select.community(state).units || []
  },

  lockDevice(state, unitId) {
    return select
      .guestCard(state)
      .lockDeviceAccesses.find((f) => f.unitId === unitId)
  },

  /**
   * Select units that are uninhabited and currently available
   * to be toured.
   */
  tourableUnits(state) {
    return select.guestCard(state)?.tourableUnits || []
  },

  shownUnits(state) {
    return select.guestCard(state)?.shownUnits || []
  },

  interestedUnits(state) {
    return select.guestCard(state)?.interestedUnits || []
  },

  isBuildingEnabled(state) {
    return select.communityConfig(state).isBuildingEnabled
  },

  userHasSignedSelfTourAgreement(state) {
    return state.userHasSignedSelfTourAgreement
  },

  selfTourUserAgreement(state) {
    return select.communityConfig(state).selfTourAgreementText
  },

  selfTourUserAgreementTitle(state) {
    return select.communityConfig(state).selfTourAgreementTitle
  },

  selfTourUserAgreementLink(state) {
    return select.communityConfig(state).selfTourAgreementUrl
  },

  selfTourRequiresAgreement(state) {
    return !!select.selfTourUserAgreement(state)
  },

  shouldUserSignSelfTourAgreement(state) {
    return (
      select.selfTourRequiresAgreement(state) &&
      !select.userHasSignedSelfTourAgreement(state)
    )
  },

  /**
   * Select the user's preferred move in dates.
   * @returns {{earliestMoveIn: string, latestMoveIn: string}}
   */
  moveInDates(state) {
    const gc = select.guestCard(state)
    return {
      earliestMoveIn: gc.earliestMoveIn,
      latestMoveIn: gc.latestMoveIn,
    }
  },

  occupants(state) {
    const gc = select.guestCard(state)
    return {
      totalTenants: gc.totalTenants,
      totalDogs: gc.totalDogs,
      totalCats: gc.totalCats,
      totalOtherAnimals: gc.totalOtherAnimals,
    }
  },

  bedBathIds(state) {
    const gc = select.guestCard(state)
    return gc.bedBathIds
  },

  /**
   * Determine if the user is checked in
   * (ie. has completed the check in questions and steps).
   * @returns {boolean}
   */
  checkedIn(state) {
    const gc = select.guestCard(state)
    return (
      !!gc.earliestMoveIn &&
      !!gc.latestMoveIn &&
      !!gc.maxPrice &&
      !!(gc.bedBathIds?.length > 0)
    )
  },

  maxPrice(state) {
    const gc = select.guestCard(state)
    return gc.maxPrice
  },

  /**
   * gets a specific floor plan based on uuid.
   * @param {*} id uuid of the floor plan.
   */
  floorPlan(state, id) {
    return select.floorPlans(state).find((f) => f.id === id)
  },

  /**
   * @returns {string[]}
   */
  allFeaturesForUnitsByName(state) {
    const units = select.units(state)
    const allUnitFeatures = []
    units.forEach((unit) => {
      unit.features.forEach((feature) => {
        if (!allUnitFeatures.includes(feature.name))
          allUnitFeatures.push(feature.name)
      })
    })
    return allUnitFeatures
  },

  /**
   * Get the floor plans for a community.
   * @param {object} state
   * @param {object} [filters]
   * @param {number} [filters.bedrooms] - The minimum bedrooms
   * @param {number} [filters.bathrooms] - The minimum bathrooms
   * @param {date} [filters.earliestMoveIn] - The earliest date the user can move
   * @param {date} [filters.latestMoveIn] - The latest date the user can move
   * @param {number} [filters.price] - The maximum price
   * @param {array<string>} [filters.features] - Features desired in a unit
   * @param {number} [filters.floor] - Floor Level desired for a unit
   */
  floorPlans(state, filters = {}) {
    const units = select.units(state)
    const floorPlans = select.community(state).floorPlans

    return (
      floorPlans?.reduce((acc, fp) => {
        // Filter Floor Plans
        if (filters) {
          if (filters.bedrooms && filters.bedrooms > fp.bedrooms) return acc
          if (filters.bathrooms && filters.bathrooms > fp.bathrooms) return acc
        }
        // Filter Units
        const matchingUnits = units?.filter((unit, index) => {
          let unitFeatureNames = unit?.features?.map((feature) => feature.name)

          if (unit.floorPlanId === fp.id) {
            if (filters) {
              if (filters.price && filters.price < unit.price) return false

              if (
                filters.earliestMoveIn &&
                filters.latestMoveIn &&
                !(
                  filters.earliestMoveIn.getTime() <=
                    new Date(unit.availableTime).getTime() &&
                  filters.latestMoveIn.getTime() >=
                    new Date(unit.availableTime).getTime()
                )
              )
                return false

              if (
                filters.features &&
                !unitFeatureNames.some((feature) =>
                  filters.features.includes(feature)
                )
              ) {
                return false
              }

              if (filters.floor && !(filters.floor === unit.floor)) return false

              return true
            } else {
              return true
            }
          } else {
            return false
          }
        })

        if (matchingUnits.length > 0) acc.push({ ...fp, units: matchingUnits })

        return acc
      }, []) || []
    )
  },

  floorPlate(state, floorPlateId) {
    return select.floorPlates(state).find((f) => f.id === floorPlateId)
  },

  floorPlates(state) {
    return select.community(state).floorPlates || []
  },

  leaseTerms(state) {
    return select.community(state).leaseTerms || []
  },

  leaseTerm(state, id) {
    return select.leaseTerms(state).find((term) => term.id === id)
  },

  quotes(state) {
    let quotes = select.guestCard(state).quotes
    let sortedQuotes = quotes
      .slice()
      ?.sort(
        (a, b) =>
          new Date(b.expirationDate).getTime() -
          new Date(a.expirationDate).getTime()
      )
    return sortedQuotes
  },

  hasQuotes(state) {
    return select.quotes(state).length > 0
  },

  quote(state, id) {
    return select.quotes(state).find((quote) => quote.id === id)
  },

  quotesForUnit(state, unitId) {
    return select.quotes(state).filter((quote) => quote.unitId === unitId)
  },

  quoteCategories(state) {
    return select.community(state).quoteCategories
  },

  /**
   * @param {object} state
   * @param {object} unit
   */
  quoteMonthlyCharges(state, unit) {
    return findCategoryItemsByFeeType(
      select.quoteCategories(state),
      'MONTHLY',
      unit
    )
  },

  /**
   * @param {object} state
   * @param {object} unit
   */
  quoteRequiredCharges(state, unit) {
    return findCategoryItemsByFeeType(
      select.quoteCategories(state),
      'REQUIRED',
      unit
    )
  },

  quoteAdditionalCharges(state, unit) {
    return select
      .quoteCategories(state)
      .map((category) => ({
        ...category,
        items: filterCategoryItems(category.items, 'NON_REQUIRED', unit),
      }))
      .sort((a, b) => {
        const scoreItem = (item) => {
          if (item.name.indexOf('Application') > -1) {
            return 3
          } else if (item.name.indexOf('Pet') > -1) {
            return 2
          } else if (item.name.indexOf('Additional') > -1) {
            return 1
          } else {
            return 0
          }
        }

        return scoreItem(b) - scoreItem(a)
      })
  },

  quoteFootNotes(state) {
    return select.communityConfig(state).quoteFootNotes
  },

  quoteHighlights(state) {
    return select.communityConfig(state).quoteHighlights
  },
}
