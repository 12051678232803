import React from 'react'
import PropTypes from 'prop-types'

import { IdPropType } from '~/store'

export function PageviewAnalytics({
  location,
  trackingId,
  communityId,
  communityName,
  guestCardId,
  guestCardUuid,
  children,
  trackingOptions,
  service,
}) {
  // SESSION TRACKING
  React.useEffect(() => {
    service.initialize(
      guestCardId,
      guestCardUuid,
      communityId,
      communityName,
      trackingId,
      trackingOptions
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // PAGE TRACKING
  React.useEffect(() => {
    service.trackPage(location)
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  return children
}

PageviewAnalytics.propTypes = {
  /**
   * The location object from React Router or Window.
   */
  location: PropTypes.object.isRequired,
  /**
   * The GA tracking id to use.
   */
  trackingId: PropTypes.string,
  /**
   * The id of the community.
   */
  communityId: IdPropType.isRequired,
  /**
   * The name of the community.
   */
  communityName: PropTypes.string.isRequired,
  /**
   * The database id of the guest card.
   */
  guestCardId: IdPropType.isRequired,
  /**
   * The UUID of the guest card.
   */
  guestCardUuid: PropTypes.string.isRequired,
  /**
   * Config options to pass to the analytics tracker.
   */
  trackingOptions: PropTypes.object,
  /**
   * The analytics service to use for tracking requests.
   */
  service: PropTypes.object,
}
