import React from 'react'
import PropTypes from 'prop-types'
import { combineClasses } from '~/util'
import {
  ItemName,
  Card,
  Action,
  UnitQuickFacts,
  UnitPrice,
  ExpandableHeaderImage,
} from '~/components'
import styles from './FloorPlanCard.module.scss'

/**
 * `<FloorPlanCard>`
 * This component renders the favorites card displayed on the
 * favorites page.
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} [props.floorPlanImageUrl]
 * @param {number} [props.startingPrice]
 * @param {string} [props.floorPlanName]
 * @param {number} [props.bed]
 * @param {number} [props.bath]
 * @param {number} [props.minFootage]
 * @param {number} [props.maxFootage]
 * @param {number} [props.quantityAvailable]
 * @param {string} [props.floorPlanDetailUrl]
 * @param {function} [props.onLoad]
 */
export function FloorPlanCard({
  className,
  floorPlanImageUrl,
  startingPrice,
  floorPlanName,
  bed,
  bath,
  minFootage,
  maxFootage,
  quantityAvailable,
  floorPlanDetailUrl,
  onLoad,
  ...rest
}) {
  return (
    <Card
      data-testid="FloorPlanCard"
      className={combineClasses(styles.FloorPlanCard, className)}
      {...rest}
    >
      {/* Floor Plan Image */}
      {floorPlanImageUrl && (
        <div className={styles.floorImageContainer}>
          <ExpandableHeaderImage
            src={floorPlanImageUrl}
            alt={'floorPlanName'}
            onLoad={onLoad}
          />
        </div>
      )}
      {/* Card Header Line */}
      {(floorPlanName || startingPrice) && (
        <div className={styles.floorPlanHeadingContainer}>
          {floorPlanName && (
            <ItemName
              data-testid="floorPlanName"
              className={styles.floorPlanName}
            >
              {floorPlanName}
            </ItemName>
          )}
          {startingPrice && (
            <UnitPrice className={styles.startingPrice} price={startingPrice} />
          )}
        </div>
      )}
      {/* Unit Details */}
      <div className={styles.section}>
        {/* col-1 */}
        <div className={styles.col}>
          {(bed || bath) && (
            <div className={styles.floorPlanInfoContainer}>
              <UnitQuickFacts bedroomCount={bed} bathroomCount={bath} />
            </div>
          )}
          {(minFootage || maxFootage) && minFootage !== maxFootage && (
            <div className={styles.floorPlanInfoContainer}>
              {minFootage && <span data-testid="minFootage">{minFootage}</span>}
              {minFootage >= 0 && maxFootage >= 0 && <span>-</span>}
              {maxFootage && <span data-testid="maxFootage">{maxFootage}</span>}
              {(minFootage || maxFootage) && ' SQFT'}
            </div>
          )}
          {(minFootage || maxFootage) && minFootage === maxFootage && (
            <div className={styles.floorPlanInfoContainer}>
              {minFootage && (
                <span data-testid="minFootage">{minFootage} SQFT</span>
              )}
            </div>
          )}
          {quantityAvailable > 0 && (
            <div className={styles.floorPlanInfoContainer}>
              <span>
                {quantityAvailable} Available Apartment
                {quantityAvailable > 1 ? 's' : ''}
              </span>
            </div>
          )}
        </div>
      </div>
      {floorPlanDetailUrl && (
        <Action
          data-testid="floorPlanDetailUrl"
          className={styles.floorPlanDetailUrl}
          href={floorPlanDetailUrl}
          feel="button"
          display="primary"
        >
          View Availability
        </Action>
      )}
    </Card>
  )
}

FloorPlanCard.propTypes = {
  /**
   * Image URL of the Floor Plan
   */
  floorPlanImageUrl: PropTypes.string,
  /**
   * Lowest price of that particualar unit
   */
  startingPrice: PropTypes.number,
  /**
   * Name of the floor Plan
   */
  floorPlanName: PropTypes.string,
  /**
   * Count of beds
   */
  bed: PropTypes.number,
  /**
   * Counts of baths
   */
  bath: PropTypes.number,
  /**
   * least footage count unit available
   */
  minFootage: PropTypes.number,
  /**
   * max footage count unit available
   */
  maxFootage: PropTypes.number,
  /**
   * Count of unit identical units available.
   */
  quantityAvailable: PropTypes.number,
  /**
   * Url for the unit.
   */
  floorPlanDetailUrl: PropTypes.string.isRequired,
  /**
   * Function that executes when image loads.
   */
  onLoad: PropTypes.func,
}
