import React from 'react'
import PropTypes from 'prop-types'

import { combineClasses } from '~/util'

import styles from './Lockbox.module.scss'
import { Action } from '../buttons/Action'
import { Modal } from '../modal'
import { Image } from '../image'

/**
 * `<Lockbox>`
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} [props.lockInstructionImageUrl]
 * @param {string} [props.lockInstructionText]
 */
export function Lockbox({
  className,
  lockInstructionImageUrl,
  lockInstructionText,
  ...rest
}) {
  const [isModalVisable, setIsModalVisable] = React.useState(false)
  return (
    <div
      data-testid="Lockbox"
      className={combineClasses(styles.Lockbox, className)}
      {...rest}
    >
      {(lockInstructionText || lockInstructionImageUrl) && (
        <section className={styles.lockBoxCodeSection}>
          {lockInstructionText && (
            <span>
              Lock Box Code:
              <span
                data-testid="lockInstructionText"
                className={styles.lockInstructionText}
              >
                #{lockInstructionText}
              </span>
            </span>
          )}
          <br />
          {lockInstructionImageUrl && (
            <Action
              button
              data-testid="lockBoxPDFButton"
              className={styles.lockBoxPDFButton}
              feel={'button'}
              onClick={() => setIsModalVisable(true)}
            >
              View Instructions
            </Action>
          )}
          <Modal
            data-testid="lockInstructionImageUrlModal"
            className={styles.modal}
            isOpen={isModalVisable}
            setIsOpen={setIsModalVisable}
            label={'community lock box instructions'}
            closeOnBackgroundClick
          >
            <Image
              data-testid="lockInstructionImageUrl"
              fit={null}
              src={lockInstructionImageUrl}
            />
          </Modal>
        </section>
      )}
    </div>
  )
}

Lockbox.propTypes = {
  lockInstructionText: PropTypes.string,
  lockInstructionImageUrl: PropTypes.string,
}
