import React from 'react'
import PropTypes from 'prop-types'

import { Title } from '../titles'
import { combineClasses } from '~/util'

import styles from './TitledContent.module.scss'

/**
 * @param {object} props
 * @param {*} props.children
 * @param {string} [props.title]
 * @param {boolean} [props.centered]
 * @param {string} [props.className]
 */
export function TitledContent({
  title,
  centered = false,
  children,
  className,
  ...rest
}) {
  return (
    <div
      data-test="titledContent"
      className={combineClasses(
        styles.TitledContent,
        className,
        centered ? styles.centered : null
      )}
      {...rest}
    >
      {title && (
        <Title data-test="pageTitle" className={styles.title}>
          {title}
        </Title>
      )}
      {children}
    </div>
  )
}

TitledContent.propTypes = {
  title: PropTypes.string,
}
