import React from 'react'
import PropTypes from 'prop-types'

import { combineClasses } from '~/util'
import { Title, SubTitle } from '../titles'

import LogoSVG from '~/assets/logomark-is-sad.svg?react'

import './NotFound.scss'

/**
 * Renders the "Not Found" component.
 *
 * @param {object} props
 * @param {string} [props.title] - The page title.
 * @param {string} [props.subtitle] - The page subtitle.
 * @param {string} [props.lostThing] - The name of the lost thing.
 * @param {string} [props.phoneNumber] - The support phone number.
 * @param {string} [props.className] - Additional css class name to use.
 * @param {object} [props.rest] - The rest of the props.
 * @returns {React.ReactNode} The component.
 */
export default function NotFound({
  title,
  subtitle,
  lostThing,
  phoneNumber,
  className,
  classNameSubTitle = '',
  ...rest
}) {
  if (!subtitle && (lostThing || phoneNumber)) {
    subtitle = `I can't find that${lostThing ? ' ' + lostThing : ''}. `
    subtitle += `Please check the link you followed. `
    if (phoneNumber) {
      subtitle += `Or give us a call at ${phoneNumber}.`
    }
  }

  return (
    <div
      name="notFound"
      className={combineClasses('not-found', className)}
      {...rest}
    >
      <Title className="title-large" data-test="title">
        {title}
      </Title>
      {subtitle && (
        <SubTitle
          className={combineClasses('title-small', classNameSubTitle)}
          data-test="subtitle"
        >
          {subtitle}
        </SubTitle>
      )}
      <LogoSVG className="logo" />
    </div>
  )
}

NotFound.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  lostThing: PropTypes.string,
  phoneNumber: PropTypes.string,
}

NotFound.defaultProps = {
  title: "Yikes...We're Lost!",
}
