import * as React from "react";
const SvgLogomarkIsSad = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 47 54", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.41421
}, ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { id: "background", className: "background", d: "M38.264,54l-29.503,0c-4.805,0 -8.737,-3.946 -8.737,-8.768l0,-36.464c0,-4.822 3.932,-8.768 8.737,-8.768l29.503,0c4.805,0 8.736,3.946 8.736,8.768l0,36.464c0,4.822 -3.931,8.768 -8.736,8.768" }), /* @__PURE__ */ React.createElement("path", { id: "circleLine", className: "circleLine", d: "M9.63,44.209c0.747,-2.116 1.965,-4.093 3.556,-5.694c2.68,-2.697 6.205,-4.132 10.198,-4.155l0.094,0c7.421,0 12.213,4.5 14.027,9.832", style: {
  fill: "none",
  stroke: "#fff",
  strokeWidth: 4
} }), /* @__PURE__ */ React.createElement("path", { id: "line1", className: "line1", d: "M20.291,9.074l-2.761,-1.093c-0.215,-0.084 -0.46,-0.058 -0.653,0.072c-0.192,0.13 -0.307,0.347 -0.307,0.579l0,17.392c0,0.386 0.314,0.699 0.701,0.699l2.762,0c0.387,0 0.701,-0.313 0.701,-0.699l0,-16.3c0,-0.286 -0.175,-0.544 -0.443,-0.65" }), /* @__PURE__ */ React.createElement("path", { id: "line2", className: "line2", d: "M25.202,4.792l-2.763,-1.093c-0.214,-0.084 -0.46,-0.058 -0.652,0.072c-0.192,0.13 -0.307,0.346 -0.307,0.578l0,21.675c0,0.386 0.314,0.7 0.701,0.7l2.762,0c0.388,0 0.701,-0.314 0.701,-0.7l0,-20.582c0,-0.287 -0.175,-0.545 -0.442,-0.65" }), /* @__PURE__ */ React.createElement("path", { id: "line3", className: "line3", d: "M30.112,9.074l-2.762,-1.093c-0.216,-0.084 -0.46,-0.058 -0.653,0.072c-0.192,0.13 -0.307,0.347 -0.307,0.579l0,17.392c0,0.386 0.313,0.699 0.701,0.699l2.762,0c0.387,0 0.701,-0.313 0.701,-0.699l0,-16.3c0,-0.286 -0.175,-0.544 -0.442,-0.65" })));
export default SvgLogomarkIsSad;
