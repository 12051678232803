import React from 'react'

export const localStorageKeys = {
  userAgreement: 'SELF_TOUR_AGREEMENT',
}

export function selfTourAgreementKey(uuid) {
  return `${localStorageKeys.userAgreement}-${uuid}`
}

/**
 * @type {import('react').Context<Storage>}
 */
export const LocalStorageContext = React.createContext(undefined)
/**
 * @type {LocalStorageContext.Provider}
 */
export const LocalStorageProvider = LocalStorageContext.Provider

export function useLocalStorage() {
  return React.useContext(LocalStorageContext)
}
