import React from 'react'
import PropTypes from 'prop-types'

import { combineClasses } from '~/util'
import { ANALYTICS_CATEGORIES } from '~/service'
import { MediaItemPropType } from '~/store'
import { PlayVideoAction, Action } from '~/components/buttons'
import { Image } from '../image'
import { Modal } from '../modal'

import transparentSVG from '~/assets/transparent.svg?react'

import styles from './Gallery.module.scss'
import { ItemName } from '../titles'

// Re-export for ease of use.
export { setAppElement } from '../modal'

export function ImageThumbnail({ className, imageURL, description, ...rest }) {
  // TODO Update Action so we can specify the dimensions of
  // images if they are cloudinary images:
  // style={{
  //   width: styles.medium,
  //   height: styles.medium,
  // }}
  return (
    <Action
      data-testid="thumbnailImage"
      className={styles.ImageThumbnail}
      aria-label={description}
      button
      transparent
      image={imageURL || transparentSVG}
      category={ANALYTICS_CATEGORIES.MEDIA}
      label={description}
      action="View Image"
      {...rest}
    />
  )
}

export function VideoThumbnail({ previewUrl, description, ...rest }) {
  return (
    <PlayVideoAction
      data-testid="thumbnailButton"
      className={styles.playButton}
      category={ANALYTICS_CATEGORIES.MEDIA}
      action="Play Video"
      label={description}
      button
      transparent
      image={previewUrl || transparentSVG}
      aria-label={description}
      {...rest}
    />
  )
}

/**
 * @param {object} media
 * @param {string} i
 */
function getMediaDescription(media, i) {
  return media?.description || `${media?.type} ${i + 1}`
}

// A resource that helped me a lot with vertical Scrolling:
// https://stackoverflow.com/questions/54724693/horizontal-scroll-with-css-grid
/**
 * @param {object} props
 * @param {string} [props.className]
 * @param {function} [props.onModalToggle]
 * @param {object[]} props.media
 * @param {string} [props.defaultVideoType]
 */
export function Gallery({
  className,
  onModalToggle,
  media,
  defaultVideoType = 'video/mp4',
  ...rest
}) {
  const [selectedMediaIndex, setSelectedMediaIndex] = React.useState(null)
  const [isModalVisable, setIsModalVisable] = React.useState(false)
  const selectedMedia =
    selectedMediaIndex != null ? media[selectedMediaIndex] : null

  const handleThumbnailClick = React.useCallback(
    (index) => {
      setIsModalVisable(true)
      setSelectedMediaIndex(index)
    },
    [setIsModalVisable, setSelectedMediaIndex]
  )

  // Update the selected video whenever the modal is closed
  // and ensure the parent component is notified of modal changes.
  React.useEffect(() => {
    if (!isModalVisable) setSelectedMediaIndex(null)
    if (onModalToggle) onModalToggle(isModalVisable)
  }, [isModalVisable, onModalToggle])

  const galleryView =
    media.length === 1
      ? styles.oneItem
      : media.length === 2
      ? styles.twoItems
      : styles.manyItems

  return (
    <>
      <div
        data-testid="gallery"
        className={combineClasses(styles.Gallery, className, galleryView)}
        {...rest}
      >
        <div className={styles.thumbnailGrid}>
          {media &&
            media.map((media, i) =>
              media?.type === 'VIDEO' || media?.type === 'REALYNC_VIDEO' ? (
                <VideoThumbnail
                  key={i}
                  description={getMediaDescription(media, i)}
                  previewUrl={media?.previewUrl}
                  onClick={() => handleThumbnailClick(i)}
                />
              ) : (
                <ImageThumbnail
                  key={i}
                  description={getMediaDescription(media, i)}
                  imageURL={media?.previewUrl || media?.url}
                  onClick={() => handleThumbnailClick(i)}
                />
              )
            )}
        </div>
      </div>
      <Modal
        data-testid="modal"
        className={styles.modal}
        isOpen={isModalVisable}
        setIsOpen={setIsModalVisable}
        label={getMediaDescription(selectedMedia, selectedMediaIndex)}
        closeOnBackgroundClick
      >
        <div className={styles.mediaContainer}>
          {media[selectedMediaIndex]?.type === 'VIDEO' ||
          media[selectedMediaIndex]?.type === 'REALYNC_VIDEO' ? (
            <video
              data-testid="modalVideo"
              className={styles.mediaView}
              controls
              muted
              autoPlay={isModalVisable}
            >
              <source
                data-testid="modalVideoSource"
                src={selectedMedia?.url}
                type={defaultVideoType}
              />
            </video>
          ) : (
            <Image
              data-testid="modalImage"
              className={styles.mediaView}
              src={selectedMedia?.url || selectedMedia?.previewUrl}
              alt={selectedMedia?.alt}
              fit="contain"
              transparent
              display="block"
              responsive
            />
          )}
        </div>
        {selectedMedia && (
          <div className={styles.mediaDescriptionContainer}>
            <ItemName
              data-testid="mediaDescriptionName"
              className={styles.mediaName}
            >
              {selectedMedia?.name}
            </ItemName>
            <div data-testid="mediaDescription">
              {selectedMedia?.description}
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

Gallery.propTypes = {
  /**
   * A callback that will be notified when the modal
   * is opened or closed.
   */
  onModalToggle: PropTypes.func,
  /**
   * Accepts an array of MediaItem objects.
   */
  media: PropTypes.arrayOf(MediaItemPropType).isRequired,
  /**
   * The default file type to use for video media items.
   */
  defaultVideoType: PropTypes.string,
}
