import { env } from '~/service/env'
// TODO Move this to @thesoulfresh/utils
import { trimChar } from './format'
/**
 * Determine if an image is a cloudinary image.
 */
export function isCloudinaryURL(url) {
  return url?.indexOf('cloudinary.com') > -1
}

/**
 * If we get an image url referencing a Cloudinary image,
 * convert the url into a Cloudinary image key so we can use
 * the Cloudinary API for responsive images.
 * @param {string} url
 * @param {string} TPK - The image identifier portion of the URL.
 */
export function urlToCloudinaryTPK(url, apiKey = env.cloudinaryAPIKey) {
  if (isCloudinaryURL(url)) {
    const parts = url.split(apiKey)
    if (parts.length > 1) {
      let name = parts[1]
      const nameParts = name.split('.')

      // If it includes an extension, remove it.
      if (nameParts.length > 1) {
        name = nameParts.slice(0, nameParts.length - 1).join('.')
      }

      return trimChar(name, '/')
    }
  }

  return null
}

/**
 * Get the third party key (TPK) for an image from either the
 * media item passed or the fallback src. This makes it easy to
 * mix media items and standard URLs through the app. The TPK
 * returned will be pulled from either the `thirdPartyKey` of
 * the media item, the `url` of the media item, the media item
 * `previewUrl` or the `url` of the `src` parameter (in that order).
 * This ensures that Cloudinary based images can be used wherever possible.
 *
 * NOTE: It's possible this won't work for all images. In that case,
 * we'll need to either
 * 1) not use Cloudinary in this case
 * 2) catch errors and try again without the transformation
 * 3) fix the underlying issue.
 *
 * @param {object} [mediaItem] - The media item for the image. If falsy,
 *   then the src parameter is used instead.
 * @param {string} [src] - The src of the image if not using a media item.
 * @param {string} [apiKey] - The key used for our Cloudinary account
 *   (refered to as the `cloudName` in the Cloudinary API).
 * @returns {string|null} If we could determine a TPK, then it will be
 *   returned or null if this is not a Cloudinary image or we couldn't
 *   determine the TPK.
 */
export function getCloudinaryTPK(mediaItem, src, apiKey) {
  if (mediaItem?.thirdPartyKey) {
    return mediaItem.thirdPartyKey
  } else if (mediaItem?.url && isCloudinaryURL(mediaItem.url)) {
    const tpk = urlToCloudinaryTPK(mediaItem.url, apiKey)
    if (tpk) return tpk
  } else if (mediaItem?.previewUrl && isCloudinaryURL(mediaItem.previewUrl)) {
    const tpk = urlToCloudinaryTPK(mediaItem.previewUrl, apiKey)
    if (tpk) return tpk
  }

  // If we got this far, we're not using a media item.
  if (isCloudinaryURL(src)) return urlToCloudinaryTPK(src, apiKey)

  return null
}

/**
 * Gets the first Hero image from media galleries.
 *
 * @param {object} props
 * @param {object} [props.community] - Community Data object.
 * @param {string} [props.name] - name of the tag being searched for.
 *
 * @return returns the id of the mediaTag.
 */
export const getMediaTagId = ({ community, name }) => {
  return community?.mediaTags?.find((x) => x.name === name.toUpperCase())?.id
}

/**
 * Gets the first Hero image from media galleries.
 *
 * @param {object} community - Community Data object.
 *
 * @return url of the home image || null.
 */
export const findFirstHeroImage = (community) => {
  let mediaTagHeroId = community?.mediaTags?.find((x) => x.name === 'HERO')?.id
  let mediaWithHomeTag = community?.mediaGalleries
    ?.flatMap((x) => x.mediaItems)
    .find((x) => x?.mediaTagIds?.includes(mediaTagHeroId) && x.type === 'IMAGE')
  return mediaWithHomeTag?.url || null
}

/**
 * Gets the first home image from media galleries.
 *
 * @param {object} community - Community Data object.
 *
 * @return url of the home image || null.
 */
export const findFirstHomeImage = (community) => {
  let mediaTagHomeId = community?.mediaTags?.find((x) => x.name === 'HOME')?.id
  let mediaWithHomeTag = community?.mediaGalleries
    ?.flatMap((x) => x.mediaItems)
    .find((x) => x?.mediaTagIds?.includes(mediaTagHomeId) && x.type === 'IMAGE')
  return mediaWithHomeTag?.url || null
}

/**
 * Provides the first image found in media galleries.
 *
 * @param {object} community - Community data object.
 *
 * @return first image found || null.
 */
export const findFirstMediaGalleryImage = (community) => {
  return (
    community?.mediaGalleries
      ?.flatMap((x) => x?.mediaItems)
      ?.find((x) => x?.type === 'IMAGE')?.url || null
  )
}

/**
 * Attemps to find a header image from community galleries if not it aill use the a hard coded default.
 * 1. A hero image from galleries.
 * 2. A Home image from galleries.
 * 3. The first image from media galleries.
 * 4. A hard coded default image.
 *
 * @param {*} community - Community data object.
 * @returns {string}
 */
export const findHeaderImage = (community) => {
  const heroImage = findFirstHeroImage(community)
  const firstHomeImage = findFirstHomeImage(community)
  const mediaGaleryImage = findFirstMediaGalleryImage(community)
  const backUpImage =
    'https://res.cloudinary.com/powerpro/system/Favorites_Background_ls33hn.jpg'
  return heroImage || firstHomeImage || mediaGaleryImage || backUpImage
}
