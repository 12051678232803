import React from 'react'
import styles from './TitleValuePairVertical.module.scss'

import { combineClasses } from '~/util'

import { ItemName } from '~/components'

interface TitleValuePairVerticalProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The Title Describing the value(data) being rendered.
   */
  title?: string
  /**
   * Can be s, m
   */
  valueTextSize?: string
  /**
   * An alias for the `children` prop
   */
  value?: React.ReactNode
}

/**
 * Display a value with a label.
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} [props.title]
 * @param {*} [props.children]
 * @param {string} [props.valueTestSize]
 */
export function TitleValuePairVertical({
  className,
  title,
  value,
  valueTextSize = 's',
  children = value,
  ...rest
}: TitleValuePairVerticalProps) {
  const valueSize = valueTextSize.toLowerCase()

  return (
    <div
      data-testid="TitleValuePairVertical"
      className={combineClasses(styles.column, className)}
      {...rest}
    >
      {title && (
        <div data-testid="title" className={styles.title}>
          {title}
        </div>
      )}

      {children && (
        <ItemName data-testid="value" className={styles[`value-${valueSize}`]}>
          {children}
        </ItemName>
      )}
      {!children && (
        <ItemName data-testid="value" className={styles[`value-${valueSize}`]}>
          &nbsp;
        </ItemName>
      )}
    </div>
  )
}
