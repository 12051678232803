import React from 'react'
import PropTypes from 'prop-types'

import { combineClasses, formatUnitName, isDateBetween } from '~/util'
import { UnitPropType } from '~/store'

import { FavoritesAction } from '../../buttons'
import { Label } from '../../labels'
import { ItemName } from '../../titles'
import { UnitPrice } from '../../unit-price'
import { UnitQuickFacts } from '../../unit-quick-facts'
import { DoorCode } from '../../door-code'

import { Card } from '../Card'

import styles from './UnitCard.module.scss'
import { ExpandableHeaderImage } from '~/components'

/**
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} [props.title]
 * @param {number} [props.price]
 * @param {number} [props.beds]
 * @param {number} [props.baths]
 * @param {number} [props.footage]
 * @param {string} [props.doorCode]
 * @param {string} [props.accessStart]
 * @param {string} [props.accessEnd]
 * @param {boolean} [props.isFavorite]
 * @param {function} [props.onToggleFavorite]
 * @param {boolean} [props.isShowPriceOnModel]
 */
export function Details({
  title,
  price,
  beds,
  baths,
  footage,
  doorCode,
  accessStart,
  accessEnd,
  isFavorite,
  onToggleFavorite,
  isShowPriceOnModel,
}) {
  const isDateValid = isDateBetween(new Date(), accessStart, accessEnd)
  const isUnitAccessible = doorCode && accessStart && accessEnd && isDateValid
  const hasDetails =
    beds || baths || footage || doorCode || accessStart || accessEnd

  return (
    <div
      className={combineClasses(
        styles.details,
        isUnitAccessible ? styles.hasDoorCode : styles.noDoorCode
      )}
    >
      <div className={styles.headerRow}>
        {title && (
          <ItemName className={styles.itemTitle} data-testid="unitTitle">
            {title}
          </ItemName>
        )}
        {price && (
          <UnitPrice
            className={styles.itemPrice}
            data-test="price"
            price={price}
          />
        )}
      </div>

      {hasDetails && (
        <div className={styles.details}>
          <UnitQuickFacts
            className={styles.quickFacts}
            bedroomCount={beds}
            bathroomCount={baths}
            squareFootageCount={footage}
          />

          <div className={styles.doorCodeAndActions}>
            {isUnitAccessible && (
              <DoorCode
                className={styles.DoorCode}
                doorCode={doorCode}
                accessStart={accessStart}
                accessEnd={accessEnd}
              />
            )}
            {onToggleFavorite && (
              <FavoritesAction
                data-testid="FavoritesAction"
                className={styles.FavoritesAction}
                isToggled={isFavorite}
                onClick={onToggleFavorite}
                button
              >
                {isFavorite ? 'Remove' : 'Add'} Favorite
              </FavoritesAction>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

/**
 * @param {object} props
 * @param {object} [props.unit]
 * @param {string} [props.label]
 * @param {string} [props.title]
 * @param {boolean} [props.showUnitPrices]
 * @param {function} [props.onLoad]
 * @param {boolean} [props.showFloorPlanImage]
 * @param {string} [props.className]
 * @param {string} [props.floorPlateImageClassName]
 * @param {boolean} [props.isFavorite]
 * @param {function} [props.onToggleFavorite]
 * @param {boolean} [props.isShowPriceOnModel]
 */
export function UnitCard({
  unit,
  label,
  title = formatUnitName(unit, false),
  showUnitPrices,
  onLoad,
  showFloorPlanImage = false,
  className,
  floorPlateImageClassName,
  isFavorite,
  onToggleFavorite,
  isShowPriceOnModel,
  ...rest
}) {
  const cardRef = React.useRef()

  return (
    <Card
      ref={cardRef}
      data-testid="UnitCard"
      className={combineClasses(
        styles.UnitCard,
        !showFloorPlanImage ? styles.row : styles.column,
        !unit.floorPlan?.imageUrl ? styles.noImage : '',
        className
      )}
      {...rest}
    >
      <div className={styles.content}>
        {label && (
          <div className={styles.unitNumberContainer}>
            <Label
              data-testid="label"
              children={label}
              size="m"
              display="primary"
            />
          </div>
        )}
        {showFloorPlanImage && unit.floorPlan?.imageUrl && (
          <div className={styles.floorPlanImageContainer}>
            <ExpandableHeaderImage
              src={unit.floorPlan.imageUrl}
              alt={`${unit.floorPlan?.name} Floor Plan`}
              onLoad={onLoad}
            />
          </div>
        )}
        <Details
          data-testid="unit"
          title={title}
          price={
            showUnitPrices &&
            (isShowPriceOnModel || (!isShowPriceOnModel && !unit.isModel))
              ? unit.price
              : null
          }
          beds={unit.floorPlan?.bedrooms}
          baths={unit.floorPlan?.bathrooms}
          footage={unit.floorPlan?.squareFootage}
          doorCode={unit.lockDevice?.pin}
          accessStart={unit.lockDevice?.startTime}
          accessEnd={unit.lockDevice?.endTime}
          isFavorite={isFavorite}
          onToggleFavorite={onToggleFavorite}
        />
      </div>
    </Card>
  )
}

UnitCard.propTypes = {
  /**
   * Unit object.
   */
  unit: UnitPropType.isRequired,
  /**
   * Usually the unit number and is displayed in the blue unitNumber Card.
   */
  label: PropTypes.string,
  /**
   * string to be passed to set the title of Apartment Destination
   * if not passed unit.number and floor.plate name will be used.
   */
  title: PropTypes.string,
  /**
   * determines if unit prices should be displayed.
   */
  showUnitPrices: PropTypes.bool,
  /**
   * dermines if the Unit floorPlate should be displayed.
   */
  showFloorPlanImage: PropTypes.bool,
  /**
   * class for customizing the plage image
   */
  floorPlateImageClassName: PropTypes.string,
  /**
   * If this is specified, then the favorites button
   * will be shown, allowing the user to add/remove
   * the unit from their favorites.
   */
  onToggleFavorite: PropTypes.func,
  /**
   * Whether the unit is currently in the users favorites.
   */
  isFavorite: PropTypes.bool,
  /**
   * Whether display price of a model unit.
   */
  isShowPriceOnModel: PropTypes.bool,
  /**
   * called when floorPlateImage is loaded.
   */
  onLoad: PropTypes.func,
}
