import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { combineClasses, useIsMounted, useImageLoad } from '~/util'
import { MarkerPropType } from '~/store'
import { MarkerLayer } from '../marker-layer'

import styles from './TourMap.module.scss'

export function User({ x, y }) {
  return (
    <div
      data-testid="user"
      className={styles.user}
      style={{ left: x, top: y }}
    />
  )
}

/**
 * @typedef {object} TourMapProps
 * @property {string} [name]
 * @property {string} [url]
 * @property {object[]} [markers]
 * @property {object} [user]
 * @property {number} [scale]
 * @property {function} [onLoad]
 * @property {function} [getUnitURL]
 * @property {function} [getAmenityURL]
 * @property {object} [firstFocusableRef]
 * @property {string} [className]
 * @property {boolean} [animated]
 */
/**
 * The `TourMap` component displays a single community map
 * with markers over the interesting points of the map.
 * @type React.FC<TourMapProps>
 */
export const TourMap = React.forwardRef(
  (
    {
      name,
      url,
      markers = [],
      user,
      scale,
      onLoad,
      getUnitURL,
      getAmenityURL,
      firstFocusableRef,
      className,
      animated,
      ...rest
    },
    ref
  ) => {
    const [dimensions, setDimensions] = React.useState()
    const [imageLoaded, setImageLoaded] = React.useState(false)
    const [markersLoaded, setMarkersLoaded] = React.useState(false)
    const emittedRef = React.useRef(false)
    const isMounted = useIsMounted()

    // Store a reference to the dimensions that the ResizeObserver
    // can read so we don't need to recreate the observer on
    // each render.
    const dimensionsRef = React.useRef()
    dimensionsRef.current = dimensions

    const history = useHistory()

    // CLICK HANDLERS
    const handleSelectUnit = React.useCallback(
      (m) => {
        if (getUnitURL) {
          history.push(getUnitURL(m.item.id))
        }
      },
      [getUnitURL, history]
    )

    const handleSelectAmenity = React.useCallback(
      (m) => {
        if (getAmenityURL) {
          history.push(getAmenityURL(m.item.id))
        }
      },
      [getAmenityURL, history]
    )

    // LOAD HANDLERS
    const onMarkersLoaded = () => setMarkersLoaded(true)

    const { ref: imageRef } = useImageLoad((image) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setDimensions({
          width: image.clientWidth,
          height: image.clientHeight,
          naturalWidth: image.naturalWidth,
          naturalHeight: image.naturalHeight,
        })
        setImageLoaded(true)
      })
    })

    // Emit the onLoad event exactly one time after image load.
    React.useEffect(() => {
      if (imageLoaded && markersLoaded && !emittedRef.current && onLoad) {
        emittedRef.current = true
        onLoad(dimensions)
      }
    }, [imageLoaded, markersLoaded, onLoad, dimensions])

    // DIMENSIONS TRACKING
    // Keep the dimensions of the overlay layer in sync
    // with the dimensions of the image.
    React.useEffect(() => {
      const observer = new ResizeObserver((entries) => {
        requestAnimationFrame(() => {
          if (isMounted()) {
            const last = entries[entries.length - 1]
            const d = dimensionsRef.current
            if (
              d &&
              d.width !== last.contentRect.width &&
              d.height !== last.contentRect.height
            ) {
              setDimensions((d) => ({
                ...d,
                width: last.contentRect.width,
                height: last.contentRect.height,
              }))
            }
          }
        })
      })

      observer.observe(imageRef.current)

      return () => observer.disconnect()
    }, [isMounted]) // eslint-disable-line react-hooks/exhaustive-deps

    // MARKER COORDINATES
    const clampCoord = (coord) => Math.min(Math.max(coord, 0), 100)
    const convertedMarkers = dimensions
      ? markers.map((m) => ({
          ...m,
          x: clampCoord((m.x / dimensions.naturalWidth) * 100),
          y: clampCoord((m.y / dimensions.naturalHeight) * 100),
        }))
      : markers

    return (
      <div
        data-testid="tourMap"
        ref={ref}
        className={combineClasses(styles.TourMap, className)}
        {...rest}
        draggable="false"
      >
        {imageLoaded && dimensions && (
          <div
            className={styles.overlays}
            style={{
              width: dimensions.width,
              height: dimensions.height,
            }}
          >
            {user && <User x={`${user.x * 100}%`} y={`${user.y * 100}%`} />}
            <MarkerLayer
              className={styles.MarkerLayer}
              markers={convertedMarkers}
              markerScale={1 / scale}
              onUnitClick={handleSelectUnit}
              onAmenityClick={handleSelectAmenity}
              onReady={onMarkersLoaded}
              firstFocusableRef={firstFocusableRef}
              initialDelay={300}
              animated={animated}
            />
          </div>
        )}
        <img
          data-testid="mapImage"
          alt={name}
          className={combineClasses(styles.backgroundImage, 'map-image')}
          src={url}
          ref={imageRef}
          draggable="false"
        />
      </div>
    )
  }
)

TourMap.propTypes = {
  /**
   * The name of the floor plate. This is used to set
   * the aria title for the map image.
   */
  name: PropTypes.string,
  /**
   * The URL of the floor plate image.
   */
  url: PropTypes.string,
  /**
   * An array of MarkerPropType objects defining
   * the markers to place on the map.
   */
  markers: PropTypes.arrayOf(MarkerPropType),
  /**
   * The scale at which to render markers.
   */
  scale: PropTypes.number,
  /**
   * A callback that will be used when the map
   * image has loaded.
   * @param {object} dimensions
   * @param {number} dimensions.width - The `naturalWidth` of the map image.
   * @param {number} dimensions.height - The `naturalHeight` of the map image.
   */
  onLoad: PropTypes.func,
  /**
   * The position of the user within the map.
   */
  user: PropTypes.object,
  /**
   * A function to get the URL to a particular unit page.
   */
  getUnitURL: PropTypes.func,
  /**
   * A function to get the URL to a particular amenity page.
   */
  getAmenityURL: PropTypes.func,
  /**
   * A ref that will be pased to the first element within the
   * tour map that can recieve focus events. This is used to
   * ensure that focus remains within the tour map when it
   * is expanded.
   */
  firstFocusableRef: PropTypes.any,
  /**
   * Any additional classes you'd like to set on
   * the root of the component.
   */
  className: PropTypes.string,
  /**
   * This can be used to disable animations.
   */
  animated: PropTypes.bool,
}
