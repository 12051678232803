import React from 'react'
import PropTypes from 'prop-types'
import { useMaybeControlled } from '@thesoulfresh/react-tools'

import { combineClasses } from '~/util'
import styles from './OptionsSelector.module.scss'

export function InputElement({
  index,
  isMultiSelect,
  selectedIndex,
  groupName,
  handleLabelClick,
  text,
}) {
  let checked = isMultiSelect
    ? (index) => selectedIndex?.includes(index)
    : (index) => selectedIndex === index
  let inputType = isMultiSelect ? 'checkbox' : 'radio'
  return (
    <span className={styles.inputContainer}>
      <label
        data-testid="optionsSelectorLabel"
        className={combineClasses(checked(index) ? styles.active : '')}
        htmlFor={`${groupName}` + index}
      >
        <input
          data-testid="optionsSelectorInput"
          id={`${groupName}` + index}
          type={inputType}
          name={groupName}
          value={text}
          checked={checked(index)}
          onClick={() => handleLabelClick(index)}
          onChange={() => {
            /**
             * Using on onClick becuase I want to be able to unselected radio buttons
             * and unchange only emmits when a diffrent button is clicked.
             *
             * I have this empty function here still becuase a react warning is emmited when
             * no onChange prop is pressent.
             */
          }}
        />
        {text}
      </label>
    </span>
  )
}

/**
 * `<OptionsSelector>` is a radio group/multiselect group component. The `isMultiSelect`
 * prop is used to determine if multiple items can be selected or just one. The `options` in the
 * group are defined by passing an array of string or numbers as the `options`
 * prop. The `onChange` callback can be used to listen for selection events
 * from the user. This component can be used as either a controlled or uncontrolled
 * component. If the `value` prop is passed, it is considered a controlled
 * component and the `onChange` callback should be used as a setter for the
 * `value`.
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} [props.groupName]
 * @param {function} [props.onChange]
 * @param {array} [props.options]
 * @param {any | number | array} [props.value]
 * @param {boolean} [props.isMultiSelect]
 */
export function OptionsSelector({
  className,
  isMultiSelect,
  value,
  options,
  groupName,
  onChange,
  ...rest
}) {
  const [selectedIndex, setSelectedIndex] = useMaybeControlled(value, onChange)

  const handleLabelClick = React.useCallback(
    (i) => {
      if (!isMultiSelect) {
        if (selectedIndex === i) {
          setSelectedIndex(null)
          return
        }
        setSelectedIndex(i)
        return
      }

      if (isMultiSelect) {
        let tempArray = selectedIndex ? [...selectedIndex] : []

        if (!selectedIndex?.includes(i)) {
          tempArray.push(i)
        }
        if (selectedIndex?.includes(i)) {
          tempArray.splice(tempArray.indexOf(i), 1)
        }
        setSelectedIndex(tempArray)
      }
    },
    [isMultiSelect, selectedIndex, setSelectedIndex]
  )

  return (
    <div
      data-testid="OptionsSelector"
      className={combineClasses(
        styles.OptionsSelector,
        styles['radio-toolbar'],
        className
      )}
      {...rest}
    >
      {options?.length ? (
        options.map((text, i) => {
          return (
            <InputElement
              key={i}
              isMultiSelect={isMultiSelect}
              selectedIndex={selectedIndex}
              groupName={groupName}
              handleLabelClick={handleLabelClick}
              index={i}
              text={text}
            />
          )
        })
      ) : (
        <></>
      )}
    </div>
  )
}

OptionsSelector.propTypes = {
  /**
   * Whether the component acts as a radio group (false)
   * or a multiselect group (true).
   */
  isMultiSelect: PropTypes.bool,
  /**
   * A unique name for radio button group.
   */
  groupName: PropTypes.string,
  /**
   * An array of custom strings or numbers that will be used
   * as the radio group values.
   */
  options: PropTypes.array,
  /**
   * This callback will be called whenever the user
   * changes the selected value. By default, the component will
   * be treated as a radio group and the most recently selected
   * value will be passed to the `onChange` callback. If `isMultiSelect`
   * is true, then the component is treated as a checkbox group
   * and `onChange` will receive an array of the selected options.
   * In either case, the selected option(s) will match one or more
   * items in the `options` prop.
   */
  onChange: PropTypes.func,
  /**
   * The selected value(s) of the component. If this is passed,
   * then the component is treated as a controlled component and
   * you must also update the value state when `onChange` events
   * occur.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
}
