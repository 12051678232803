import React from 'react'
import PropTypes from 'prop-types'

import { combineClasses } from '~/util'

import styles from './DesiredFloorForm.module.scss'
import { OptionsSelector } from '~/components/options-selector'
import { Title } from '~/components/titles'

/**
 * `<DesiredFloorForm>`
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} [props.title]
 * @param {array} [props.availableFloorLevels]
 * @param {number | null} [props.floorValue]
 * @param {function} [props.setFloorValue]
 */
export function DesiredFloorForm({
  className,
  title = 'Select Floor Preference',
  availableFloorLevels,
  floorValue,
  setFloorValue,
  ...rest
}) {
  return (
    <div
      data-testid="DesiredFloorForm"
      className={combineClasses(styles.DesiredFloorForm, className)}
      {...rest}
    >
      <Title data-testid="title" className={styles.title}>
        {title}
      </Title>
      <OptionsSelector
        groupName="floor"
        options={availableFloorLevels}
        value={floorValue}
        onChange={setFloorValue}
      />
    </div>
  )
}

DesiredFloorForm.propTypes = {
  /** Title for  */
  title: PropTypes.string,
  availableFloorLevels: PropTypes.array,
  floorValue: PropTypes.number,
  setFloorValue: PropTypes.func,
}
