import React from 'react'
import PropTypes from 'prop-types'
import { useId } from '~/util'
import { Currency, Tooltip } from '@thesoulfresh/react-tools'
import { combineClasses } from '~/util'
import { Title, Slider } from '~/components'

import styles from './MaxRentForm.module.scss'

/**
 * Gets a nubmer divisable by the step so that the number is
 * complient with the slider regulation on having a number
 * divisable by the step.
 *
 * @param {*} number - base numger the slider will go to.
 * @param {*} step - step of the slider
 * @returns {number}
 */
export const getNumberDevisableByStep = (number, step = 25) => {
  let isNumberDivisableByStep = Number.isInteger(number / step)
  if (isNumberDivisableByStep) return number

  let total = 0
  while (total < number) {
    total += step
  }
  return total
}

/**
 * `<MaxRentForm>`
 *
 * This is a controlled component that allows the user
 * to select a value with in the defined props
 * min and max using the slider or the text input.
 * If the value is to high then a warning label is displayed.
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} [props.title]
 * @param {number} [props.min]
 * @param {number} [props.max]
 * @param {number} [props.step]
 * @param {number | null} [props.value]
 * @param {function} [props.onChange]
 * @param {function} [props.textInputOnChange]
 */
export function MaxRentForm({
  className,
  title,
  min = 800,
  max = 4000,
  step = 25,
  value,
  onChange,
  ...rest
}) {
  const id = useId('rangeTextInput')
  const isWarningLabelDisplayed =
    value < min || value > getNumberDevisableByStep(max, step)

  return (
    <div
      data-testid="MaxRentForm"
      className={combineClasses(styles.MaxRentForm, className)}
    >
      <Title data-testid="title" className={styles.title}>
        {title}
      </Title>
      <Tooltip
        isOpen={isWarningLabelDisplayed}
        content={
          <label
            data-testid="warningLabel"
            htmlFor="rangeTextInput"
            className={styles.warningLabel}
          >
            Value must be between <Currency value={min} /> and{' '}
            <Currency value={max} />
          </label>
        }
      >
        <label htmlFor={id} className={styles.label}>
          Up to
          <Currency
            data-testid="textInput"
            className={styles.textInput}
            input
            id={id}
            type="text"
            name="rangeValue"
            value={value ? value : getNumberDevisableByStep(max, step)}
            style={{ width: `${max?.toString().length + 5}ch` }}
            onValueChange={(value) => onChange(value.floatValue)}
          />
          per month
        </label>
      </Tooltip>
      <Slider
        min={getNumberDevisableByStep(min, step)}
        max={getNumberDevisableByStep(max, step)}
        value={value ? value : getNumberDevisableByStep(max, step)}
        onChange={onChange}
        step={step}
        aria-label={`Selected rent value is $${value}`}
        {...rest}
      />
    </div>
  )
}

MaxRentForm.propTypes = {
  /**
   * Title for the range slider
   */
  title: PropTypes.string,
  /**
   * Min slide value
   */
  min: PropTypes.number,
  /**
   * Max slide value
   */
  max: PropTypes.number,
  /**
   * Increment of the slider.
   */
  step: PropTypes.number,
  /**
   * Current value
   */
  value: PropTypes.number,
  /**
   * Change listener accepts a number.
   */
  onChange: PropTypes.func,
}
