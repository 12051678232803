import React from 'react'
import PropTypes from 'prop-types'
import MasonryInfiniteScroller from 'react-masonry-infinite'

import { combineClasses, useTimeout, useIsMounted } from '~/util'

import styles from './MasonryScroller.module.scss'

/**
 * @param {object} props
 * @param {(array | function)} props.children
 * @param {number} [props.cardWidth]
 * @param {number} [props.gutterWidth]
 * @param {boolean} [props.repack]
 * @param {number} [props.repackDelay]
 * @param {string} [props.className]
 */
export const MasonryScroller = ({
  className,
  children,
  cardWidth = Number(styles['card-width']),
  gutterWidth = Number(styles.xs),
  repack = true,
  repackDelay = 0,
  ...rest
}) => {
  const masonryRef = React.useRef(null)
  const wait = useTimeout()
  const isMounted = useIsMounted()

  const calcMasonryColumnSize = (columnCount) => {
    return `${cardWidth * columnCount + gutterWidth * (columnCount - 1)}px`
  }

  const masonrySizeOptions = [
    { columns: 1, gutter: gutterWidth },
    { mq: calcMasonryColumnSize(2), columns: 2, gutter: gutterWidth },
    { mq: calcMasonryColumnSize(3), columns: 3, gutter: gutterWidth },
    { mq: calcMasonryColumnSize(4), columns: 4, gutter: gutterWidth },
  ]

  const forceRepack = () => {
    if (masonryRef.current) {
      wait(() => {
        if (isMounted()) masonryRef.current.forcePack()
      }, repackDelay)
    }
  }

  React.useEffect(() => {
    window.addEventListener('resize', () => forceRepack())
    return () => {
      window.removeEventListener('resize', () => forceRepack())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // If asked to do so, force a repack after the first render.
  React.useEffect(() => {
    if (isMounted() && repack) forceRepack()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MasonryInfiniteScroller
      ref={masonryRef}
      data-testid="MasonryScroller"
      className={combineClasses(styles.MasonryScroller, className)}
      sizes={masonrySizeOptions}
      loadMore={() => {}}
    >
      {children && typeof children === 'function'
        ? children({ forceRepack })
        : children}
    </MasonryInfiniteScroller>
  )
}

MasonryScroller.propTypes = {
  /**
   * Children should be either standard JSX children or a function that returns the
   * children to render. If a function is passed, it receives the following parameters:
   * @param {object} props
   * @param {function} props.forceRepack - A function that can be used to force the Masonry
   *    component to repack it's content.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.func,
  ]),
  /**
   * Width of the cards.
   */
  cardWidth: PropTypes.number,
  /**
   * Space in between the card columns.
   */
  gutterWidth: PropTypes.number,
  /**
   * Allows you to prevent the initial page packing
   * algorithm on first render.
   */
  repack: PropTypes.bool,
  /**
   * The number of milliseconds to delay repack calls.
   */
  repackDelay: PropTypes.number,
}
