import React from 'react'
import PropTypes from 'prop-types'

import { combineClasses, useTimeout } from '~/util'
import { Loader } from '../../loader'

import styles from './MapLoader.module.scss'

// Build these into separate bundles because they're fairly large
// and we only need one or the other.
const UnitMap = React.lazy(() => import('./engrain/UnitMap'))
const PowerProMap = React.lazy(() =>
  import('../zoomable-tour-map/ZoomableTourMap')
)

/**
 * Loads the correct mapping bundle depending on the map technology
 * used by this community.
 */
export function MapLoader({
  useEngrain = false,
  engrainMapId,
  className,
  onLoad,
  loaderDelay = 3000,
  ...rest
}) {
  const [visible, setVisible] = React.useState(false)
  const [showLoader, setShowLoader] = React.useState(true)
  const wait = useTimeout()

  const handleLoad = () => {
    setVisible(true)
    if (onLoad) onLoad()

    if (loaderDelay > 0) wait(() => setShowLoader(false), loaderDelay)
    else setShowLoader(false)
  }

  return (
    <div
      data-testid="MapLoader"
      className={combineClasses(styles.MapLoader, className)}
    >
      {showLoader && <Loader data-testid="Loader" className={styles.Loader} />}
      <div
        data-testid="mapContent"
        className={combineClasses(
          styles.mapContent,
          visible ? styles.visible : null
        )}
      >
        <React.Suspense fallback={null}>
          {useEngrain ? (
            <UnitMap
              data-testid="UnitMap"
              onLoad={handleLoad}
              {...rest}
              id={engrainMapId}
              className={styles.UnitMap}
            />
          ) : (
            <PowerProMap
              data-testid="PowerProMap"
              onLoad={handleLoad}
              {...rest}
              id={engrainMapId}
              className={styles.UnitMap}
            />
          )}
        </React.Suspense>
      </div>
    </div>
  )
}

MapLoader.propTypes = {
  useEngrain: PropTypes.bool,
  engrainMapId: PropTypes.string,
}
