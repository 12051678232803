import { combineClasses, formatCurrency } from '~/util'

import styles from './UnitPrice.module.scss'

interface UnitPriceProps extends React.HTMLAttributes<HTMLSpanElement> {
  /**
   * Price per month of the unit in pennies.
   */
  price: number
}

/**
 * A helper for displaying the price value of a unit. It takes the price
 * in pennies and displays it in the user's locale.
 */
export function UnitPrice({ className, price, ...rest }: UnitPriceProps) {
  return (
    <span
      data-testid="UnitPrice"
      className={combineClasses(styles.UnitPrice, className)}
      {...rest}
    >
      {formatCurrency(price * 100)}
    </span>
  )
}
