import React from 'react'
import PropTypes from 'prop-types'
import useTimeout from 'react-use/lib/useTimeout'

import { combineClasses, formatTime, formatDoorCode } from '~/util'
import { TitleValuePairVertical } from '../titles'

import styles from './DoorCode.module.scss'

/**
 * `<DoorCode>` displays the access code for
 * an apartment and the length of time that code
 * can be used. If the code is not available yet
 * or is in the future, it is not displayed to
 * ensure the user cannot access the unit.
 *
 * @param {object} props
 * @param {string | number} [props.doorCode]
 * @param {string} [props.accessStart]
 * @param {string} [props.accessEnd]
 * @param {number} [props.refreshRate]
 * @param {number} [props.warningTime]
 * @param {string} [props.className]
 */
export function DoorCode({
  doorCode,
  accessStart,
  accessEnd,
  refreshRate = 15,
  warningTime = 15,
  className,
  ...rest
}) {
  const start = new Date(accessStart)
  const end = new Date(accessEnd)

  const started = Date.now() >= start
  const ended = Date.now() >= end
  const canAccess = started && !ended

  const remaining = end.getTime() - Date.now()
  const minutesLeft = Math.round(remaining / 60000)
  const almostUp = canAccess && minutesLeft < warningTime

  useTimeout(refreshRate * 1000)

  return (
    <div
      data-testid="DoorCode"
      className={combineClasses(
        styles.DoorCode,
        className,
        canAccess ? styles.accessible : null,
        almostUp ? styles.almostUp : null
      )}
      {...rest}
    >
      <TitleValuePairVertical
        data-testid="doorCode"
        title="Door Code"
        className={styles.code}
      >
        <span data-testid="code" className={styles.codeValue}>
          {canAccess
            ? formatDoorCode(doorCode)
            : ended
            ? 'Expired'
            : 'No Access'}
        </span>
      </TitleValuePairVertical>

      <TitleValuePairVertical title="Access Time" className={styles.accessTime}>
        <span data-testid="accessTime" className={styles.time}>
          {formatTime(accessStart)} - {formatTime(accessEnd)}
        </span>
      </TitleValuePairVertical>

      {canAccess && (
        <TitleValuePairVertical
          title="Remaining"
          className={styles.timeRemaining}
        >
          <span data-testid="remaining" className={styles.time}>
            {minutesLeft} min
          </span>
        </TitleValuePairVertical>
      )}
    </div>
  )
}

DoorCode.propTypes = {
  /**
   * The code for unlocking the door.
   */
  doorCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  /**
   * The ISO date that access can begin.
   */
  accessStart: PropTypes.string.isRequired,
  /**
   * The ISO date that access ends.
   */
  accessEnd: PropTypes.string.isRequired,
  /**
   * The rate in seconds at which the component minutes remaining
   * will be refreshed.
   */
  refreshRate: PropTypes.number,
  /**
   * The number of minutes before `accessEnd` that
   * the component will show a warning that access is about to end.
   */
  warningTime: PropTypes.number,
  /**
   * Any other props you pass will be applied to the
   * root HTMLElement.
   */
  'other props...': PropTypes.any,
}
