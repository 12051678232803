import React from 'react'

import { combineClasses } from '~/util'

import './Loader.scss'

import LoaderIcon from './icon/loader.svg?react'
import './icon/loader.css'

/**
 * @typedef {object} LoaderProps
 * @extends HTMLDivElement
 * @property {string} [className]
 */

/**
 * @type {React.ForwardRefRenderFunction<HTMLDivElement, LoaderProps>}
 */
export default React.forwardRef(({ className, ...rest }, ref) => {
  return (
    <div
      className={combineClasses('loader-component', className)}
      {...rest}
      ref={ref}
    >
      <LoaderIcon name="loaderComponent" className="loader" />
    </div>
  )
})
