import React from 'react'
import PropTypes from 'prop-types'

import styles from './KeyValuePairs.module.scss'

import { combineClasses } from '~/util'

export function KeyValuePairs({
  values,
  renderKey,
  renderValue,
  className,
  ...rest
}) {
  const getValueTestName = (value) =>
    (value && value['data-test']) ||
    (Array.isArray(value) && value[2]) ||
    'value'

  return (
    <table
      data-test="keyValuePairs"
      className={combineClasses(styles.KeyValuePairs, className)}
      {...rest}
    >
      <tbody>
        {values.map((value, i) => (
          <tr key={i}>
            <td
              data-test="key"
              className={combineClasses(styles.key, 'KeyValuePairs-key')}
            >
              {renderKey
                ? // If we have a callback for rendering the key
                  renderKey(value)
                : // If we passed a multi-dimensional array
                Array.isArray(value)
                ? // use the first element as the key
                  value[0]
                : // If we passed an object
                value.key
                ? // and it has a key property
                  value.key
                : // Otherwise just use the value
                  value}
            </td>
            <td
              data-test={getValueTestName(value)}
              className={combineClasses(styles.value, 'KeyValuePairs-value')}
            >
              {renderValue
                ? // If we have a callback for rendering the value
                  renderValue(value)
                : // If we passed a multi-dimensional array
                Array.isArray(value)
                ? // use the second element as the key
                  value[1]
                : // If we passed an object
                value.value
                ? // and it has a value property
                  value.value
                : // Otherwise just use the value
                  value}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

KeyValuePairs.propTypes = {
  values: PropTypes.array.isRequired,
  renderKey: PropTypes.func,
  renderValue: PropTypes.func,
}
