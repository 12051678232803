/// <reference types="vite-plugin-svgr/client" />
import React from 'react'
import PropTypes from 'prop-types'

import {
  combineClasses,
  formatDate,
  formatDateWithTime,
  isDateToday,
} from '~/util'

import { SubTitle } from '../titles'
import { AddToCalendar } from '../add-to-calendar'
import { Lockbox } from '../lockbox'

import CalendarIcon from '~/assets/icons/calendar.svg?react'
import styles from './AppointmentWelcomeMessage.module.scss'
import { Address, DateLike } from '~/service'

export interface AppointmentWelcomeMessageProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The primary line of the welcome message.
   */
  title?: string
  /**
   * The subtitle of the welcome message. Defaults to "Your tour is scheduled
   * for X". Pass `null` to hide the subtitle.
   */
  subtitle?: string | null
  communityName?: string
  primaryProspect?: string
  /**
   * The community address.
   */
  address?: Address
  startDatetime?: DateLike
  /**
   * The title of the calendar event, should the user choose to add the tour
   * date to their calendar.
   */
  calendarEventTitle?: string
  /**
   * The description of the calendar event should the user choose to add their
   * tour to their calendar.
   */
  calendarEventDescription?: string
  /**
   * The URL to an image that describes how the lock boxes work for this
   * community.
   */
  lockInstructionImageUrl?: string
  /**
   * Textual instructions for using the lock boxes at this community.
   */
  lockInstructionText?: string
}

/**
 * `<AppointmentWelcomeMessage>` shows a welcome message on the tour page with
 * the user's tour date.
 */
export function AppointmentWelcomeMessage({
  className,
  communityName,
  primaryProspect,
  address,
  startDatetime,
  title = primaryProspect
    ? `Hi ${primaryProspect}!`
    : 'Get Excited For Your Tour!',
  subtitle = startDatetime
    ? `Your tour is scheduled for ${
        isDateToday(startDatetime) ? 'today' : formatDate(startDatetime)
      }`
    : undefined,
  calendarEventTitle = `Your ${communityName} Tour`,
  // TODO The picture ID requirement needs to be conditional now
  calendarEventDescription = `We are excited for your tour! Please remember to bring a valid picture ID. Thank you!`,
  lockInstructionImageUrl,
  lockInstructionText,
  ...rest
}: AppointmentWelcomeMessageProps) {
  const eventLocation = [
    ...(address?.addressLines || []),
    address?.city,
    address?.state,
    address?.postalCode,
  ]
    .filter((a) => !a)
    .join(', ')
  const tempDate = startDatetime ? new Date(startDatetime) : null
  const event = startDatetime
    ? {
        title: calendarEventTitle,
        description: calendarEventDescription,
        location: eventLocation,
        startDatetime: startDatetime,
        endDatetime: tempDate
          ? new Date(tempDate.setHours(tempDate.getHours() + 1))
          : undefined,
      }
    : null

  return (
    <div
      data-testid="AppointmentWelcomeMessage"
      className={combineClasses(styles.AppointmentWelcomeMessage, className)}
      {...rest}
    >
      <SubTitle
        data-testid="appointmentSectionHeaderText"
        className={combineClasses(styles.headerText, styles.blueTitleText)}
        level={1}
      >
        {title}
      </SubTitle>
      {startDatetime && (
        <>
          <SubTitle
            level={2}
            className={styles.headerText}
            data-testid="appointmentSectionSubtitle"
          >
            {subtitle}
          </SubTitle>
          <section className={styles.addToCalendarSection}>
            {event?.startDatetime && (
              <div
                data-testid="startTimeDateString"
                className={styles.addToCalendarSectionDate}
              >
                {formatDateWithTime(event?.startDatetime)}
              </div>
            )}
            {event && (
              <div>
                <CalendarIcon className={styles.calendarIcon} />
                <AddToCalendar
                  className={styles.AddToCalendar}
                  data-testid="AddToCalendar"
                  title={event.title}
                  description={event.description}
                  location={event.location}
                  startDatetime={event.startDatetime}
                  endDatetime={event.endDatetime}
                />
              </div>
            )}
          </section>
        </>
      )}
      <br />
      {(lockInstructionText || lockInstructionImageUrl) && startDatetime && (
        <Lockbox
          lockInstructionImageUrl={lockInstructionImageUrl}
          lockInstructionText={lockInstructionText}
        />
      )}
    </div>
  )
}

AppointmentWelcomeMessage.propTypes = {
  /**
   * Name of Community.
   */
  communityName: PropTypes.string,
  /**
   * Name of the Prospect
   */
  primaryProspect: PropTypes.string,
  /**
   * startDatetime of the appointment.
   */
  startDatetime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  /**
   * address contains
   * {streetAddress, city, postalCode, state}
   */
  address: PropTypes.object,
  /**
   * sets the sizse of the title elements.
   */
  size: PropTypes.oneOf(['s', 'l']),
  /**
   * sets the appointment title.
   */
  appointmentTitle: PropTypes.string,
  /**
   * sets the appointment description.
   */
  appointmentDescription: PropTypes.string,
  /**
   * sets the lockbox PDF Link
   */
  lockInstructionImageUrl: PropTypes.string,
  /**
   * sets the lockbox code.
   */
  lockInstructionText: PropTypes.string,
}
