import React from 'react'
import {
  Accordion as Acc,
  AccordionItem as AccItem,
} from '@szhsin/react-accordion'

import Chevron from '~/assets/icons/chevron-down.svg?react'
import { combineClasses } from '~/util'

import styles from './Accordion.module.scss'

/**
 * @type {React.ExoticComponent<import('@szhsin/react-accordion').AccordionItemProps>}
 */
export const AccordionItem = ({
  header,
  buttonProps: { className: buttonClassName, ...buttonProps } = {},
  contentProps: { className: contentClassName, ...contentProps } = {},
  panelProps: { className: panelClassName, ...panelProps } = {},
  ...rest
}) => (
  <AccItem
    data-testid="AccordionItem"
    {...rest}
    header={
      <>
        {header}
        <Chevron className={styles.chevron} />
      </>
    }
    className={styles.AccordionItem}
    buttonProps={{
      'data-testid': 'AccordionItem-button',
      className: ({ isEnter }) =>
        combineClasses(
          styles.itemBtn,
          buttonClassName,
          isEnter && styles.itemBtnExpanded
        ),
      ...buttonProps,
    }}
    contentProps={{
      className: combineClasses(styles.itemContent, contentClassName),
      ...contentProps,
    }}
    panelProps={{
      className: combineClasses(styles.itemPanel, panelClassName),
      ...panelProps,
    }}
  />
)

/**
 * `<Accordion>`
 *
 * @param {object} props
 * @param {string} [props.className]
 */
export function Accordion({ className, transitionTimeout = 250, ...rest }) {
  return (
    <Acc
      data-testid="Accordion"
      transition
      transitionTimeout={transitionTimeout}
      className={combineClasses(className)}
      {...rest}
    />
  )
}
