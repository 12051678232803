import React from 'react'

import { NotFound } from '~/components/not-found'

import './NotFound.scss'

/**
 * Renders the "page not found" page
 *
 * @typedef {object} Props
 *
 * @extends {React.Component<Props>}
 */
export default function NotFoundPage({
  title = 'Sorry,',
  subtitle = 'We Lost That',
  ...rest
}) {
  console.log('[NotFound]', rest)
  return <NotFound title={title} subtitle={subtitle} />
}
