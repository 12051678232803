import React from 'react'
import ReactModal from 'react-modal'
import { useSelector } from 'react-redux'
import { matchPath } from 'react-router'

import { PageLoader } from '~/components'
import { Analytics, Hex, ID, UUID, env, useAnalyticsClient } from '~/service'
import { combineClasses, generatePaletteColors } from '~/util'
import {
  useSelfTour,
  selectGuestCardId,
  selectTourInitialized,
  selectCommunityLogoUrl,
  selectCommunityName,
  selectCommunityId,
  selectCommunityVoicePhoneNumber,
  selectCommunityHasChat,
  selectCommunityChatURL,
  selectCommunityUsesPureChat,
  selectCommunityPureChatId,
  selectCommunityEmail,
  selectCommunityWebsite,
  selectLicenseNumber,
  selectCommunityContactName,
  selectCommunitySmsPhoneNumber,
  selectCommunityPrimaryColor,
  selectCommunitySecondaryColor,
} from '~/store'

import { NotFound } from '../not-found'
import { Footer } from './Footer'
import { Header } from './Header'
import { RouteHandler, DebugRouter } from './RouteHandler'
import { getRoute } from '../routes'

import { history as defaultHistory } from '../history'

import styles from './Main.module.scss'

interface MainProps {
  communityId: ID
  communityLogo: string
  communityName: string
  communityPhoneNumber?: string
  communityEmail?: string
  communityWebsite?: string
  licenseNumber?: string
  communityContactName?: string
  communitySmsPhoneNumber?: string
  uuid: UUID
  showChat?: boolean
  chatURL?: string
  pureChatId?: ID
  usePureChat?: boolean
  guestCardId: ID
  isSelfTour?: boolean
  className?: string
  animated?: boolean
  history?: typeof defaultHistory
  trackingId?: string
  trackingOptions?: Record<string, any>
  analytics: Analytics
}

export function Main({
  guestCardId,
  communityLogo,
  communityId,
  communityName,
  communityPhoneNumber,
  communityEmail,
  communityWebsite,
  licenseNumber,
  communityContactName,
  communitySmsPhoneNumber,
  uuid,
  showChat,
  chatURL,
  pureChatId,
  usePureChat,
  isSelfTour,
  className,
  animated,
  history,
  trackingId,
  trackingOptions,
  analytics,
  ...rest
}: MainProps) {
  const rootRef = React.createRef<HTMLDivElement>()

  React.useEffect(() => {
    ReactModal.setAppElement(rootRef.current!)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DebugRouter history={history || defaultHistory}>
      <div
        data-test="Main"
        ref={rootRef}
        className={combineClasses(styles.Main, className)}
        {...rest}
      >
        <Header
          uuid={uuid}
          logo={communityLogo}
          title={communityName}
          homeURL={getRoute(isSelfTour ? 'SELF_TOUR' : 'HOME', { uuid })}
          myTourURLs={[
            getRoute('MY_TOUR', { uuid }),
            getRoute('HOME', { uuid }),
            getRoute('ROOT', { uuid }),
          ]}
          myApartmentsURL={getRoute('INTERESTED_UNITS', { uuid })}
          myQuotesURL={getRoute('QUOTES', { uuid })}
          amenitiesURL={getRoute('AMENITIES', { uuid })}
          phoneNumber={communityPhoneNumber}
          showChat={showChat}
          chatURL={chatURL}
          pureChatId={pureChatId}
          usePureChat={usePureChat}
          isSelfTour={isSelfTour}
        />

        <RouteHandler
          communityId={communityId}
          communityName={communityName}
          guestCardId={guestCardId}
          guestCardUuid={uuid}
          trackingId={trackingId}
          trackingOptions={trackingOptions}
          {...rest}
        />

        <Footer
          communityName={communityName}
          communityPhoneNumber={communityPhoneNumber}
          communityEmail={communityEmail}
          communityWebsite={communityWebsite}
          licenseNumber={licenseNumber}
          communityContactName={communityContactName}
          communitySmsPhoneNumber={communitySmsPhoneNumber}
        />
      </div>
    </DebugRouter>
  )
}

/**
 * Set the value of a dynamic CSS size variable (ex. the header height)
 */
export function setDynamicSize(
  /**
   * The name of the CSS size variable being set. If the
   * variable is `--size-header-height`, then use `header-height`.
   */
  name: string,
  /**
   * The variable value to use. It will be converted to pixels.
   */
  value: number
) {
  if (
    getComputedStyle(document.documentElement).getPropertyValue(
      `--size-${name}`
    )
  ) {
    document.documentElement.style.setProperty(`--size-${name}`, `${value}px`)
  } else {
    console.warn('Unable to find dynamic size variable', name)
  }
}

/**
 * Define new palettes and override the global theme variables defined on the DocumentElement
 * based on the community's theme colors.
 *
 * @param {string} primary - The primary color hex value.
 * @param {string} secondary - The secondary color hex value.
 */
export function setThemeColors(primary: Hex, secondary: Hex) {
  if (primary) {
    const primaryColors = generatePaletteColors('primary', primary)
    primaryColors.forEach(({ name, color }) =>
      document.documentElement.style.setProperty(name, color)
    )
    // Use a dark primary color for focus states.
    document.documentElement.style.setProperty(
      `--light-foreground-focus`,
      primaryColors[7].color
    )
    document.documentElement.style.setProperty(
      `--light-background-focus`,
      primaryColors[7].color
    )
    // Use the main primary color as the HTML background.
    document.documentElement.style.setProperty(
      `--light-background-html`,
      primaryColors[4].color
    )

    // Calculate the grayscale using a bit of the primary color
    const grayscaleColors = generatePaletteColors('grayscale', primary, 30, 70)
    grayscaleColors.forEach(({ name, color }) =>
      document.documentElement.style.setProperty(name, color)
    )
    // Use the lightest grayscale color as the page loader spinner color. This
    // should show up nicely over the primary background color.
    document.documentElement.style.setProperty(
      `--light-foreground-loader`,
      grayscaleColors[1].color
    )
  }
  if (secondary) {
    const secondaryColors = generatePaletteColors('secondary', secondary)
    secondaryColors.forEach(({ name, color }) =>
      document.documentElement.style.setProperty(name, color)
    )
  }
}

export interface MainConnectedProps {
  /**
   * A callback that will be called after the main menu
   * modal is opened. Useful for testing.
   */
  onAfterModalOpen?: (...args: unknown[]) => unknown
  guestCardUuid?: UUID
  history?: typeof defaultHistory
  trackingId?: string
}

export default function MainConnected({
  // This should only be defined in development and test environments.
  guestCardUuid = env.guestCardUUID,
  onAfterModalOpen,
  history,
  ...rest
}: MainConnectedProps) {
  useSelfTour(guestCardUuid)
  const analytics = useAnalyticsClient()

  const initialized = useSelector(selectTourInitialized)
  const communityName = useSelector(selectCommunityName)
  const communityId = useSelector(selectCommunityId)
  const guestCardId = useSelector(selectGuestCardId)
  const communityPhoneNumber = useSelector(selectCommunityVoicePhoneNumber)
  const communityEmail = useSelector(selectCommunityEmail)
  const communityContactName = useSelector(selectCommunityContactName)
  const communityWebsite = useSelector(selectCommunityWebsite)
  const licenseNumber = useSelector(selectLicenseNumber)

  const communitySmsPhoneNumber = useSelector(selectCommunitySmsPhoneNumber)

  const useChat = useSelector(selectCommunityHasChat)
  const chatURL = useSelector(selectCommunityChatURL)
  const usePureChat = useSelector(selectCommunityUsesPureChat)
  const pureChatId = useSelector(selectCommunityPureChatId)

  const primaryColor = useSelector(selectCommunityPrimaryColor)
  const secondaryColor = useSelector(selectCommunitySecondaryColor)

  const pathname = (history || window).location.pathname
  const isSelfTour = !!matchPath(pathname, {
    path: getRoute('SELF_TOUR'),
  })

  // Initialize the page title.
  React.useEffect(() => {
    if (communityName) {
      document.title = `${communityName} Self-Tour`
    }
  }, [communityName])

  React.useEffect(() => {
    setThemeColors(primaryColor, secondaryColor)
  }, [primaryColor, secondaryColor])

  React.useEffect(() => {
    if (isSelfTour) {
      setDynamicSize('header-height', Number(styles.selfTourHeaderHeight))
    }
  }, [isSelfTour])

  const nextProps = {
    ...rest,
    analytics,
    uuid: guestCardUuid,
    guestCardId,
    communityId,
    communityName,
    communityPhoneNumber,
    communityEmail,
    communityWebsite,
    licenseNumber,
    communityContactName,
    communitySmsPhoneNumber,
    communityLogo: useSelector(selectCommunityLogoUrl),
    showChat: useChat,
    chatURL,
    pureChatId,
    usePureChat,
    isSelfTour,
    history,
  }

  if (initialized) {
    if (!guestCardUuid) {
      return <NotFound phoneNumber={communityPhoneNumber} lostThing="tour" />
    } else {
      return <Main {...nextProps} />
    }
  }

  return <PageLoader />
}
