import React from 'react'
import PropTypes from 'prop-types'
import Calendar from 'react-calendar'
import PrevIcon from '~/assets/icons/chevron-left.svg?react'
import NextIcon from '~/assets/icons/chevron-right.svg?react'

import { combineClasses } from '~/util'

import styles from './DatePicker.module.scss'

/**
 * `<DatePicker>` provides an easy way to select dates and date ranges.
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} [props.tileClassName]
 * @param {function} [props.onChange]
 * @param {Date | array} [props.value]
 * @param {Date} [props.minDate]
 * @param {Date} [props.maxDate]
 * @param {boolean} [props.selectRange]
 * @param {string} [props.view]
 */
export function DatePicker({
  className,
  tileClassName,
  minDate = new Date(),
  maxDate = new Date(new Date().setMonth(new Date().getMonth() + 6)),
  selectRange = true,
  view = 'month',
  value,
  onChange,
  ...rest
}) {
  /*
   * Disable the calendar component's year/decace/century button
   * by removing it's event listener since Calendar doesn't provide
   * a way to disable that functionality.
   * It also ignores minDate so it will go all the way back to
   * January even if its June.
   */
  const addElmentInFrontOfNavLabel = (querySelector) => {
    const divElement = document.createElement('div')
    divElement.className = 'navigation__label_cover'
    divElement.onclick = (event) => {
      event.stopPropagation()
      return false
    }
    var elem = document.querySelector(querySelector)
    elem?.insertAdjacentElement('beforeend', divElement)
  }

  React.useEffect(() => {
    if (view === 'month') {
      addElmentInFrontOfNavLabel('.react-calendar__navigation__label')
    }
  })
  return (
    <Calendar
      className={combineClasses(styles.Calendar, className)}
      tileClassName={combineClasses(styles.tileClassName, tileClassName)}
      prevLabel={<PrevIcon />}
      nextLabel={<NextIcon />}
      minDate={minDate}
      maxDate={maxDate}
      selectRange={selectRange}
      view={view}
      calendarType={'Hebrew'}
      value={value}
      onChange={onChange}
      drillUp={null}
      {...rest}
    />
  )
}

DatePicker.propTypes = {
  /**
   * Styles for the Calendar
   */
  className: PropTypes.string,
  /**
   * Styles for the tiles within the calendar.
   */
  tileClassName: PropTypes.string,
  /**
   * Calendar value.
   * Can be either one value or an array of two values.
   * If you wish to use React-Calendar in an uncontrolled way, use defaultValue instead.
   *
   * Ex:
   * Date: new Date()
   * An array of dates: [new Date(2017, 0, 1), new Date(2017, 7, 1)]
   */
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /**
   * onChange event handler.
   */
  onChange: PropTypes.func,
  /**
   * Min Date shown on the calendar.
   */
  minDate: PropTypes.object,
  /**
   * Max Date shown on the calendar.
   */
  maxDate: PropTypes.object,
  /**
   * Range of dates shown
   */
  selectRange: PropTypes.bool,
  /**
   * The calendar view 'month' | 'year' | 'decade' | 'century'.
   */
  view: PropTypes.string,
  /**
   * Any other props you pass will be passed along to the underlying
   * `react-calendar` component. For the full list, see
   * https://github.com/wojtekmaj/react-calendar#props
   */
  'react-calendar props...': PropTypes.any,
}
