import { createSlice } from '@reduxjs/toolkit'

// Simplified mechanism for creating a Reducer and its
// associated Actions.
// @see https://redux-starter-kit.js.org/usage/usage-guide#creating-slices-of-state
const selfTourSlice = createSlice({
  name: 'selftour',
  /**
   * @typedef {object} Amenity
   * @prop {number} id
   * @prop {string} name
   * @prop {boolean} [isInSelfTour]
   * @prop {string} [imageUrl]
   * @prop {object} [floorPlateImagePosition]
   */
  /**
   * @typedef {object} Community
   * @prop {Amenity[]} amenities
   */
  /**
   * @typedef {object} GuestCard
   * @prop {number[]} amenityIds
   */
  /**
   * @typedef {object} Data
   * @prop {Community} [community]
   * @prop {GuestCard} [guestCard]
   */
  /**
   * @typedef {object} State
   * @prop {Data} data
   * @prop {boolean} initialized
   * @prop {string} guestCardUuid
   * @prop {object[]} notices
   * @prop {boolean} userHasSignedSelfTourAgreement
   */
  /**
   * @type {State} initialState
   */
  initialState: {
    initialized: false,
    guestCardUuid: null,
    notices: [],
    data: {},
    userHasSignedSelfTourAgreement: false,
  },

  reducers: {
    setSelfTourInitialized(draftState, action) {
      draftState.initialized = true
    },

    getSelfTourSuccess(draftState, action) {
      // Under the hood, this uses the Immer library, allowing you
      // to mutate the state directly because we are receiving a
      // draft version of the state here. You MUST mutate the `draftState`
      // given to you; you CANNOT return a new state object.
      draftState.guestCardUuid = action.payload.guestCardUuid
      draftState.data = action.payload.tour
      draftState.userHasSignedSelfTourAgreement =
        action.payload.userHasSignedSelfTourAgreement
    },
    getSelfTourFailure(draftState, action) {
      draftState.initialized = true
      // TODO Check for userMessage properties and if not defined,
      // set them.
      draftState.notices = action.payload.notices
    },

    getVariableRentSuccess(draftState, action) {
      draftState.initialized = true
    },
    getVariableRentFailure(draftState, action) {
      draftState.initialized = true
      draftState.notices = action.payload.notices
    },

    /**
     * Saves a user's move in date preferences.
     */
    setUserMoveInDates(draftState, action) {
      // Save the previous values so they can be rolled back.
      draftState.previousMoveInDates = {
        earliestMoveIn: draftState.data.guestCard.earliestMoveIn,
        latestMoveIn: draftState.data.guestCard.latestMoveIn,
      }

      draftState.data.guestCard.earliestMoveIn = action.payload.earliestMoveIn
      draftState.data.guestCard.latestMoveIn = action.payload.latestMoveIn
    },
    /**
     * Rolls back the user's move in date preferences if the
     * API request failed.
     */
    setUserMoveInDatesFailure(draftState, action) {
      // Only rollback if the action matches the current state.
      if (
        draftState.data.guestCard.earliestMoveIn ===
          action.payload.earliestMoveIn &&
        draftState.data.guestCard.latestMoveIn ===
          action.payload.latestMoveIn &&
        draftState.previousMoveInDates
      ) {
        draftState.data.guestCard.earliestMoveIn =
          draftState.previousMoveInDates.earliestMoveIn
        draftState.data.guestCard.latestMoveIn =
          draftState.previousMoveInDates.latestMoveIn
        draftState.previousMoveInDates = undefined
      }
    },
    /**
     * Saves a user's move in date preferences.
     */
    setOccupants(draftState, action) {
      // Save the previous values so they can be rolled back.
      draftState.previousOccupants = {
        totalTenants: draftState.data.guestCard.totalTenants,
        totalDogs: draftState.data.guestCard.totalDogs,
        totalCats: draftState.data.guestCard.totalCats,
        totalOtherAnimals: draftState.data.guestCard.totalOtherAnimals,
      }

      draftState.data.guestCard.totalTenants = action.payload.totalTenants
      draftState.data.guestCard.totalDogs = action.payload.totalDogs
      draftState.data.guestCard.totalCats = action.payload.totalCats
      draftState.data.guestCard.totalOtherAnimals =
        action.payload.totalOtherAnimals
    },
    /**
     * Rolls back the user's move in date preferences if the
     * API request failed.
     */
    setOccupantsFailure(draftState, action) {
      // Only rollback if the action matches the current state.
      if (
        draftState.data.guestCard.totalTenants ===
          action.payload.totalTenants &&
        draftState.data.guestCard.totalDogs === action.payload.totalDogs &&
        draftState.data.guestCard.totalCats === action.payload.totalCats &&
        draftState.data.guestCard.totalOtherAnimals ===
          action.payload.totalOtherAnimals &&
        draftState.previousOccupants
      ) {
        draftState.data.guestCard.totalTenants =
          draftState.previousOccupants.totalTenants
        draftState.data.guestCard.totalDogs =
          draftState.previousOccupants.totalDogs
        draftState.data.guestCard.totalCats =
          draftState.previousOccupants.totalCats
        draftState.data.guestCard.totalOtherAnimals =
          draftState.previousOccupants.totalOtherAnimals
        draftState.previousOccupants = undefined
      }
    },
    /**
     * Saves a user's price preferences.
     */
    setMaxPrice(draftState, action) {
      // Save the previous values so they can be rolled back.
      draftState.previousMaxPrice = {
        maxPrice: draftState.data.guestCard.maxPrice,
      }

      draftState.data.guestCard.maxPrice = action.payload.maxPrice
    },
    /**
     * Rolls back the user's price preferences if the
     * API request failed.
     */
    setMaxPriceFailure(draftState, action) {
      // Only rollback if the action matches the current state.
      if (
        draftState.data.guestCard.maxPrice === action.payload.maxPrice &&
        draftState.previousMaxPrice
      ) {
        draftState.data.guestCard.maxPrice =
          draftState.previousMaxPrice.maxPrice
        draftState.previousMaxPrice = undefined
      }
    },

    /**
     * Saves a user's bed and bath preferences.
     */
    setBedAndBaths(draftState, action) {
      // Save the previous values so they can be rolled back.
      draftState.previousBedBathIds = {
        bedBathIds: draftState.data.guestCard.bedBathIds,
      }
      draftState.data.guestCard.bedBathIds = action.payload.bedBathIds
    },
    /**
     * Rolls back the user's move in date preferences if the
     * API request failed.
     */
    setBedAndBathsFailure(draftState, action) {
      // Only rollback if the action matches the current state.
      if (
        draftState.data.guestCard.bedBathIds === action.payload.bedBathIds &&
        draftState.previousBedBathIds
      ) {
        draftState.data.guestCard.bedBathIds =
          draftState.previousBedBathIds.bedBathIds
        draftState.previousBedBathIds = undefined
      }
    },

    addUnitToFavorites(draftState, action) {
      const unitId = action.payload.unitId
      const guestCard = draftState.data.guestCard
      const community = draftState.data.community
      const unit = community.units.find((u) => u.id === unitId)

      if (!guestCard.interestedUnits.find((u) => u.id === unitId)) {
        // TODO Update the store architecture so we don't have to
        // modify both the interestedUnits and interestedUnitIds
        guestCard.interestedUnits = [...guestCard.interestedUnits, unit]
        guestCard.interestedUnitIds = guestCard.interestedUnits.map((u) => u.id)
      }
    },

    removeUnitFromFavorites(draftState, action) {
      const unitId = action.payload.unitId
      const guestCard = draftState.data.guestCard
      guestCard.interestedUnits = guestCard.interestedUnits.filter(
        (u) => u.id !== unitId
      )
      guestCard.interestedUnitIds = guestCard.interestedUnits.map((u) => u.id)
    },

    setUserHasSignedSelfTourAgreement(draftState, action) {
      draftState.userHasSignedSelfTourAgreement = true
    },

    setUserVerificationComplete(draftState, action) {
      const idVerification = action.payload.inquiryId
      const prospect = draftState.data.guestCard.primaryProspect
      prospect.idVerification = idVerification
    },
  },
})

// Extract the action creators object and the reducer
const { actions, reducer } = selfTourSlice

// Extract and export each action creator by name
export const {
  setSelfTourInitialized,
  getSelfTourSuccess,
  getSelfTourFailure,
  getVariableRentSuccess,
  getVariableRentFailure,
  addUnitToFavorites,
  removeUnitFromFavorites,
  setUserHasSignedSelfTourAgreement,
  setUserMoveInDates,
  setUserMoveInDatesFailure,
  setOccupants,
  setOccupantsFailure,
  setMaxPrice,
  setMaxPriceFailure,
  setBedAndBaths,
  setBedAndBathsFailure,
  setUserVerificationComplete,
} = actions

// Export the reducer, either as a default or named export
export default reducer
