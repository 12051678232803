import React from 'react'
import Axios from 'axios'
import axiosRetry from 'axios-retry'
import { networkError, unknownError } from './errors'
import { ServiceBase } from '@thesoulfresh/utils'

import { env } from './env'
import { fromSelfTour } from './fromSelfTour'

// Get the CancelToken reference for creating request cancel tokens.
const CancelToken = Axios.CancelToken

export const communityUrl = `${env.apiURL}/api`
export const selfTourUrl = `${env.apiURL}/self-tour/api`
export const variableRentUrl = `${selfTourUrl}/unit-variable-rent`

function makeError(error) {
  // No network connection or the API service is down.
  if (error.message === 'Network Error') {
    return networkError
  }

  // The service responded, but with an error.
  if (error.response) {
    return error.response.data || unknownError
  }

  return unknownError
}

export default class SelfTourService extends ServiceBase {
  constructor(debug, retries = 3, axe = Axios) {
    if (retries) {
      // Add the retry plugin to axios.
      axiosRetry(axe, { retries, retryDelay: axiosRetry.exponentialDelay })
    }

    super(axe, debug)
    this.log(`connected to ${env.apiURL}`)
  }

  async get(uuid) {
    try {
      const response = await this.client.get(`${selfTourUrl}/${uuid}`)

      let variableRent
      if (response.data.body.config.isVariableRentEnabled === true) {
        variableRent = await this.getVariableRentData(uuid)
      }

      this.log(`get(${uuid}) Response:`, response)
      const out = fromSelfTour.tour(response.data.body, variableRent)
      this.log(`get(${uuid}) SUCCESS:`, out)
      return out
    } catch (error) {
      this.error(error)
      throw makeError(error)
    }
  }

  async getVariableRentData(uuid) {
    const response = await this.client.get(`${variableRentUrl}/${uuid}`)

    this.log(`getVariableRentData(${uuid})`, response)
    return response.data
  }

  async addAmenityToSelfTour(guestCardUuid, amenityId) {
    try {
      const url = `${selfTourUrl}/${guestCardUuid}/amenities?amenityId=${amenityId}`
      const response = await this.client.post(url)
      this.log(`addAmenityToSelfTour(${guestCardUuid}, ${amenityId})`, response)
      return response.data
    } catch (error) {
      this.error(error)
      throw makeError(error)
    }
  }

  async removeAmenityFromSelfTour(guestCardUuid, amenityId) {
    try {
      const url = `${selfTourUrl}/${guestCardUuid}/amenities?amenityId=${amenityId}`
      const response = await this.client.delete(url)
      this.log(`addAmenityToSelfTour(${guestCardUuid}, ${amenityId})`, response)
      return response.data
    } catch (error) {
      this.error(error)
      throw makeError(error)
    }
  }

  /**
   * Add a unit to the user's interested units list.
   * @param {string} guestCardUuid
   * @param {number} unitId
   * @returns {Promise<object>}
   */
  addUnitToFavorites(guestCardUuid, unitId) {
    const source = CancelToken.source()
    const url = `${selfTourUrl}/${guestCardUuid}/interested-units/${unitId}`

    const p = this.client
      .post(url, {}, { cancelToken: source.token })
      .then((response) => {
        this.log(`addUnitToFavorites(${guestCardUuid}, ${unitId})`, response)
        return response.data.body
      })
      .catch((error) => {
        if (this.client.isCancel(error)) {
          this.debug('addUnitToFavorites CANCELLED')
        } else {
          this.error(error)
          throw makeError(error)
        }
      })

    // @ts-ignore
    p.cancel = source.cancel

    return p
  }

  /**
   * Remove a unit to the user's interested units list.
   * @param {string} guestCardUuid
   * @param {number} unitId
   * @returns {Promise<object>}
   */
  removeUnitFromFavorites(guestCardUuid, unitId) {
    const source = CancelToken.source()
    const url = `${selfTourUrl}/${guestCardUuid}/interested-units/${unitId}`

    const p = this.client
      .delete(url, { cancelToken: source.token })
      .then((response) => {
        this.log(
          `removeUnitFromFavorites(${guestCardUuid}, ${unitId})`,
          response
        )
        return response.data.body
      })
      .catch((error) => {
        if (this.client.isCancel(error)) {
          this.debug('removeUnitFromFavorites CANCELLED')
        } else {
          this.error(error)
          throw makeError(error)
        }
      })

    // @ts-ignore
    p.cancel = source.cancel

    return p
  }

  /**
   * Notifies the backend that the user is using the app.
   *
   * @param {*} guestCardUuid - The id of the guest card being updated.
   * @returns {Promise<object>} Returns a promise that resolves to the
   *   API response body. The promise includes a cancel method which you
   *   can call to cancel the request mid-flight. When this happens, the
   *   request will resolve to `undefined`.
   */
  postUserAccessTracker(guestCardUuid) {
    const source = CancelToken.source()
    const url = `${selfTourUrl}/track/${guestCardUuid}`
    const p = this.client
      .post(url, {}, { cancelToken: source.token })
      .then((response) => {
        this.log(`postUserAccessTracker(${guestCardUuid}`, response)
        return response.data.body
      })
      .catch((error) => {
        if (this.client.isCancel(error)) {
          this.debug('postUserAccessTracker CANCELLED')
        } else {
          this.error(error)
          throw makeError(error)
        }
      })

    p.cancel = source.cancel
    return p
  }

  /**
   * Updated details about a guest card.
   *
   * @param {string} guestCardUuid - The id of the guest card being updated.
   * @param {object} data - The values to set on the guest card.
   * @returns {Promise<object>} Returns a promise that resolves to the
   *   API response body. The promise includes a cancel method which you
   *   can call to cancel the request mid-flight. When this happens, the
   *   request will resolve to `undefined`.
   */
  setGuestCardDetails(guestCardUuid, data) {
    const source = CancelToken.source()
    const url = `${selfTourUrl}/${guestCardUuid}`

    const p = this.client
      .patch(url, data, { cancelToken: source.token })
      .then((response) => {
        this.log(
          `setGuestCardDetails(${guestCardUuid}, ${JSON.stringify(data)})`,
          response
        )
        return response.data.body
      })
      .catch((error) => {
        if (this.client.isCancel(error)) {
          this.debug('setGuestCardDetails CANCELLED')
        } else {
          this.error(error)
          throw makeError(error)
        }
      })

    // @ts-ignore
    p.cancel = source.cancel

    return p
  }

  /**
   * Tell the backend that a user has completed the verification process.
   *
   * @parma {string} communityId - The id of the current guest card
   * @param {string} prospectId - The id of the user
   * @param {string} inquiryId - The id of the verificaton inquiry returned by
   *   the Persona API.
   */
  setUserVerificationComplete(guestCardUuid, prospectId, inquiryId) {
    const source = CancelToken.source()
    const url = `${selfTourUrl}/${guestCardUuid}/guest-card/prospect/id-inquiry`
    const data = {
      prospectiveTenantId: prospectId,
      thirdPartyKey: inquiryId,
      status: 'COMPLETED',
      // TEMP This is currently required by the API but will be removed in the
      // future. Hardcoding it until it is removed.
      type: 'Selfie',
    }

    const p = this.client
      .post(url, data, { cancelToken: source.token })
      .then((response) => {
        this.log(
          `setUserVerificationComplete(${guestCardUuid}, ${prospectId}, ${inquiryId})`,
          response
        )
        return response.data.body
      })
      .catch((error) => {
        if (this.client.isCancel(error)) {
          this.debug('setUserVerificationComplete CANCELLED')
        } else {
          this.error(error)
          throw makeError(error)
        }
      })

    // @ts-ignore
    p.cancel = source.cancel

    return p
  }
}

/**
 * @type {import('react').Context<SelfTourService>}
 */
export const SelfTourAPIContext = React.createContext(undefined)
/**
 * @type {SelfTourAPIContext.Provider}
 */
export const SelfTourAPIProvider = SelfTourAPIContext.Provider

export function useSelfTourAPIClient() {
  return React.useContext(SelfTourAPIContext)
}
