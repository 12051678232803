import React from 'react'
import styles from './UnitQuickFacts.module.scss'

import { combineClasses, formatBeds, formatBaths, formatFootage } from '~/util'

interface UnitQuickFactsProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Number Of bedrooms
   */
  bedroomCount?: number
  /**
   * Number Of bathrooms
   */
  bathroomCount?: number
  /**
   * Square Footage of home.
   */
  squareFootageCount?: number
}

export function UnitQuickFacts({
  className,
  bedroomCount,
  bathroomCount,
  squareFootageCount,
  ...rest
}: UnitQuickFactsProps) {
  return (
    <div
      data-testid="UnitQuickFacts"
      className={combineClasses(styles.UnitQuickFacts, className)}
      {...rest}
    >
      <span className={styles.detail} data-testid="bedroomCount">
        {formatBeds(bedroomCount)}
      </span>
      {bathroomCount && (
        <span className={styles.detail} data-testid="bathroomCount">
          {formatBaths(bathroomCount)}
        </span>
      )}
      {squareFootageCount && (
        <span className={styles.detail} data-testid="squareFootageCount">
          {formatFootage(squareFootageCount)}
        </span>
      )}
    </div>
  )
}
