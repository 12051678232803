import React from 'react'
import PropTypes from 'prop-types'

import { combineClasses } from '~/util'

import styles from './ProgressBar.module.scss'

/**
 * `<ProgressBar>`
 *
 * Displays a progress bar that shows the fraction and visual representation of progress.
 *
 * @param {object} props
 * @param {string} [props.className]
 *
 * @param {number} [props.progress]
 * @param {number} [props.total]
 */
export function ProgressBar({ className, progress = 1, total = 4, ...rest }) {
  if (progress > total) {
    let temp = progress
    progress = Math.min(progress, total)
    total = Math.max(temp, total)
  }

  let text = `${progress}/${total}`

  return (
    <div
      data-testid="ProgressBarContainer"
      className={combineClasses(styles.ProgressBar, className)}
      {...rest}
    >
      <div
        data-testid="progressBar"
        className={combineClasses(styles.innerDiv)}
        style={{ width: `${(progress / total) * 100}%` }}
      >
        <span data-testid="progressBarText">{text}</span>
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  /**
   * current state of progress
   */
  progress: PropTypes.number,
  /**
   * number of stages to be completed.
   */
  total: PropTypes.number,
}
