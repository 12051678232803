import React from 'react'
import PropTypes from 'prop-types'
import {
  LazyLoadImage,
  trackWindowScroll,
} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/opacity.css'
// @ts-ignore
import { Image as CloudinaryImage } from 'cloudinary-react'
import { CloudinaryContext, Transformation } from 'cloudinary-react'
import { combineClasses, getCloudinaryTPK } from '~/util'
import { Loader } from '../loader'
import { MediaItemPropType } from '~/store'
import { env } from '~/service'

import AlertIcon from '~/assets/icons/alert-triangle.svg?react'
import styles from './Image.module.scss'

// Re-exported for ease of use.
export { trackWindowScroll }

/**
 * @typedef {object} ImageProps
 * @property {*} [ref]
 * @property {object} [mediaItem] - The media item defining the image to load. It is
 *   better to pass the media item when possible because this will give better results
 *   for Cloudinary based responsive images.
 * @property {string} [src] - The image src if not using the media item prop. If passing
 *   both, the media item takes precedent.
 * @property {string} [cloudinaryAPIKey] - The name of the PowerPro Cloudinary account
 *   (the cloud name in Cloudinary terminology).
 * @property {boolean} [loader] - Whether or not to show a loader.
 * @property {boolean} [lazy] - Whether or not to defer loading the image
 *   until after it has entered the viewport. This should only be used for
 *   images that are guaranteed to be out of the viewport on first load.
 * @property {boolean} [responsive] - Turning this on will request the image at the
 *   dimensions of it's parent container or the dimensions set on the image. This
 *   can help reduce network bandwidth by loading smaller images on mobile devices.
 *   This functionality works with images from the Cloudinary domain.
 * @property {string} [fit] - The object fit setting to use for the image element.
 *   It is better to use this fit prop than to try to set the object-fit on the image
 *   element because using the fit prop will also set necessary styles on the wrapping
 *   span elements necessary for rendering the loader and error sub components.
 * @property {string} [display] - This will set the display CSS property of the underlying
 *   image element and will also set the correctly display value for the wrapping span
 *   elements to support what you need. Using this property will give you better results
 *   than trying to set this styling directly through CSS.
 * @property {number|string} [width] - Specific width for the underlying image element.
 *   This will also set the approapriate styling for the wrapping elements so the image
 *   behaves as you would expect.
 * @property {number|string} [height] - Specific height for the underlying image element.
 *   This will also set the approapriate styling for the wrapping elements so the image
 *   behaves as you would expect.
 * @property {boolean} [transparent] - Removes the background color behind the image.
 * @property {string} [alt]
 * @property {string} [className]
 * @property {object} [style]
 * @property {function} [onError]
 * @property {function} [onLoad] - calls a parent function when image is loaded
 */
/**
 * @type React.FC<ImageProps>
 */
export const Image = React.forwardRef(
  (
    {
      className,
      transparent = false,
      loader = true,
      lazy = false,
      style,
      alt,
      fit = 'cover',
      mediaItem,
      src = mediaItem?.url || mediaItem?.previewUrl,
      cloudinaryAPIKey = env.cloudinaryAPIKey,
      width = '100%',
      height = '100%',
      responsive = false,
      display,
      onError,
      onLoad = () => {},
      ...props
    },
    ref
  ) => {
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    // See if the media item or src are Cloudinary images.
    const cloudinaryTPK = getCloudinaryTPK(mediaItem, src, cloudinaryAPIKey)

    // TODO Replace images throughout
    // TODO Support width/height in %
    // TODO Fade in on Cloudinary images
    const handleCloudinaryImageError = (e) => {
      setError(true)
      if (onError) onError(e)
    }

    const handleLazyLoadError = (e) => {
      setError(true)
      if (onError) onError(e)
    }

    return (
      <span
        className={combineClasses(
          styles.Image,
          className,
          transparent ? styles.transparent : null
        )}
        style={{
          ...style,
          display,
          width: typeof width === 'number' ? `${width}px` : width,
          height: typeof height === 'number' ? `${height}px` : height,
        }}
      >
        {loader && loading && !error && (
          <Loader
            data-testid="Loader"
            className={combineClasses(styles.Loader, 'loader')}
          />
        )}
        {error && (
          <AlertIcon
            aria-label={alt}
            className={combineClasses(styles.ErrorIcon, 'error')}
            data-testid="error"
          >
            <title>{alt}</title>
          </AlertIcon>
        )}
        {cloudinaryTPK && src && !error && (
          <CloudinaryContext
            cloudName={cloudinaryAPIKey}
            style={{
              display: 'block',
              objectFit: fit,
              width: width,
              height: width,
            }}
            responsiveUseBreakpoints={responsive}
            width={responsive ? 'auto' : undefined}
            crop={responsive ? 'fit' : width || height ? 'fill' : undefined}
            dpr="auto"
            {...props}
            onError={handleCloudinaryImageError}
          >
            <CloudinaryImage
              ref={ref}
              data-testid="content"
              data-type="cloudinary"
              className={combineClasses(styles.cloudinaryImage, 'image')}
              publicId={cloudinaryTPK}
              alt={alt}
              src={src}
              loading={lazy ? 'lazy' : undefined}
              style={{
                display: 'block',
                objectFit: fit,
                width: width,
                height: width,
              }}
              crop={responsive ? 'fit' : width || height ? 'fill' : undefined}
              dpr="auto"
              {...props}
              onError={handleCloudinaryImageError}
            >
              {!responsive && (width || height) && (
                <Transformation width={width} height={height} />
              )}
              <Transformation quality="auto" fetchFormat="auto" />
            </CloudinaryImage>
          </CloudinaryContext>
        )}
        {!cloudinaryTPK && src && !error && (
          <LazyLoadImage
            ref={ref}
            data-testid="content"
            data-type="native"
            className={combineClasses(styles.lazyImage, 'image')}
            style={{
              display: 'block',
              objectFit: fit,
              width: '100%',
              height: '100%',
            }}
            wrapperClassName={combineClasses(
              styles.wrapperClass,
              'image-wrapper'
            )}
            visibleByDefault={!lazy}
            wrapperProps={{
              style: { display: 'inline' },
              'data-testid': 'imageWrapper',
            }}
            alt={alt}
            src={src}
            {...props}
            beforeLoad={() => setLoading(true)}
            afterLoad={() => {
              setLoading(false)
              onLoad()
            }}
            onError={handleLazyLoadError}
          />
        )}
      </span>
    )
  }
)

Image.propTypes = {
  /**
   * The media item defining the image to load. It is
   * better to pass the media item when possible because this will give better results
   * for Cloudinary based responsive images.
   */
  mediaItem: MediaItemPropType,
  /**
   * The image src if not using the media item prop. If passing
   * both, the media item takes precedent.
   */
  src: PropTypes.string,
  /**
   * The name of the PowerPro Cloudinary account
   * (the cloud name in Cloudinary terminology).
   */
  cloudinaryAPIKey: PropTypes.string,
  /**
   * Whether or not to defer loading the image until after it has entered
   * the viewport. This should only be used for images that are guaranteed
   * to be out of the viewport on first load.
   */
  lazy: PropTypes.bool,
  /**
   * Turning this on will request the image at the
   * dimensions of it's parent container or the dimensions set on the image. This
   * can help reduce network bandwidth by loading smaller images on mobile devices.
   * This functionality works with images from the Cloudinary domain.
   */
  responsive: PropTypes.bool,
  /**
   * The object fit setting to use for the image element.
   * It is better to use this fit prop than to try to set the object-fit on the image
   * element because using the fit prop will also set necessary styles on the wrapping
   * span elements (used for rendering the loader and error sub components).
   */
  fit: PropTypes.string,
  /**
   * This will set the display CSS property of the underlying
   * image element and will also set the display value for the wrapping span
   * elements to support what you need. Using this property will give you better results
   * than trying to set this styling directly through CSS.
   */
  display: PropTypes.string,
  /**
   * Specific width for the underlying image element.
   * This will also set the approapriate styling for the wrapping elements so the image
   * behaves as you would expect.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Specific height for the underlying image element.
   * This will also set the approapriate styling for the wrapping elements so the image
   * behaves as you would expect.
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Whether or not to show the background color while the image is loading.
   */
  transparent: PropTypes.bool,
  /**
   * Whether or not to show the loader.
   */
  loader: PropTypes.bool,
  /**
   * You can pass any other properties that can be applied to standard `<img>`
   * elements or the underlying `react-lazy-load-image-component` or `cloudinary-react`
   * depending on if the image is coming from Cloudinary or not.
   */
  'other props...': PropTypes.any,
}
