import React from 'react'
import PropTypes from 'prop-types'

import { combineClasses } from '~/util'

import {
  ItemName,
  Label,
  UnitQuickFacts,
  ExpandingUnorderedList,
  Action,
} from '~/components'

import styles from './UnitSummary.module.scss'

export function UnitSummary({
  className,
  detailColumnClassName,
  floorPlanImageURL,
  floorPlanName,
  beds,
  baths,
  squareFootage,
  features,
  unitLink,
  unitNumber,
  buildingNumber,
  ...rest
}) {
  const [floorPlanImageError, setFloorPlanImageError] = React.useState(false)

  return (
    <div
      data-testid="UnitSummary"
      className={combineClasses(styles.UnitSummary, className)}
      {...rest}
    >
      <div className={styles.column}>
        <div className={styles.floorPlanImageUnitNumberContainer}>
          {unitNumber && (
            <Label
              data-testid="unitNumber"
              className={
                !floorPlanImageError
                  ? styles.unitNumber
                  : styles.unitNumberNoFloorPlan
              }
              size={'s'}
            >
              {unitNumber}
            </Label>
          )}
          {floorPlanImageURL && (
            <img
              data-testid="floorPlanImage"
              className={
                !floorPlanImageError
                  ? styles.floorPlanImage
                  : styles.floorPlanAltText
              }
              src={floorPlanImageURL}
              alt="Floor Plan"
              onError={() => {
                setFloorPlanImageError(true)
              }}
            />
          )}
          {unitLink && (
            <Action
              data-testid="unitLink"
              className={styles.unitLink}
              href={unitLink}
            >
              Click here for photos, videos, and more!
            </Action>
          )}
        </div>
      </div>

      <div className={combineClasses(styles.column, detailColumnClassName)}>
        {floorPlanName && (
          <ItemName
            data-testid="floorPlanName"
            className={styles.floorPlanName}
          >
            {floorPlanName}
          </ItemName>
        )}
        {(beds || baths || squareFootage) && (
          <UnitQuickFacts
            className={styles.UnitQuickFacts}
            bedroomCount={beds}
            bathroomCount={baths}
            squareFootageCount={squareFootage}
          />
        )}
        {features?.length > 0 && <ExpandingUnorderedList items={features} />}
      </div>
    </div>
  )
}

UnitSummary.propTypes = {
  /**
   * Holds the imageUrl for the floor plan.
   */
  floorPlanImageURL: PropTypes.string.isRequired,
  /**
   * Name of the floor Plan.
   */
  floorPlanName: PropTypes.string.isRequired,
  /**
   * Number Of bedrooms.
   */
  beds: PropTypes.number.isRequired,
  /**
   * Number of bathrooms.
   */
  baths: PropTypes.number.isRequired,
  /**
   * Number of squarefeet for the apartment.
   */
  squareFootage: PropTypes.number.isRequired,
  /**
   * Array of features for the apartment.
   */
  features: PropTypes.array.isRequired,
  /**
   * Link for the unit details.
   */
  unitLink: PropTypes.string.isRequired,
  /**
   * Label for the unit.
   */
  unitNumber: PropTypes.string,
}
