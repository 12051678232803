import React from 'react'
import PropTypes, { string } from 'prop-types'

import { combineClasses } from '~/util'

import { Action } from '~/components'

import styles from './ExpandingUnorderedList.module.scss'

export function ExpandingUnorderedList({
  className,
  items,
  initialCount = 3,
  ...rest
}) {
  const [isWholeListVisable, setIsWholeListVisable] = React.useState(false)

  let renderList = () => {
    const featureElements = items?.reduce((accumulated, current, index) => {
      let element = (
        <li key={index} data-testid="listLi" className={styles.featureListLi}>
          {current}
        </li>
      )
      if (index < initialCount && !isWholeListVisable) {
        accumulated.push(element)
      }
      if (isWholeListVisable) {
        accumulated.push(element)
      }
      return accumulated
    }, [])

    return (
      <>
        <ul className={styles.featureList}>{featureElements}</ul>
        {items.length > initialCount && (
          <Action
            data-testid="listButton"
            className={styles.showFeaturesButton}
            button
            feel="link"
            onClick={() => setIsWholeListVisable(!isWholeListVisable)}
          >
            {!isWholeListVisable
              ? `Show ${items.length - initialCount} More`
              : 'Show Less'}
          </Action>
        )}
      </>
    )
  }

  return (
    <div
      data-testid="ExpandingUnorderedList"
      className={combineClasses(styles.ExpandingUnorderedList, className)}
      {...rest}
    >
      {renderList()}
    </div>
  )
}

ExpandingUnorderedList.propTypes = {
  items: PropTypes.arrayOf(string),
}
