import React from 'react'
import ReactGA from 'react-ga'
import queryString from 'query-string'

import { env } from '~/service'

// Top level event catagories.
export const ANALYTICS_CATEGORIES = {
  MAP: 'Map',
  // Events for contacting the community or leasing agent.
  CONTACT: 'Contact',
  // Events for interacting with videos or pictures.
  MEDIA: 'Media',
  // Events for interacting with checkIn page.
  CHECKIN: 'CheckIn',
}

/**
 * A service for tracking page and user events to our analytics backend.
 */
export class Analytics {
  constructor(options = {}) {
    this.options = options
  }

  /**
   * Initialize the underlying tracker, setting
   * the tracking id and session level analytics dimensions.
   * @param {string | number} guestCardId
   * @param {string} guestCardUuid
   * @param {string | number} communityId
   * @param {string} communityName
   * @param {string | number} [trackerId] - The id of the analytics account
   *   to track events to. This gets pulled from the environment
   *   variables if not specified.
   * @param {object} [trackingOptions] - Options to pass to the underlying
   *   tracker object. See https://github.com/react-ga/react-ga#reactgainitializegatrackingid-options
   */
  initialize(
    guestCardId,
    guestCardUuid,
    communityId,
    communityName,
    trackerId = env.gaTrackingId,
    trackingOptions
  ) {
    if (!this.initialized) {
      this.trackingId = trackerId
      if (trackingOptions) this.options = { ...this.options, trackingOptions }

      // Ensure we never send analtyics events during tests.
      if (env.test) this.options.testMode = true
      // Enable debug logging in verbose mode.
      if (this.options.verbose == null && env.verbose) this.options.debug = true

      // Initialize the tracking id.
      ReactGA.initialize(trackerId, this.options)

      // Set session variables.
      ReactGA.set({
        dimension1: guestCardUuid,
        dimension2: String(guestCardId),
        // Track the community as id and name so it's easy to filter and search
        // by either in analytics reports.
        dimension3: `${communityId} - ${communityName}`,
      })

      // Do this last in case something blows up.
      this.initialized = true
    }
  }

  /**
   * Get the search parameters from the URL, filtering
   * any parameters that are not relevant to analytics tracking.
   * @param {object} [location] - The location object from React Router or Window.
   */
  getQuery(location = window.location) {
    const query = queryString.parse(location.search)

    // Strip query parameters that aren't relavent to analytics.
    ;['from'].forEach((n) => {
      delete query[n]
    })

    const out = queryString.stringify(query)
    return out ? `?${out}` : ''
  }

  /**
   * Determine the URL of the current page.
   * @param {object} [location] - The location object from React Router or Window.
   */
  getPage(location = window.location) {
    const query = this.getQuery(location)
    const page = location.pathname

    // Exclude the UUID so we can track page views
    // regardless of the guest card they are associated
    // with. The UUID is tracked as a custom dimension instead.
    const path = page
      .split('/')
      .filter((p) => !!p)
      .slice(1)
      .join('/')

    return '/' + path + query
  }

  /**
   * Track the current page.
   * @param {object} [location] - The location object from React Router or Window.
   */
  trackPage(location) {
    if (this.initialized) {
      const url = this.getPage(location)

      // Indicate what page subsequent events are associated with.
      // https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications?hl=en
      ReactGA.set({ page: url })
      // Track the current page load (required in addition to the previous line).
      ReactGA.pageview(url)
    }
  }

  /**
   * Track a non-pageview event/interaction.
   * @see https://github.com/react-ga/react-ga#reactgaeventargs
   * @param {string} category
   * @param {string} action
   * @param {string} [label]
   * @param {number} [value]
   * @param {boolean} [userInteraction] - Whether this event was triggered by the user.
   */
  trackEvent(category, action, label, value, userInteraction = true) {
    // Requiring category, action AND label so we can ensure consistent tracking data.
    if (this.initialized && category && action && label) {
      ReactGA.event({
        category,
        action,
        label,
        value,
        nonInteraction: !userInteraction,
      })
    }
  }

  /**
   * Track a link to an external site.
   * https://github.com/react-ga/react-ga#reactgaoutboundlinkargs-hitcallback
   * @param {string} url
   */
  trackExternalLink(url) {
    if (this.initialized) {
      ReactGA.outboundLink({ label: url }, () => {})
    }
  }
}

/**
 * @type {import('react').Context<Analytics>}
 */
export const AnalyticsContext = React.createContext(undefined)
/**
 * @type {AnalyticsContext.Provider}
 */
export const AnalyticsProvider = AnalyticsContext.Provider

/**
 * @returns {Analytics}
 */
export function useAnalyticsClient() {
  return React.useContext(AnalyticsContext)
}
