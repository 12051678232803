import React from 'react'
import { Accordion } from './Accordion'
import styles from './ResponsiveAccordion.module.scss'

/**
 * Render either an Accordion or a horizontal row of boxes based on the browser
 * width.
 */
export const ResponsiveAccordion = ({
  /**
   * The browser size at which to switch. Use a tshirt size (s, m, l, xl, xxl)
   * to indicate a breakpoint defined in the theme or a number. When the browser
   * is wider than that value, the row will be shown.
   */
  dimension = 'l',
  children,
  ...rest
}) => {
  const d = styles[`${dimension}Browser`] ?? styles.lBrowser

  const mediaQuery = React.useMemo(() => {
    return window.matchMedia(`(min-width: ${d})`)
  }, [d])

  const [collapsible, setCollapsible] = React.useState(!mediaQuery.matches)

  React.useEffect(() => {
    const onMediaQueryChange = (e) => setCollapsible(!e.matches)

    mediaQuery.addEventListener('change', onMediaQueryChange)
    return () => mediaQuery.removeEventListener('change', onMediaQueryChange)
  })

  if (collapsible) {
    return (
      <Accordion
        data-testid="ResponsiveAccordion"
        data-collapsible="true"
        {...rest}
      >
        {children}
      </Accordion>
    )
  } else {
    return (
      <div
        data-testid="ResponsiveAccordion"
        data-collapsible="false"
        className={styles.uncollapsed}
      >
        {React.Children.map(
          children,
          ({ props: { header, children: itemChildren } }, i) => (
            <div className={styles.item} key={i}>
              <div className={styles.header}>{header}</div>
              <div className={styles.content}>{itemChildren}</div>
            </div>
          )
        )}
      </div>
    )
  }
}
