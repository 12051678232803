import React from 'react'
import { useLocation } from 'react-router'

import { type ID } from '../self-tour.service.types'
import { useAnalyticsClient } from './analytics'

export function usePageTracking(
  guestCardId: ID,
  guestCardUuid: string,
  communityId: ID,
  communityName: string,
  trackingId?: ID,
  trackingOptions?: Record<string, any>
) {
  const location = useLocation()
  const service = useAnalyticsClient()

  // SESSION TRACKING
  React.useEffect(() => {
    service.initialize(
      guestCardId,
      guestCardUuid,
      communityId,
      communityName,
      trackingId,
      trackingOptions
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // PAGE TRACKING
  React.useEffect(() => {
    service.trackPage(location)
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps
}
