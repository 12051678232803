import React from 'react'
import PropTypes from 'prop-types'

import { Modal } from './Modal'
import { combineClasses } from '~/util'

import styles from './IFrameModal.module.scss'

/**
 * @param {object} props
 * @param {string} [props.url]
 * @param {string} [props.label]
 * @param {boolean} [props.isOpen]
 * @param {function} [props.setIsOpen]
 * @param {object} [props.modalProps]
 * @param {object} [props.iframeProps]
 * @param {string} [props.className]
 */
export function IFrameModal({
  isOpen,
  setIsOpen,
  url,
  label,
  iframeProps,
  className,
  ...rest
}) {
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      label={`${label} Window`}
      {...rest}
    >
      <iframe
        data-testid="iframe"
        title={label}
        src={url}
        className={combineClasses(styles.iframe, className)}
        {...iframeProps}
      />
    </Modal>
  )
}

IFrameModal.propTypes = {
  /**
   * The current open state.
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * A callback to set the open state.
   */
  setIsOpen: PropTypes.func.isRequired,
  /**
   * The URL to open in the iframe.
   */
  url: PropTypes.string.isRequired,
  /**
   * The title of the iframe element and the aria name
   * of the model. This should describe the content being opened.
   */
  label: PropTypes.string.isRequired,
  /**
   * additional props for modal.
   */
  modalProps: PropTypes.object,
  /**
   * additional props for iframe.
   */
  iframeProps: PropTypes.object,
  /**
   * Classes to apply to the iframe element.
   */
  className: PropTypes.string,
}
