import React from 'react'
import PropTypes from 'prop-types'

import { combineClasses } from '~/util'

import { Title } from '../titles'
import { Image } from '../image'

import styles from './ImageHeader.module.scss'

/**
 * This component renders a page header that can hold
 * a hero image, a title and/or a subtitle.
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} [props.headerTitleClassName]
 * @param {string} [props.imageURL]
 * @param {string} [props.imageAlt]
 * @param {string} [props.title]
 * @param {*} [props.children] - Any additional
 *   content to display under the title.
 * @param {boolean} [props.transparent]
 */
export function ImageHeader({
  className,
  headerTitleClassName,
  imageURL,
  imageAlt,
  title,
  children,
  transparent,
  ...rest
}) {
  const hasBackground = imageURL || !transparent

  return (
    <div
      data-testid="ImageHeader"
      className={combineClasses(
        styles.ImageHeader,
        className,
        transparent ? styles.transparent : null,
        !imageURL ? styles.noImage : styles.withImage,
        children ? styles.withSubText : null
      )}
      {...rest}
    >
      {hasBackground && (
        <div className={styles.background}>
          {imageURL && (
            <Image
              data-testid="headerImage"
              className={styles.image}
              src={imageURL}
              alt={imageAlt}
              loader={false}
              width="100%"
              height="100%"
              responsive
            />
          )}
        </div>
      )}
      {(title || children) && (
        <div
          className={combineClasses(
            hasBackground ? styles.withBackground : styles.noBackground,
            styles.textContainer
          )}
        >
          {title && (
            <Title
              data-testid="headerTitle"
              className={combineClasses(styles.title, headerTitleClassName)}
            >
              {title}
            </Title>
          )}
          {children && (
            <div data-testid="subText" className={styles.subText}>
              {children}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

ImageHeader.propTypes = {
  /**
   * Sets the class for the title property.
   */
  headerTitleClassName: PropTypes.string,
  /**
   * An image to show behind the title.
   */
  imageURL: PropTypes.string,
  /**
   * The alt text applied to the background image.
   */
  imageAlt: PropTypes.string,
  /**
   * Title text for header.
   */
  title: PropTypes.string,
  /**
   * Removes the background coloring.
   */
  transparent: PropTypes.bool,
  /**
   * Any children will be placed under the title.
   */
  children: PropTypes.node,
  /**
   * Any additional props will be passed to the wrapping div element.
   */
  'other props...': PropTypes.any,
}
