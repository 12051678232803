import { Location } from 'history'
import queryString from 'query-string'
import { matchPath, useLocation, useRouteMatch } from 'react-router'
import { URL } from '~/service'

type URLPattern = string

// We define a loose version of `RouteConfig` because we cannot enforce that the
// `back` property is a `RouteIds` due to it's cyclic nature. However, we can
// export a `RouteConfig` with the correctly typed `back` property later in the
// file.
interface RouteConfigLoose {
  /**
   * A public id that can be used to lookup routing information. This id should
   * be used as the `from` query parameter in order to distinguish a user's
   * previous page.
   *
   * @deprecated The id field will be removed from routes in the future.
   */
  id: string
  /**
   * The default page title that will be displayed to the user if a more
   * specific page title is not supplied.
   */
  title: string
  /**
   * The path portion of the page URL with parameter tags.
   * See https://reactrouter.com/en/main/start/concepts#match-params
   */
  path: URLPattern
  /**
   * The Route id to use to go back in history if a more specific backwards URL
   * is not provided. This is used in the case that the user deep links into the
   * app and the back button or page breadcrumb cannot use History back to go to
   * the previous page.
   */
  back: string
}

/**
 * Create a readonly Map object.
 */
function ReadonlyMapWithStringKeys<K extends string>(
  iterable: Iterable<[K, RouteConfigLoose]>
): ReadonlyMap<K, RouteConfigLoose> {
  return new Map(iterable)
}

/*
 * A Map of route names to `RouteConfig` objects.
 */
export const Routes = ReadonlyMapWithStringKeys([
  // TOUR TO GO
  ['ROOT', { id: 'home', title: 'My Tour', path: '/', back: 'HOME' }],
  ['HOME', { id: 'home', title: 'My Tour', path: '/:uuid', back: 'HOME' }],
  [
    'MY_TOUR',
    { id: 'my-tour', title: 'My Tour', path: '/:uuid/tour', back: 'HOME' },
  ],
  // SELF TOUR
  [
    'SELF_TOUR',
    {
      id: 'self-tour',
      title: 'Self Tour',
      path: '/:uuid/self-tour',
      back: 'HOME',
    },
  ],
  [
    'SELF_TOUR_DIRECTIONS',
    {
      id: 'self-tour-directions',
      title: 'Self Tour Directions',
      path: '/:uuid/self-tour/directions',
      back: 'HOME',
    },
  ],
  [
    'SELF_TOUR_UNIT_DETAIL',
    {
      id: 'self-tour-unit',
      title: 'Unit',
      path: '/:uuid/self-tour/units/:unitId',
      back: 'SELF_TOUR',
    },
  ],
  [
    'SELF_TOUR_AMENITY_DETAIL',
    {
      id: 'self-tour-amenity',
      title: 'Amenity',
      path: '/:uuid/self-tour/amenities/:amenityId',
      back: 'SELF_TOUR',
    },
  ],
  // VERIFY IDENTITY
  [
    'VERIFY_IDENTITY',
    {
      id: 'verify-identity',
      title: 'Verify Identity',
      path: '/:uuid/verify-identity',
      back: 'HOME',
    },
  ],
  // UNITS
  [
    'INTERESTED_UNITS_LEGACY',
    {
      id: 'interested-apts',
      title: 'Favorites',
      path: '/:uuid/virtual',
      back: 'HOME',
    },
  ],
  [
    'INTERESTED_UNITS',
    {
      id: 'interested-apts',
      title: 'Favorites',
      path: '/:uuid/apartments',
      back: 'HOME',
    },
  ],
  [
    'UNIT_DETAIL',
    {
      id: 'apartment',
      title: 'Apartment',
      path: '/:uuid/apartments/:unitId',
      back: 'MY_TOUR',
    },
  ],
  [
    'UNIT_DETAIL_LEGACY',
    {
      id: 'apartment',
      title: 'Apartment',
      path: '/:uuid/apartment/:unitId',
      back: 'MY_TOUR',
    },
  ],
  // QUOTES
  [
    'QUOTES',
    {
      id: 'my-quotes',
      title: 'My Quotes',
      path: '/:uuid/quotes',
      back: 'HOME',
    },
  ],
  [
    'QUOTE_DETAIL',
    {
      id: 'quote',
      title: 'Quote',
      path: '/:uuid/quotes/:quoteId',
      back: 'QUOTES',
    },
  ],
  [
    'QUOTE_DETAIL_LEGACY',
    {
      id: 'quote',
      title: 'Quote',
      path: '/:uuid/quote/:quoteId',
      back: 'QUOTES',
    },
  ],
  // AMENITIES
  // TODO Can I rename this?
  [
    'AMENITIES',
    {
      id: 'community-amenities',
      title: 'Amenities',
      path: '/:uuid/community-amenities',
      back: 'HOME',
    },
  ],
  [
    'AMENITY_DETAIL',
    {
      id: 'amenity',
      title: 'Amenity',
      path: '/:uuid/amenities/:amenityId',
      back: 'MY_TOUR',
    },
  ],
  [
    'AMENITY_DETAIL_LEGACY',
    {
      id: 'amenity',
      title: 'Amenity',
      path: '/:uuid/amenity/:amenityId',
      back: 'MY_TOUR',
    },
  ],
  // Check In Pages
  [
    'CHECK_IN',
    {
      id: 'check-in',
      title: 'Check In',
      path: '/:uuid/check-in',
      back: 'HOME',
    },
  ],
  [
    'OCCUPANTS',
    {
      id: 'occupants',
      title: 'Occupants',
      path: '/:uuid/occupants',
      back: 'CHECK_IN',
    },
  ],
  [
    'RENT',
    { id: 'rent', title: 'Rent', path: '/:uuid/rent', back: 'OCCUPANTS' },
  ],
  [
    'BEDROOMS',
    {
      id: 'bedrooms',
      title: 'Bedrooms',
      path: '/:uuid/bedrooms',
      back: 'RENT',
    },
  ],
  //Floor Plan Search Page
  [
    'SEARCHFLOORPLAN',
    {
      id: 'search-floor-plan',
      title: 'Search Floor Plan',
      path: '/:uuid/floor-plans',
      back: 'INTERESTED_UNITS',
    },
  ],
  [
    'FLOORPLAN',
    {
      id: 'floor-plan',
      title: 'Floor Plan',
      path: '/:uuid/floor-plans/:floorPlanId',
      back: 'SEARCHFLOORPLAN',
    },
  ],
])

/**
 * The identifiers for all routes in the app.
 */
export type RouteIds = Parameters<(typeof Routes)['get']>[0]
/**
 * The configuration for a specific application route.
 */
export type RouteConfig = Omit<RouteConfigLoose, 'back'> & {
  back: RouteIds
}

type URLParameterType = string | number | boolean | undefined | null

/**
 * Get the URL for a specific route with path parameters
 * replaced.
 */
export function getRoute(
  /**
   * The name of the route (key) in the Routes map.
   */
  name: RouteIds,
  /**
   * An object with the url parameters to replace.
   * Each key in the parameter should match a variable token in the route
   * (without the leading ':'). If parameters is not passed, the raw
   * path is returned.
   *
   * Ex: {projectId: 123} will replace the project id token in the route
   * `/project/:projectId/new` with the value 123.
   */
  urlParams?: Record<string, URLParameterType>,
  /**
   * An object of key/value pairs to use as query parameters on the URL.
   */
  queryParams?: Record<string, URLParameterType | URLParameterType[]>
) {
  const route = Routes.get(name)
  if (!route) {
    console.error('Could not find route called', name)
    return '#'
  }

  let r = route.path
  if (urlParams) {
    Object.keys(urlParams).forEach(
      (key) => (r = r.replace(`:${key}`, String(urlParams[key])))
    )
  }

  if (queryParams) {
    const q = queryString.stringify(queryParams)
    if (q) r += `?${q}`
  }

  return r
}

/**
 * Find the key for a route by its public id. You can then use the key with
 * `getRoute` or other functions that take a route key.
 */
export function findRouteById(
  /**
   * The `id` property of a `RouteConfig`.
   */
  id: string
) {
  if (!id) return null
  for (let [key, value] of Routes.entries()) {
    if (value.id === id) return key
  }
}

/**
 * Get an element from the `Routes` map based on the current browser location.
 */
export function findRouteMatchingLocation(
  /**
   * A URL for which you want to find the associated `RouteConfig`.
   */
  location: URL
) {
  // Convert the Routes map into objects that contain both the key and value.
  let values: { value: RouteConfig; key: RouteIds }[] = []
  Routes.forEach((value, key) =>
    values.push({ value: value as RouteConfig, key })
  )
  values = values.reverse()

  // Search the routes in reverse looking for the first route to match the given location.
  for (let route of values) {
    const match = matchPath(location, {
      path: route.value.path,
      exact: true,
      strict: false,
    })
    if (match) {
      return {
        key: route.key,
        route: route.value,
        match,
      }
    }
  }
  return undefined //null
}

/**
 * Finds the previous route key based on the query params. If the query params
 * contain a `from` parameter, it is used as the previous URL. Otherwise the
 * fallback parameter is used to look up the previous URL.
 */
export function getPreviousRoute(
  /**
   * The route key used as a fallback if the `queryParams` does not include a
   * `from` prop to use as the previous URL.
   */
  fallback: RouteIds,
  /**
   * URL path parameters to replace in the returned URL
   */
  pathParams?: Record<string, URLParameterType>,
  /**
   * URL query parameters to add to the returned URL. If this contains a `from`
   * parameter, it will be used as the previous URL.
   */
  queryParams?: Record<string, URLParameterType | URLParameterType[]>
) {
  const from = queryParams?.from
  if (!from) {
    return {
      key: fallback,
      url: getRoute(fallback, pathParams),
    }
  }
  // Support the legacy 'from=virtual' for now...
  if (queryParams?.from === 'virtual') {
    return {
      key: 'INTERESTED_UNITS' as RouteIds,
      url: getRoute('INTERESTED_UNITS', pathParams),
    }
  }
  let { key } = findRouteMatchingLocation(from as string) || { key: fallback }
  return {
    key,
    url: queryParams?.from,
  }
}

/**
 * Check to see if a URL matches one of the URL provided.
 */
export function doesURLMatch(
  /**
   * The URL you want to check.
   */
  url: URL,
  /**
   * The URLs to comapare against.
   */
  routes: URLPattern | URLPattern[]
) {
  if (!Array.isArray(routes)) routes = [routes]
  const filtered = routes.filter((t) => {
    return matchPath(url, {
      path: t,
      exact: true,
      strict: false,
    })
  })
  return filtered.length > 0
}

/**
 * Given a route or query parameter name, normalize the value. For example, if
 * the param should be treated as a number, this will convert the value into a
 * number.
 */
export function normalizeParam(
  /**
   * The name of the query parameter.
   */
  name: string,
  /**
   * The value to normalize.
   */
  value: string | string[] | null | undefined
) {
  // TODO Handle arrays
  if (/(Id$|Count$)/.test(name)) return Number(value)
  if (value === 'false') return false
  if (value === 'true') return true
  if (value === '' || value === 'null') return null
  if (value === 'undefined') return undefined

  return value
}

/**
 * Normalize all keys in a parameter object.
 */
export function normalizeAllParams(
  /**
   * The parameters to normalize
   */
  params: Record<string, string | string[] | null | undefined>
) {
  // TODO Verify this change works
  if (!params) return undefined // return null
  const out: Record<string, ReturnType<typeof normalizeParam>> = {}
  Object.keys(params).forEach((key) => {
    out[key] = normalizeParam(key, params[key])
  })
  return out
}

/**
 * Get a parameter from a parameter object, doing any necessary casting.
 * For convenience, you can pass the react-router `match` object or
 * the `match.params` property.
 */
export function getRouteParam(
  /**
   * The name of the property to retrieve and normalize.
   */
  name: string,
  /**
   * The `react-router` match object or an object that contains the expected key.
   */
  match: Record<string, string> | { params: Record<string, string> }
) {
  // Allow passing either the `match` object or the `match.params` object.
  const params = (match?.params as Record<string, string>) ?? match

  return normalizeParam(name, params[name])
}

/**
 * Get all parameters from the URL match as noramlized values. For convenience,
 * you can pass the react-router `match` object or the `match.params` property.
 */
export function getAllRouteParams(
  /**
   * The `react-router` match object or an object that contains the expected key.
   */
  match: Record<string, string> | { params: Record<string, string> }
) {
  const params = (match?.params as Record<string, string>) ?? match
  return normalizeAllParams(params) as Record<string, URLParameterType>
}

/**
 * Get a parameter from the URL query string.
 */
export function getQueryParam(
  /**
   * The name of the paramerter to retrieve.
   */
  name: string,
  /**
   * The search string from window.location or the location object from react-router.
   */
  search: string | Location
) {
  const s =
    typeof search === 'object' && search.search
      ? search.search
      : (search as string)
  const params = queryString.parse(s)

  const value = params[name]
  if (Array.isArray(value)) {
    return normalizeParam(name, value[0])
  } else {
    return normalizeParam(name, value)
  }
}

/**
 * Get an object with all of the query params in the search
 * string with all params normalized.
 */
export function getAllQueryParams(
  /**
   * URL search params string or a Location object.
   */
  search: string | Location
) {
  const s =
    typeof search === 'object' && search.search
      ? search.search
      : (search as string)
  if (!s) return undefined //null
  const params = queryString.parse(s)
  return normalizeAllParams(params)
}

/**
 * Get all query parameters from the URL. The values of the returned object will
 * have been cased into the appropriate types.
 */
export function useURLParams(id: RouteIds) {
  const location = useLocation()
  const match = useRouteMatch()

  const params = getAllRouteParams(match)
  const query = getAllQueryParams(location)

  const route = Routes.get(id) as RouteConfig

  const { key: previousRouteId, url: previousUrl } = getPreviousRoute(
    route.back as RouteIds,
    params,
    query
  )
  const previousRoute = Routes.get(previousRouteId) as RouteConfig
  return {
    /**
     * The `RouteConfig` associated witht he given route id.
     */
    route,
    /**
     * The URL to the previous page.
     */
    previousUrl,
    /**
     * The title of the previous page.
     */
    previousTitle: previousRoute.title,
    ...params,
    ...query,
  } as Record<string, URLParameterType | URLParameterType[]> & {
    route: RouteConfig
    previousUrl: string
    previousTitle: string
  }
}
