import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import selfTourReducer from './self-tour.reducer'
import * as fromSelfTour from './self-tour.selectors'
import pureChatReducer, * as fromPureChat from './pure-chat.reducer'

/**
 * @typedef {import('~/service').Feature} Feature
 */

export const reducerConfig = {
  tour: selfTourReducer,
  chat: pureChatReducer,
}

const rootReducer = combineReducers(reducerConfig)

export function reset() {
  return {
    type: 'RESET',
    payload: null,
  }
}

export default configureStore({
  reducer: (state, action) => {
    if (action.type === 'RESET') {
      // Reset to the initial store state.
      return rootReducer(undefined, action)
    }

    return rootReducer(state, action)
  },
})

export const selectTourInitialized = (state) =>
  fromSelfTour.select.initialized(state.tour)

export const selectSelfTourNotices = (state) =>
  fromSelfTour.select.notices(state.tour)

export const selectGuestCardUuid = (state) =>
  fromSelfTour.select.uuid(state.tour)

export const selectUserHasSignedSelfTourAgreement = (state) =>
  fromSelfTour.select.userHasSignedSelfTourAgreement(state.tour)

export const selectShouldUserSignSelfTourAgreement = (state) =>
  fromSelfTour.select.shouldUserSignSelfTourAgreement(state.tour)

export const selectGuestCard = (state) =>
  fromSelfTour.select.guestCard(state.tour)

export const selectGuestCardId = (state) =>
  fromSelfTour.select.guestCardId(state.tour)

/**
 * Determine if the user has completed the check in
 * questions/steps.
 * @returns {boolean}
 */
export const selectCheckedIn = (state) =>
  fromSelfTour.select.checkedIn(state.tour)

export const selectOccupants = (state) =>
  fromSelfTour.select.occupants(state.tour)

export const selectMaxPrice = (state) =>
  fromSelfTour.select.maxPrice(state.tour)

export const selectBedBathIds = (state) =>
  fromSelfTour.select.bedBathIds(state.tour)

/**
 * Select the user's preferred move in dates.
 * @returns {{earliestMoveIn: string, latestMoveIn: string}}
 */
export const selectMoveInDates = (state) =>
  fromSelfTour.select.moveInDates(state.tour)

export const selectAppointments = (state) =>
  fromSelfTour.select.appointments(state.tour)

export const selectCommunity = (state) =>
  fromSelfTour.select.community(state.tour)

export const selectCommunityId = (state) =>
  fromSelfTour.select.communityId(state.tour)

export const selectCommunityName = (state) =>
  fromSelfTour.select.communityName(state.tour)

export const selectPetPolicy = (state) =>
  fromSelfTour.select.petPolicy(state.tour)

export const selectCommunityLogoUrl = (state) =>
  fromSelfTour.select.communityLogoUrl(state.tour)

export const selectCommunityVoicePhoneNumber = (state) =>
  fromSelfTour.select.communityVoicePhoneNumber(state.tour)

export const selectCommunityEmail = (state) =>
  fromSelfTour.select.communityEmail(state.tour)

export const selectCommunityWebsite = (state) =>
  fromSelfTour.select.communityWebsite(state.tour)

export const selectLicenseNumber = (state) =>
  fromSelfTour.select.licenseNumber(state.tour)

export const selectCommunityContactName = (state) =>
  fromSelfTour.select.communitySmsContactName(state.tour)

export const selectCommunitySmsPhoneNumber = (state) =>
  fromSelfTour.select.communitySmsPhoneNumber(state.tour)

export const selectCommunityTimeZoneOffset = (state) =>
  fromSelfTour.select.communityTimeZoneOffset(state.tour)

export const selectCommunityPrimaryColor = (state) =>
  fromSelfTour.select.communityPrimaryColor(state.tour)

export const selectCommunitySecondaryColor = (state) =>
  fromSelfTour.select.communitySecondaryColor(state.tour)

/**
 * Config values for this community.
 * @param {object} state
 */
export const selectCommunityConfig = (state) =>
  fromSelfTour.select.communityConfig(state.tour)

/**
 * beds and baths available within the community.
 * @param {*} state
 */
export const selectCommunityBedBaths = (state) =>
  fromSelfTour.select.communityBedBaths(state.tour)

/**
 * Whether this community supports a non-sms chat tool.
 * @param {object} state
 */
export const selectCommunityHasChat = (state) =>
  fromSelfTour.select.communityHasChat(state.tour)

/**
 * Whether this community uses PureChat as it's chat tool.
 * @param {object} state
 */
export const selectCommunityUsesPureChat = (state) =>
  fromSelfTour.select.usePureChat(state.tour)

/**
 * The community's pure chat js client id.
 * @param {object} state
 */
export const selectCommunityPureChatId = (state) =>
  fromSelfTour.select.pureChatId(state.tour)

/**
 * The URL for the community's chat tool if it can be opened
 * in an iframe.
 * @param {object} state
 */
export const selectCommunityChatURL = (state) =>
  fromSelfTour.select.chatURL(state.tour)

export const selectUseEngrainMaps = (state) =>
  fromSelfTour.select.useEngrainMaps(state.tour)

export const selectEngrainMapId = (state) =>
  fromSelfTour.select.engrainMapId(state.tour)

/**
 * Select a specific amenity by its id.
 */
export const selectCommunityAmenity = (state, amenityId) =>
  fromSelfTour.select.communityAmenity(state.tour, amenityId)

export const selectCommunityAddress = (state) =>
  fromSelfTour.select.communityAddress(state.tour)

/**
 * All amenities to show on the amenities page.
 * This can include things that don't show up on
 * the map such as "Free Wifi". It will exclude
 * any amenities with a specialTypeId property
 * which should be retrieved with their specific selectors.
 */
export const selectCommunityAmenities = (state) =>
  fromSelfTour.select.communityAmenities(state.tour)

/**
 *  Gets amenities filted by guestcard amenity ids.
 */
export const selectCommunityAmenitiesFromGuestCardIds = (state) =>
  fromSelfTour.select.communityAmenitiesFromGuestCardIds(state.tour)

/**
 * Select the leasing offices for this community.
 * They will have the same shape as an amenity.
 * Most communities will have a list with one
 * leasing office but some communities will have
 * multiple offices.
 */
export const selectCommunityLeasingOffices = (state) =>
  fromSelfTour.select.communityLeasingOffices(state.tour)

export const selectCommunityQuoteCategories = (state) =>
  fromSelfTour.select.quoteCategories(state.tour)

export const selectCommunityFeatures = (state) =>
  fromSelfTour.select.communityFeatures(state.tour)

/**
 * @returns {string[]}
 */
export const selectAllFeaturesForUnitsByName = (state) =>
  fromSelfTour.select.allFeaturesForUnitsByName(state.tour)

/**
 * The amenities to show on the map. This can be a subset
 * of the amenities to show on the amenities page
 * (ex. "Free Wifi"). It will exclude any amenities
 * with a specialTypeId property which should be
 * retrieved with their specific selectors.
 */
export const selectAmenitiesInTour = (state) =>
  fromSelfTour.select.amenitiesInTour(state.tour)

export const selectUnit = (state, unitId) =>
  fromSelfTour.select.unit(state.tour, unitId)

export const selectUnits = (state) => fromSelfTour.select.units(state.tour)

export const selectTourableUnits = (state) =>
  fromSelfTour.select.tourableUnits(state.tour)

export const selectShownUnits = (state) =>
  fromSelfTour.select.shownUnits(state.tour)

export const selectInterestedUnits = (state) =>
  fromSelfTour.select.interestedUnits(state.tour)

export const selectIsShowPriceOnModel = (state) =>
  fromSelfTour.select.isShowPriceOnModel(state.tour)

export const selectShowUnitPrices = (state) =>
  fromSelfTour.select.showUnitPrices(state.tour)

export const selectSelfTourRequiresAgreement = (state) =>
  fromSelfTour.select.selfTourRequiresAgreement(state.tour)

export const selectSelfTourUserAgreement = (state) =>
  fromSelfTour.select.selfTourUserAgreement(state.tour)

export const selectSelfTourUserAgreementTitle = (state) =>
  fromSelfTour.select.selfTourUserAgreementTitle(state.tour)

export const selectSelfTourUserAgreementLink = (state) =>
  fromSelfTour.select.selfTourUserAgreementLink(state.tour)

export const selectIsBuildingEnabled = (state) =>
  fromSelfTour.select.isBuildingEnabled(state.tour)

export const selectIsVariableRentEnabled = (state) =>
  fromSelfTour.select.isVariableRentEnabled(state.tour)

export const selectFloorPlate = (state, floorPlateId) =>
  fromSelfTour.select.floorPlate(state.tour, floorPlateId)

export const selectTourFloorPlates = (state) =>
  fromSelfTour.select.floorPlates(state.tour)

export const selectFloorPlan = (state, id) =>
  fromSelfTour.select.floorPlan(state.tour, id)

export const selectFloorPlans = (state) =>
  fromSelfTour.select.floorPlans(state.tour)

export const selectFloorPlansWithFilter = (state, filterObj) =>
  fromSelfTour.select.floorPlans(state.tour, filterObj)

export const selectPureChatClientId = (state) =>
  fromPureChat.select.id(state.chat)

export const selectIsPureChatLoaded = (state) =>
  fromPureChat.select.loaded(state.chat)

export const selectQuotes = (state) => fromSelfTour.select.quotes(state.tour)

export const selectHasQuotes = (state) =>
  fromSelfTour.select.hasQuotes(state.tour)

export const selectQuote = (state, id) =>
  fromSelfTour.select.quote(state.tour, id)

export const selectQuotesForUnit = (state, id) =>
  fromSelfTour.select.quotesForUnit(state.tour, id)

export const selectQuoteMonthlyCharges = (state, unit) =>
  fromSelfTour.select.quoteMonthlyCharges(state.tour, unit)

export const selectQuoteRequiredCharges = (state, unit) =>
  fromSelfTour.select.quoteRequiredCharges(state.tour, unit)

export const selectQuoteAdditionalCharges = (state, unit) =>
  fromSelfTour.select.quoteAdditionalCharges(state.tour, unit)

export const selectQuoteFootNotes = (state) =>
  fromSelfTour.select.quoteFootNotes(state.tour)

export const selectQuoteHighlights = (state) =>
  fromSelfTour.select.quoteHighlights(state.tour)

export const selectPrimaryProspect = (state) =>
  fromSelfTour.select.primaryProspect(state.tour)

export const selectCommunityMediaGalleries = (state) =>
  fromSelfTour.select.communityMediaGalleries(state.tour)

export const selectAmenitiesMediaGallery = (state) =>
  fromSelfTour.select.amenitiesMediaGallery(state.tour)
