import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { combineClasses } from '@thesoulfresh/utils'

import { Action, CallOrTextAction } from '~/components'
import { ANALYTICS_CATEGORIES, ID, PhoneNumber, URL } from '~/service'
import { doesURLMatch } from '../routes'

import { Chat } from './Chat'

import styles from './Header.module.scss'

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  uuid: ID
  logo?: URL
  title?: string
  homeURL?: URL
  myTourURLs?: URL[]
  myApartmentsURL?: URL
  myQuotesURL?: URL
  amenitiesURL?: URL
  phoneNumber?: PhoneNumber
  showChat?: boolean
  pureChatId?: ID
  pureChatClient?: any
  pureChatDelay?: number
  usePureChat?: boolean
  chatURL?: URL
  isSelfTour?: boolean
}

export function Header({
  uuid,
  logo,
  title,
  homeURL,
  myTourURLs,
  myApartmentsURL,
  myQuotesURL,
  amenitiesURL,
  phoneNumber,
  showChat,
  chatURL,
  pureChatId,
  usePureChat,
  className,
  pureChatClient,
  pureChatDelay,
  isSelfTour,
  ...rest
}: HeaderProps) {
  return (
    <div data-testid="Header" className={styles.Header} {...rest}>
      <div
        className={combineClasses(
          styles.logoLine,
          isSelfTour && styles.selfTour
        )}
      >
        <Action
          transparent
          data-testid="homeLink"
          className={styles.logoLink}
          href={homeURL}
        >
          <div className={styles.logoContainer}>
            <img className={styles.logo} src={logo} alt={title} />
          </div>
        </Action>
        <div className={styles.contact}>
          {phoneNumber && (
            <CallOrTextAction
              data-testid="CallOrTextAction"
              className={styles.contactButton}
              number={phoneNumber}
              category={ANALYTICS_CATEGORIES.CONTACT}
              action="Call Or Text Community"
              label="Header Call Or Text Button"
            />
          )}
          {showChat && (
            <Chat
              data-testid="chatButton"
              className={styles.contactButton}
              chatURL={chatURL}
              pureChatId={pureChatId}
              usePureChat={usePureChat}
              pureChatClient={pureChatClient}
              pureChatDelay={pureChatDelay}
            />
          )}
        </div>
      </div>
      <div className={styles.tabs}>
        {!isSelfTour && myTourURLs && (
          <NavLink
            data-testid="myTourLink"
            to={myTourURLs[0]}
            isActive={(_match, location) =>
              doesURLMatch(location.pathname, myTourURLs)
            }
          >
            <span className={styles.myPrefix}>My</span> Tour
          </NavLink>
        )}
        {!isSelfTour && myApartmentsURL && (
          <NavLink data-testid="interestedAptsLink" to={myApartmentsURL}>
            Favorites
          </NavLink>
        )}
        {!isSelfTour && myQuotesURL && (
          <NavLink data-testid="myQuotesLink" to={myQuotesURL}>
            <span className={styles.myPrefix}>My</span> Quotes
          </NavLink>
        )}
        {!isSelfTour && amenitiesURL && (
          <NavLink data-testid="amenitiesLink" to={amenitiesURL}>
            Amenities
          </NavLink>
        )}
      </div>
    </div>
  )
}

Header.propTypes = {
  uuid: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  homeURL: PropTypes.string.isRequired,
  myTourURLs: PropTypes.arrayOf(PropTypes.string),
  myApartmentsURL: PropTypes.string,
  myQuotesURL: PropTypes.string,
  amenitiesURL: PropTypes.string,
  phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showChat: PropTypes.bool,
}
