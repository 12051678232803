import React from 'react'
import PropTypes from 'prop-types'

import { combineClasses } from '@thesoulfresh/utils'
import { Select as SelectBase } from '@thesoulfresh/react-tools'

import { Action } from '../../buttons'

import styles from './Select.module.scss'
import sharedStyles from '../shared.module.scss'

/**
 * @typedef {object} SelectProps
 * @property {*[]} options
 * @property {*} children
 * @property {*} [ref]
 * @property {string} [className]
 * @property {object} [layerOptions]
 * @property {function} [content]
 * @property {function} [optionToString]
 * @property {*} [value]
 * @property {function} [onChange]
 * @property {function} [onOpen]
 * @property {function} [onClose]
 * @property {boolean} [isOpen]
 * @property {object} [selectOptions]
 * @property {object} [layerOptions]
 */
/**
 * `<Select>` provides a custom select element allowing you to customize
 * both the trigger button styling and the menu styles.
 * `Select` can be used as either a controlled or uncontrolled
 * component depending on whether you pass the `value` prop.
 *
 * By default, items will be rendered as Action buttons but you can
 * customize this by specifying a `content` function. To simplify
 * using the `content` prop, you can return `<SelectOption>` components
 * from this package which will ensure the generic option styling is
 * applied. `SelectOption` is just an `Action` component and takes
 * all of the same props.
 *
 * The children you pass to `Select` will be used as the select
 * trigger element. You can pass either a JSX definition or a function.
 * When using the functional version, don't forget to apply the received props
 * to your trigger element.
 *
 * Lastly, the `options` prop can be a list of anything. If the list contains
 * something other than strings or numbers, you will also need to
 * pass the `optionToString` prop so the default content renderer
 * can render the items in the menu. If you supply your own `content`
 * prop, it is up to you if you want to pass `optionToString` or not.
 *
 * @type React.FC<SelectProps>
 */
export const Select = React.forwardRef(
  (
    {
      className,
      layerOptions,
      content = (item) => (
        <SelectOption button className={styles.itemButton}>
          {item}
        </SelectOption>
      ),
      'data-testid': testId,
      ...rest
    },
    ref
  ) => {
    return (
      <SelectBase
        wrapperProps={{ 'data-testid': testId }}
        className={combineClasses(
          sharedStyles.Popover,
          styles.Select,
          className
        )}
        layerOptions={{
          triggerOffset: sharedStyles.arrowSize,
          ...layerOptions,
        }}
        content={content}
        {...rest}
        ref={ref}
      />
    )
  }
)

Select.propTypes = {
  /**
   * The list of items to render as select options.
   * These can be of any type and will be pass back to
   * your content render function when rendering the select options.
   */
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  /**
   * This is a callback that will be used to convert
   * each item into a string for user in ARIA properties.
   * This function is only required if the items you
   * pass are not strings. It recieves the item and
   * should return the string representation of that item.
   *
   * @param {*} item
   */
  optionToString: PropTypes.func,
  /**
   * Passing a value prop allows you to control the component's
   * selected item state. If you pass a value, you should also
   * pass the `onChange` prop which should update the `value`
   * as needed. If you don't pass a `value`, the the component
   * will maintain its own state.
   */
  value: PropTypes.any,
  /**
   * This callback will be called whenever the selected
   * item changes.
   *
   * It is called with the following parameters:
   *
   * @param {*} item - The newly selected item.
   * @param {object} updateData - The full Downshift update.
   */
  onChange: PropTypes.func,
  /**
   * A function that is used to render each of the items
   * in your select. You can return any JSX from this function
   * and it will be rendered.
   *
   * The callback receives the following parameters:
   *
   * @param {*} item - An item from the `items` prop which is the
   *   item being rendered.
   * @param {boolean} selected - Whether the current item is the
   *   selected item.
   * @param {boolean} highlighted - Whether the current item is
   *   currently highlighted by the user.
   * @param {number} index - The index of this item in the `items`
   *   prop array.
   *
   * If you don't pass the content prop, the items will be rendered
   * as strings and you can use the `.menu-item` class to style them.
   */
  content: PropTypes.func,
  /**
   * The element to render as the select trigger. This can be
   * either a JSX node or a function. The function variant is useful
   * because it will receive the currently selected item which you
   * can use to set the text of your trigger element.
   * Your trigger element should be a button for accessiblility purposes.
   *
   * The function variant will be called with the following parameters:
   *
   * @param {object} props - Props to apply to the trigger element.
   * @param {*} item - The currently selected item.
   */
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  /**
   * A callback that will be called whenever the menu is opened.
   * This is required if you use this component in a controlled fashion.
   */
  onOpen: PropTypes.func,
  /**
   * A callback that will be called whenever the menu is closed.
   * This is required if you use this component in a controlled fashion.
   */
  onClose: PropTypes.func,
  /**
   * The current open state of the menu. You only need to use this
   * if you want to control the open state of the menu yourself.
   * If you do pass this prop, then you will also need to pass the
   * `onOpen` and `onClose` props which will be called whenever
   * Downshift determines that the menu should be opened or closed.
   */
  isOpen: PropTypes.bool,
  /**
   * Any additional properties that you'd like to pass to the
   * `useSelect` hook from `Downshift`.
   *
   * See https://github.com/downshift-js/downshift/tree/master/src/hooks/useSelect#basic-props
   */
  selectOptions: PropTypes.object,
  /**
   * Configure options for the tooltip layer.
   * Since this component uses `react-laag` under the
   * hood, these options are pass directly to the
   * `useLayer` hook.
   * See https://github.com/everweij/react-laag#uselayeroptions
   */
  layerOptions: PropTypes.object,
  /**
   * The className will be applied to the outer div
   * that wraps both the `content` and `arrow` divs.
   * This allows you to scope the `content` and `arrow`
   * selectors to your specific tooltip component.
   */
  className: PropTypes.string,
  /**
   * Any other props you pass will be applied to the
   * root `<ol>` wrapping your menu items.
   */
  // @ts-ignore
  'other props...': PropTypes.any,
}

/**
 * @typedef {object} SelectOptionProps
 * @property {*} [ref]
 */
/**
 * The `<SelectOption>` can be used to achieve consistent
 * styling across menus. It gives you consistent padding
 * and sizes itself to the width of the menu. You can pass
 * any content you need as its children so you are not limited
 * to rendering just text.
 *
 * However, you are not required to use `<SelectOption>` as the
 * option conents of a `<Select>`.
 *
 * @type React.FC<SelectOptionProps>
 */
export const SelectOption = React.forwardRef(({ className, ...rest }, ref) => {
  return (
    <Action
      transparent
      unrounded
      feel="button"
      className={combineClasses(styles.SelectOption, className)}
      {...rest}
      ref={ref}
    ></Action>
  )
})

SelectOption.propTypes = {
  /**
   * You can pass anything as the content of a trigger.
   */
  children: PropTypes.node.isRequired,
  /**
   * Any other props you pass will be passed along to the
   * underlying span element.
   */
  'other props...': PropTypes.any,
}
