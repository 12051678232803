import hexToHSL from 'hex-to-hsl'

/**
 * Generate a full color palette of 9 colors given the center color.
 * @param {string} paletteName
 * @param {string} centerColor
 * @param {number} [saturationParam]
 * @param {number} [lightnessParam]
 */
export function generatePaletteColors(
  /**
   * The name of the color palette. This will be used to create the variable
   * names associated with each color in the palette.
   */
  paletteName,
  /**
   * The color in the center of the palette (.ie the 500 value)
   */
  centerColor,
  /**
   * Optional saturation value to use for the center color. If not passed, then
   * the saturation of centerColor is used. If this value is greater than the
   * actual saturation, then the actual saturation is used. This allows us to
   * generate a greyscale color palette while ensuring that we don't
   * accidentally introduce red tones into a grayscale color.
   */
  saturationParam,
  /**
   * Optional lightness value to use for the center color. If not passed, then
   * the lightness of centerColor is used. If this value is less than the
   * actual lightness, then the actual saturation is used. This allows us to
   * generate a greyscale color palette while ensuring that we don't
   * accidentally make the grayscale palette darker than the primary.
   */
  lightnessParam
) {
  const [hue, saturationCalc, lightnessCalc] = hexToHSL(centerColor)
  // Allow passing a saturation so we can use it to generate a desaturated
  // grayscale palette from the primary or secondary colors. However, if our
  // saturation parameter is actually more saturated than the community color
  // (ex. the primary color is a grey), then use the calculated saturation
  // instead of our value. Otherwise, we would tint the primary color with a
  // random hue.
  const saturation =
    saturationParam < saturationCalc ? saturationParam : saturationCalc
  // Allow passing a lightness so we can use it to generate palette colors.
  // Similar to the saturation param, if the given lightness is actually darker
  // than the lightness of the community color, then use the calculated
  // lightness.
  const lightness =
    lightnessParam > lightnessCalc ? lightnessParam : lightnessCalc

  // The range above/below the center color that we can safely use to generate
  // colors. The brighter/darker the center color, the narrower the color range
  // of the palette.
  const range = Math.min(lightness, 100 - lightness)
  // We use a range of 5 but pick the 4 colors above/below the center color so
  // that the first and last colors are not full black/white
  const factor = range / 5
  const start = lightness + factor * 4
  const out = []
  // Generate each color in the palette ranging from 100-900
  for (let i = 1; i < 10; i++) {
    const colorName = i * 100
    const l = start - (i - 1) * factor
    // Ensure that we always use the exact center color value.
    const color =
      colorName === 500 && !saturationParam && !lightnessParam
        ? centerColor
        : `hsl(${hue}, ${saturation}%, ${l.toFixed(2)}%)`
    out.push({
      name: `--light-${paletteName}-${colorName}`,
      color,
    })
  }
  return out
}
