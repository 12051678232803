import React from 'react'
import { LinkProps, Link as RouterLink } from 'react-router-dom'
import { useEnterExit } from '@thesoulfresh/react-tools'
import { combineClasses } from '~/util'
import { useAnalyticsClient } from '~/service'
import { Loader } from '../loader'

import styles from './Action.module.scss'

function makeBackgroundImage(url: string | undefined) {
  return url ? `url(${url})` : undefined
}

/**
 * A loader icon that will animate its entrance.
 */
function GrowLoader() {
  // TODO Allow animating the loader exit. Will need to think of a clever
  // way to track whether `loading=false` or `loading=undefined` means
  // don't show a loader versus hide the loader.
  const { ref, state } = useEnterExit(true, 'flex')
  return (
    <Loader
      data-testid="GrowLoader"
      // @ts-ignore: can't figure out what it's complaining about here
      className={combineClasses(
        styles.Loader,
        state === 'entered' && styles.loaderVisible
      )}
      ref={ref}
    />
  )
}

/**
 * Merge the children and icon props into a single
 * renderable node.
 */
function setChildren(
  children: HTMLCollection,
  icon?: React.ReactElement,
  /**
   * Show a loader icon in place of the icon.
   */
  loading?: boolean
) {
  const childCount = React.Children.count(children)

  return (
    <>
      {loading && <GrowLoader />}
      {icon &&
        !loading &&
        React.cloneElement(icon, {
          className: combineClasses(
            // Add generic icon styling
            styles.icon,
            // Global icon selector for easily targeting the icon.
            'icon',
            // Include the className on props
            icon.props ? icon.props.className : null,
            // If there are additional children next to the icon,
            // add spacing between them.
            childCount > 0 ? styles.withText : null
          ),
        })}
      {children}
    </>
  )
}

/**
 * Convert a React Router `to` object and convert it
 * into a URL string.
 */
function makeLinkFromObject(to: LinkProps['to']) {
  switch (typeof to) {
    case 'function':
      return ''
    case 'string':
      return to
    default:
      let href = ''
      if (to.pathname) href += to.pathname
      if (to.hash) href += to.hash
      if (to.search) href += to.search
      return href
  }
}

interface ActionLinkProps extends Omit<HTMLAnchorElement, 'href'>, RouterLink {
  to?: LinkProps['to']
  href?: string
  blank?: boolean
  unrouted?: boolean
  icon?: React.ReactElement
  image?: string
  display?: 'primary' | 'secondary' | 'success' | 'warn' | 'error'
  feel?: 'button' | 'link'
  transparent?: boolean
  unrounded?: boolean
  size?: 's' | 'm' | 'l'
  category?: string
  action?: string
  label?: string
  value?: number
  loading?: boolean
  unstyled?: boolean
  onClick?: (e: React.MouseEvent) => void
}

/**
 * Renders either a react router link component
 * or a standard browser link.
 */
export const ActionLink = React.forwardRef<HTMLAnchorElement, ActionLinkProps>(
  (
    {
      blank,
      unrouted,
      href,
      to = href,
      icon,
      loading,
      image,
      feel = 'link',
      display = feel === 'button' ? 'primary' : undefined,
      unrounded,
      unstyled,
      transparent,
      size = 'm',
      className,
      onClick,
      category,
      action,
      label,
      value,
      children,
      style = {},
      ...rest
    },
    ref
  ) => {
    const analytics = useAnalyticsClient()

    // Hash links should be unrouted because react router
    // does not handle them well.
    const hasHash = (function () {
      switch (typeof to) {
        case 'object':
          return !!to.hash
        case 'string':
          return to.split('#').length > 1
        default:
          return false
      }
    })()

    const classes = combineClasses(
      className,
      styles.ActionLink,
      display ? styles[display] : null,
      styles[size],
      feel === 'link' ? styles.LinkStyle : styles.ButtonStyle,
      unrounded ? styles.unrounded : styles.rounded,
      unstyled ? styles.unstyled : styles.styled,
      transparent ? styles.transparent : null,
      !children && !image ? styles.noText : null,
      icon || loading ? styles.withIcon : null,
      image || style.backgroundImage ? styles.withImage : null
    )

    const mergedStyles = {
      ...style,
      // Allow passing the background image through the styles object.
      backgroundImage: makeBackgroundImage(style.backgroundImage || image),
    }

    if (blank || hasHash || unrouted) {
      const href = typeof to === 'object' ? makeLinkFromObject(to) : to
      const target = blank
        ? { target: '_blank', rel: 'noopener noreferrer' }
        : {}

      if (typeof href === 'function') {
        throw new Error(
          'Cannot use functional `href` props when using `blank` or `unrouted`.'
        )
      }

      // Don't consider mailto, tel or sms links "external" links.
      const external =
        blank && typeof href === 'string' && href.indexOf('http') > -1

      // Handle external link and event tracking.
      /** @param {HTMLElement} e */
      const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (category && action && label && analytics)
          analytics.trackEvent(category, action, label, value)

        if (href && external && analytics) analytics.trackExternalLink(href)

        if (onClick) onClick(e)
      }

      return (
        <a
          className={classes}
          href={href}
          tabIndex={0}
          {...target}
          {...(rest as any as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
          style={mergedStyles as React.CSSProperties}
          children={setChildren(children, icon, loading)}
          ref={ref}
          onClick={handleClick}
        />
      )
    } else {
      const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        // Event tracking will only occur if category, action
        // and label are all set.
        if (analytics && category && action)
          analytics.trackEvent(category, action, label, value)

        if (onClick) onClick(e)
      }

      return (
        <RouterLink
          className={classes}
          data-router-link
          to={to || '#'}
          tabIndex={0}
          {...(rest as any as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
          style={mergedStyles as React.CSSProperties}
          children={setChildren(children, icon, loading)}
          ref={ref}
          onClick={handleClick}
        />
      )
    }
  }
)

interface ActionButtonProps extends Omit<HTMLButtonElement, 'value'> {
  icon?: React.ReactElement
  image?: string
  display?: 'primary' | 'secondary' | 'success' | 'warn' | 'error'
  feel?: 'button' | 'link'
  transparent?: boolean
  unrounded?: boolean
  size?: 's' | 'm' | 'l'
  isToggled?: boolean
  category?: string
  action?: string
  label?: string
  value?: number
  isSelected?: boolean
  loading?: boolean
  unstyled?: boolean
  onClick?: (e: React.MouseEvent) => void
}

/**
 * Renders a standard HTML button, defaulting to type "button"
 */
export const ActionButton = React.forwardRef<
  HTMLButtonElement,
  ActionButtonProps
>(
  (
    {
      className,
      loading,
      icon,
      image,
      feel = 'button',
      display = feel === 'button' ? 'primary' : undefined,
      unrounded,
      unstyled,
      transparent,
      size = 'm',
      category,
      action,
      label,
      value,
      onClick,
      isToggled,
      isSelected,
      style = {},
      children,
      ...rest
    },
    ref
  ) => {
    const analytics = useAnalyticsClient()
    const handleClick = React.useCallback(
      (e: React.MouseEvent) => {
        // Analytics tracking will only occur if category, action
        // and label are all supplied. Value is optional.
        if (analytics && category && action)
          analytics.trackEvent(category, action, label, value)
        if (onClick) onClick(e)
      },
      [onClick, analytics, category, action, label, value]
    )

    const mergedStyles = {
      ...style,
      // Allow passing the background image through the styles object.
      backgroundImage: makeBackgroundImage(style.backgroundImage || image),
    }

    const toggleProps =
      isToggled !== undefined
        ? {
            'aria-checked': isToggled,
            role: 'checkbox',
          }
        : {}

    return (
      <button
        className={combineClasses(
          styles.ActionButton,
          className,
          display ? styles[display] : null,
          styles[size],
          feel === 'link' ? styles.LinkStyle : styles.ButtonStyle,
          unrounded ? styles.unrounded : styles.rounded,
          unstyled ? styles.unstyled : styles.styled,
          transparent ? styles.transparent : null,
          !children ? styles.noText : null,
          icon || loading ? styles.withIcon : null,
          image || style.backgroundImage ? styles.withImage : null,
          isSelected ? styles.isSelected : null,
          isToggled === undefined
            ? null
            : isToggled
            ? styles.toggled
            : styles.untoggled
        )}
        // @ts-ignore: I'm lost on what's causing this error
        type="button"
        {...toggleProps}
        {...(rest as any as React.AnchorHTMLAttributes<HTMLButtonElement>)}
        style={mergedStyles as React.CSSProperties}
        onClick={handleClick}
        children={setChildren(children, icon, loading)}
        ref={ref}
      />
    )
  }
)

// type HTMLButtonOrLink = Omit<HTMLButtonElement, 'value'> & Partial<HTMLAnchorElement>
type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>
type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>
type AnchorButtonProps = AnchorProps & ButtonProps

export type ActionRef = HTMLAnchorElement | HTMLButtonElement

export interface ActionProps extends AnchorButtonProps {
  /**
   * If true, render a `<Button>` component. Otherwise,
   * render a `<Link>`.
   */
  button?: boolean
  /**
   * Either an object as accepted by React Router or a string to
   * use as an href. Only useful if you are rendering a link.
   * See https://reactrouter.com/web/api/Link/to-object for React
   * Router docs.
   */
  to?: string | object
  /**
   * You can also pass an href string like a standard but the `to`
   * prop is used if you pass both. Only useful when rendering links.
   */
  href?: string
  /**
   * __true__: set `target="_blank" rel="noopener noreferrer"`.
   * __false__: link internally using react router.
   * Only useful when rendering links.
   */
  blank?: boolean
  /**
   * __true__: force this link to be a standard HTML link that will not
   * be routed by React Router. Only usefull when rendering links.
   */
  unrouted?: boolean
  /**
   * Render the link/button without the default button styles
   * for cases where you need custom styling of the action. This
   * is also useful for cases where you want to wrap arbitrary elements
   * in an anchor link without applying link/button styling to those
   * elements.
   */
  unstyled?: boolean
  /**
   * An icon to render before the children. For more advanced usecases
   * you can pass the icon in the children prop and handle styling yourself.
   */
  icon?: React.ReactElement
  /**
   * The URL of an image to render as a background behind the button content.
   * If an icon is also passed with no children, the icon will be centered
   * over the image.
   */
  image?: string
  /**
   * The role of this action. "Secondary" is the same as not passing
   * a display.
   */
  display?: 'primary' | 'secondary' | 'success' | 'warn' | 'error'
  /**
   * Whether this action should look like a action or a link.
   * The default depends on whether you are rendering a link or
   * a buttons.
   */
  feel?: 'button' | 'link'
  /**
   * Whether this action should have a transparent background.
   * This is only applicable when using the action feel.
   */
  transparent?: boolean
  /**
   * Whether this action should remove the rounded corners.
   * This is useful when you need the action to fit nicely
   * in a box layout.
   */
  unrounded?: boolean
  /**
   * Sets the size of the content to small, medium or large.
   */
  size?: 's' | 'm' | 'l'
  /**
   * Style the button as a toggle button. Passing true will
   * show the toggled on state and passing false will show
   * the toggled off state.
   */
  isToggled?: boolean
  /**
   * Shows a loading icon in place of the normal `icon` prop.
   */
  loading?: boolean
  /**
   * Analytics event tracking category. This is only used
   * by button actions and event tracking will only occur
   * if category, action and label are all supplied.
   * See https://github.com/react-ga/react-ga#reactgaeventargs
   */
  category?: string
  /**
   * Analytics event tracking action. This is only used
   * by button actions and event tracking will only occur
   * if category, action and label are all supplied.
   * See https://github.com/react-ga/react-ga#reactgaeventargs
   */
  action?: string
  /**
   * Analytics event tracking label. This is only used
   * by button actions and event tracking will only occur
   * if category, action and label are all supplied.
   * See https://github.com/react-ga/react-ga#reactgaeventargs
   */
  label?: string
  /**
   * Analytics event tracking value. This is only used
   * by button actions and event tracking will only occur
   * if category, action and label are all supplied.
   * See https://github.com/react-ga/react-ga#reactgaeventargs
   */
  value?: number
  'data-testid'?: string
  children?: React.ReactNode
  className?: string
}

/**
 * Renders either a button or a link depending on the
 * `isButton` prop.
 */
export const Action = React.forwardRef<ActionRef, ActionProps>(
  ({ button, 'data-testid': testId = 'Action', ...rest }, ref) =>
    button ? (
      <ActionButton
        data-testid={testId}
        {...(rest as any)}
        ref={ref as React.Ref<HTMLButtonElement>}
      />
    ) : (
      <ActionLink
        data-testid={testId}
        {...(rest as any)}
        ref={ref as React.Ref<HTMLAnchorElement>}
      />
    )
)
