import { env } from './env'

/**
 * Give an object logging functionality with correct
 * source line numbers.
 */
export function loggerMixin(item, prefix, debug = true) {
  const bind = prefix
    ? (level) => console[level].bind(window.console, prefix)
    : (level) => console[level].bind(window.console)

  const noop = () => {}

  // Configurable log levels...
  ;['debug', 'log', 'info'].forEach((level) => {
    item[level] = debug ? bind(level) : noop
  })

  // Always log the following...
  item.error = bind('error')
  item.warn = bind('warn')
}

/**
 * A base class you can extend to make new services.
 */
export class ServiceBase {
  constructor(client, debug = env.verbose) {
    this.client = client
    this.listeners = []

    // Setup configurable logging.
    const prefix = `[${this.constructor.name}]`
    loggerMixin(this, prefix, debug)
  }

  addEventListener(name, listener) {
    this.listeners.push({ name, listener })
  }

  removeEventListener(name, listener) {
    this.listeners = this.listeners.filter(
      (l) => l.name === name && l.listener === listener
    )
  }

  emit(name, data) {
    this.listeners.forEach((l) => {
      if (l.name === name) {
        l.listener(data)
      }
    })
  }
}
