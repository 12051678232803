import React from 'react'
import PropTypes from 'prop-types'

import { combineClasses } from '~/util'

import styles from './Label.module.scss'

/**
 * Displays a label for an item. It's almost like a Material Design chip
 * and is intended to call out a piece of data.
 * @param {object} props
 * @param {string} [props.size] - 's', 'm' or 'l'
 * @param {string} [props.display] - The display type of this item
 *   similar to the display prop of Action components (ex. 'primary',
 *   'error', etc.).
 * @param {string} [props.className]
 * @param {*} [props.children]
 */
export function Label({ className, size = 'm', display, children, ...rest }) {
  return (
    <span
      className={combineClasses(
        styles.Label,
        styles[size.toLowerCase()],
        className,
        display ? styles[display] : null
      )}
      data-testid="Label"
      {...rest}
    >
      {children}
    </span>
  )
}

Label.propTypes = {
  /**
   * The size of labe to show. This can be one of ['s','m','l'].
   * The default is 'm'.
   */
  size: PropTypes.oneOf(['s', 'm', 'l']),
}
