import { createSlice } from '@reduxjs/toolkit'

/**
 * @see self-tour.reducer.js for more details about store slice setup.
 */
const pureChatSlice = createSlice({
  name: 'purechat',
  /**
   * @typedef {object} State
   * @prop {number} id
   * @prop {boolean} loaded
   */
  /**
   * @type {State} initialState
   */
  initialState: {
    id: null,
    loaded: false,
  },

  reducers: {
    setPureChatId(draftState, action) {
      draftState.id = action.payload
    },
    setPureChatLoaded(draftState, action) {
      draftState.loaded = true
    },
  },
})

const { actions, reducer } = pureChatSlice

export default reducer

export const { setPureChatId, setPureChatLoaded } = actions

export const select = {
  id(state) {
    return state.id
  },
  loaded(state) {
    return state.loaded
  },
}
