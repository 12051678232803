import React from 'react'
import PropTypes from 'prop-types'
import RcSlider from 'rc-slider'
import { combineClasses } from '@thesoulfresh/utils'

import 'rc-slider/assets/index.css'
import styles from './Slider.module.scss'

/**
 * `<Slider>`
 * Allows the user to crate a slider to select a value within a range of the max and min.
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {number} [props.min]
 * @param {number} [props.max]
 * @param {number} [props.step]
 * @param {number} [props.value]
 * @param {function} [props.onChange]
 */
export function Slider({
  className,
  min = 0,
  max = 1,
  step = 0.01,
  value,
  onChange,
  // @ts-ignore
  'aria-label': ariaLabel,
  // @ts-ignore
  'aria-labelledby': ariaLabelledBy,
  ...rest
}) {
  return (
    <RcSlider
      className={combineClasses(styles.Slider, className)}
      min={min}
      max={max}
      value={value}
      // @ts-ignore
      onChange={onChange}
      step={step}
      ariaLabelForHandle={ariaLabel}
      ariaLabelledByForHandle={ariaLabelledBy}
      {...rest}
    />
  )
}

Slider.propTypes = {
  /**
   * Min slide value
   */
  min: PropTypes.number,
  /**
   * Max slide value
   */
  max: PropTypes.number,
  /**
   * Increment of the slider.
   */
  step: PropTypes.number,
  /**
   * Current value
   */
  value: PropTypes.number,
  /**
   * Change listener accepts a number.
   */
  onChange: PropTypes.func,
  /**
   * Text to inform the screen reader what the component is doing.
   */
  // @ts-ignore
  'aria-label': PropTypes.string,
}
