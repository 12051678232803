export const unknownError = {
  notices: [
    {
      type: 'ERROR',
      message: 'Unknown Error',
      userMessage:
        'An unknown error has occurred. Please refresh and try again.',
    },
  ],
}

export const networkError = {
  notices: [
    {
      type: 'ERROR',
      message: 'Network Connection Error',
      userMessage:
        'We are having trouble connecting to the Internet. Please check your connection and try again.',
    },
  ],
}
