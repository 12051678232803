import React from 'react'
import PropTypes from 'prop-types'

import { combineClasses } from '~/util'

import styles from './MoveInDateForm.module.scss'
import { Title } from '../../titles'
import { TitleValuePairVertical } from '../../titles/title-value-pair'
import { DatePicker } from '../../date-picker'
import { formatDate } from '~/util'

export function DateFormTitleValuePairVertical({
  selectRange,
  earliestDate,
  latestDate,
  earliestDateText,
  latestDateText,
}) {
  return selectRange ? (
    <>
      <TitleValuePairVertical
        data-testid="earliestDate"
        className={styles.TitleValuePairVertical}
        title={earliestDateText}
        value={earliestDate}
        // valueTextSize='m'
        size="m"
      />
      <TitleValuePairVertical
        data-testid="latestDate"
        className={styles.TitleValuePairVertical}
        title={latestDateText}
        value={latestDate}
        // valueTextSize='m'
        size="m"
      />
    </>
  ) : (
    <>
      <TitleValuePairVertical
        data-testid="earliestDate"
        className={styles.TitleValuePairVertical}
        title={earliestDateText}
        value={earliestDate}
        // valueTextSize='m'
        size="m"
      />
    </>
  )
}

/**
 * `<MoveInDateForm>`
 * Form for selected a range of dates.
 *
 * @param {object} props
 * @param {String} [props.className]
 * @param {String} [props.classNameDatePickerContainer]
 * @param {String} [props.earliestDateText]
 * @param {String} [props.latestDateText]
 * @param {string | object | null} [props.earliestDate]
 * @param {string | object | null} [props.latestDate]
 * @param {Date} [props.date]
 * @param {string[] | null} [props.dateRange]
 * @param {Function} [props.onDateChange]
 * @param {Function} [props.onDateRangeChange]
 * @param {Boolean} [props.selectRange]
 * @param {Date} [props.minDate]
 * @param {Date} [props.maxDate]
 */
export function MoveInDateForm({
  className,
  classNameDatePickerContainer,
  earliestDateText = 'Earliest:',
  latestDateText = 'Latest:',
  earliestDate,
  latestDate,
  date,
  dateRange,
  onDateChange,
  onDateRangeChange,
  selectRange,
  minDate = new Date(),
  maxDate = new Date(new Date().setMonth(new Date().getMonth() + 6)),
  ...rest
}) {
  return (
    <div
      data-testid="MoveInDateForm"
      className={combineClasses(styles.MoveInDateForm, className)}
    >
      <Title data-testid="header" className={styles.title}>
        When would you like to move in?
      </Title>
      <DateFormTitleValuePairVertical
        earliestDateText={earliestDateText}
        latestDateText={latestDateText}
        selectRange={selectRange}
        earliestDate={earliestDate ? formatDate(earliestDate) : ''}
        latestDate={latestDate ? formatDate(latestDate) : ''}
      />
      <div className={classNameDatePickerContainer}>
        <DatePicker
          className={styles.DatePicker}
          value={date || dateRange}
          onChange={onDateChange || onDateRangeChange}
          selectRange={selectRange}
          minDate={minDate}
          maxDate={maxDate}
          {...rest}
        />
      </div>
    </div>
  )
}

MoveInDateForm.propTypes = {
  className: PropTypes.string,
  classNameDatePickerContainer: PropTypes.string,
  earliestDateText: PropTypes.string,
  latestDateText: PropTypes.string,
  earliestDate: PropTypes.object,
  latestDate: PropTypes.object,
  date: PropTypes.object,
  dateRange: PropTypes.array,
  onDateChange: PropTypes.func,
  onDateRangeChange: PropTypes.func,
  selectRange: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
}
