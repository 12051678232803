import React from 'react'
import PropTypes from 'prop-types'

import { ANALYTICS_CATEGORIES, useAnalyticsClient } from '~/service'

import { ChatAction, PureChatWithDelay } from '~/components'

const category = ANALYTICS_CATEGORIES.CONTACT
const action = 'Toggle Chat'

/**
 * @typedef {object} ChatButtonProps
 * @property {function} [onClick]
 * @property {string} href
 * @property {string} label
 * @property {boolean} [isOpen]
 * @property {boolean} button
 * @property {string} feel
 * @property {boolean} blank
 * @property {*} [ref]
 */
/**
 * Chat Action styling
 * @type React.FC<ChatButtonProps>
 */
export const ChatButton = React.forwardRef(
  ({ isOpen, label, ...rest }, ref) => {
    return (
      <ChatAction
        data-testid="ChatButton"
        button
        transparent
        size="m"
        category={category}
        action={action}
        label={`${isOpen ? 'Close' : 'Open'} ${label}`}
        {...rest}
        ref={ref}
      />
    )
  }
)

/**
 * Render chat using the PureChat API.
 * @param {object} props
 * @param {string} [props.pureChatId]
 * @param {*} [props.client] - The API client to use
 *   instead of loading the PureChat script tag.
 */
export function PureChat({ pureChatId, client, delay, ...rest }) {
  const label = 'Pure Chat'
  const [isOpen, setIsOpen] = React.useState(false)
  const [isReady, setIsReady] = React.useState(false)

  const analytics = useAnalyticsClient()

  const onToggle = () => setIsOpen((o) => !o)
  const onCloseEvent = () => {
    analytics.trackEvent(category, action, `Close ${label}`)
    setIsOpen(false)
  }
  const onReady = () => setIsReady(true)

  return (
    <>
      {isReady && (
        <ChatButton
          onClick={onToggle}
          isOpen={isOpen}
          label={label}
          {...rest}
        />
      )}
      <PureChatWithDelay
        clientId={pureChatId}
        isOpen={isOpen}
        onClose={onCloseEvent}
        onReady={onReady}
        client={client}
        delay={delay}
      />
    </>
  )
}

PureChat.propTypes = {
  pureChatId: PropTypes.string,
}

/**
 * Render chat as an iframe to a 3rd party chat page.
 * @param {object} props
 * @param {string} props.chatURL
 */
export function StandardChat({ chatURL, ...rest }) {
  const label = 'Standard Chat'

  return (
    <>
      <ChatButton
        button={false}
        feel="button"
        href={chatURL}
        blank
        label={label}
        {...rest}
      />
    </>
  )
}

StandardChat.propTypes = {
  chatURL: PropTypes.string,
}

/**
 * @param {object} props
 * @param {boolean} [props.usePureChat]
 * @param {string | number} [props.pureChatId]
 * @param {string} [props.chatURL]
 * @param {string} [props.pureChatClient]
 * @param {number} [props.pureChatDelay]
 * @param {string} [props.className]
 */
export function Chat({
  chatURL,
  pureChatId,
  usePureChat,
  pureChatClient,
  pureChatDelay,
  ...rest
}) {
  return !usePureChat ? (
    <StandardChat chatURL={chatURL} {...rest} />
  ) : (
    <PureChat
      pureChatId={pureChatId}
      client={pureChatClient}
      delay={pureChatDelay}
      {...rest}
    />
  )
}

Chat.propTypes = {
  /**
   * Whether to use the PureChat API or the
   * iframe API.
   */
  usePureChat: PropTypes.bool.isRequired,
  /**
   * The URL of the chat site to open when not using
   * the PureChat API.
   */
  chatURL: PropTypes.string,
  /**
   * The id of the PureChat client if the community uses
   * PureChat.
   */
  pureChatId: PropTypes.string,
  /**
   * The delay in milliseconds before loading the PureChat API,
   * if using PureChat.
   */
  pureChatDelay: PropTypes.number,
}
