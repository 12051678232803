import React from 'react'
import PropTypes from 'prop-types'
import Truncate from 'react-truncate-html'

import { combineClasses } from '~/util'

import { ItemName } from '../../titles'
import { Label } from '../../labels'
import { Image } from '../../image'
import { Card } from '../Card'

import styles from './AmenityCard.module.scss'

/**
 *
 * @param {object} props
 * @param {string} [props.label] - The marker label associating this card with a map marker.
 * @param {string} [props.name] - The name of the amenity.
 * @param {string} [props.imageUrl]
 * @param {string} [props.description] - This can be HTML conent.
 * @param {string} [props.className]
 */
export function AmenityCard({
  label,
  name,
  imageUrl,
  description,
  className,
  ...rest
}) {
  let cardRef = React.useRef()

  return (
    <Card
      ref={cardRef}
      className={combineClasses(
        styles.AmenityCard,
        className,
        description ? styles.withDescription : null,
        imageUrl ? styles.withImage : null
      )}
      {...rest}
    >
      <div className={styles.content}>
        {label && (
          <Label
            className={styles.label}
            data-testid="amenityCardLabel"
            size="s"
          >
            {label}
          </Label>
        )}
        <div className={styles.details}>
          <ItemName className={styles.title} data-testid="amenityCardTitle">
            {name}
          </ItemName>
          <Truncate
            className={styles.description}
            lines={3}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
      {imageUrl && (
        <Image
          className={styles.thumbnail}
          src={imageUrl}
          alt={`Photo of ${name}`}
          lazy
        />
      )}
    </Card>
  )
}

AmenityCard.propTypes = {
  /**
   * A label used to associate this amenity card with a map marker.
   */
  label: PropTypes.string,
  /**
   * The display name of the amenity.
   */
  name: PropTypes.string.isRequired,
  /**
   * An image to display for this amenity.
   */
  imageUrl: PropTypes.string,
  /**
   * The description of this amenity. This can be HTML content.
   */
  description: PropTypes.string,
  /**
   * Any other props will be applied to the wrapping Card element
   * or it's underlying wrapping HTMLElement.
   */
  'other props...': PropTypes.any,
}
