import { snakeCase } from 'snake-case'

/**
 * A Proxy around `import.meta.env` that allows
 * you to access variables without remembering to
 * prefix the variable names with "VITE_".
 * However, you can still reference them that way
 * if you choose. Additionally, you can expose helpers
 * on this class to simplify using variables.
 *
 * Some Examples:
 * --------------
 * env.name === import.meta.env.VITE_NAME
 * env.VITE_NAME === import.meta.env.VITE_NAME
 * env.production === (import.meta.env.NODE_ENV === 'production')
 */
export class EnvWrapper {
  constructor(env = import.meta.env) {
    const me = this
    // @ts-ignore
    return new Proxy(env, {
      get: function (target, prop, receiver) {
        // If the property is defined on this class, use that.
        if (me[prop] !== undefined) {
          return me[prop]
        }
        // If the property is defined on import.meta.env, use that.
        else if (target[prop] !== undefined) {
          return Reflect.get(target, prop, receiver)
        }
        // If the prop is something like "fooBar",
        // then look for "VITE_FOO_BAR".
        else {
          const snaked = 'VITE_' + snakeCase(prop).toUpperCase()
          if (target[snaked] !== undefined) {
            return Reflect.get(target, snaked, receiver)
          }
        }
      },
    })
  }

  // The environment we are currently running in.
  environment = import.meta.env.NODE_ENV

  // Specific environment flags...
  production = import.meta.env.VITE_ENV === 'production'
  development = import.meta.env.VITE_ENV === 'development'
  test = import.meta.env.VITE_ENV === 'test'

  // version = pkg.version

  // Whether or not to perform verbose logging.
  get verbose() {
    return (
      import.meta.env.VITE_VERBOSE === 'true' &&
      // Allow turning off verbose through a query param.
      // TODO Allow turning verbose logging on in production.
      // TODO Read this at startup and retain the value
      // for the rest of the session.
      window.location.search.indexOf('verbose=false') < 0
    )
  }

  // Whether or not to run against mock APIs.
  mock = import.meta.env.VITE_MOCK_APIS === 'true'

  // The name of the app.
  appName = import.meta.env.VITE_NAME
  appURL = import.meta.env.VITE_APP_BASE_URL || ''
  // The base URL for API calls.
  apiURL = import.meta.env.VITE_API_BASE_URL || ''
  // The API key for accessing the UnitMap API
  unitMapAPIKey = import.meta.env.VITE_UNIT_MAP_KEY
  // The key used to identify PowerPro images in Cloudinary.
  cloudinaryAPIKey = import.meta.env.VITE_CLOUDINARY_KEY || 'powerpro'
  // The guest card uuid to use in dev if none is specified.
  guestCardUUID = import.meta.env.VITE_GUEST_CARD_UUID
  // The tracking id to use for Google Analytics tracking.
  gaTrackingId = import.meta.env.VITE_GA_TRACKING_ID
  // The URL to the app's public folder.
  publicFolder = import.meta.env.PUBLIC_URL
  /** Google Maps API URL */
  googleMapsApi = import.meta.env.VITE_GOOGLE_MAPS_API
  /** Google maps api key */
  googleMapsApiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY
  personaTemplateId = import.meta.env.VITE_PERSONA_TEMPLATE_ID
  personaEnvironmentId = import.meta.env.VITE_PERSONA_ENVIRONMENT_ID

  /**
   * Convert a relative file path in the public folder to
   * an absolute path.
   */
  publicFileURL(filePath) {
    return env.publicFolder + '/' + filePath
  }

  /**
   * Log the environment variables.
   */
  log() {
    // Always log the software version.
    console.log(`[ENV] version ${this.version}`)
    if (!env.production && env.verbose && !env.test) {
      console.log('[ENV] environment:', env.environment)
      for (let key in env) {
        const blacklist = ['log', 'environment', 'graphAPIKey', 'version']
        if (blacklist.indexOf(key) === -1) {
          console.log(`[ENV] ${key}:`, env[key])
        }
      }
    }
  }
}

export const env = new EnvWrapper(import.meta.env)
