import React from 'react'
import ReactDOM from 'react-dom'
import composeRefs from '@seznam/compose-react-refs'

import { combineClasses } from '~/util'
import { DESTINATION_TYPES } from '~/store'

import StarIcon from '~/assets/icons/star.svg?react'

import styles from './Marker.module.scss'

export const haloPadding = 10

/**
 * Convert a marker's numberic dimensions into CSS values.
 * @param {number} width
 * @param {number} height
 * @param {boolean} round - Whether to use a circle shape.
 * @param {number} padding - An amount of padding to add to the dimensions.
 */
function markerDimensions(width, height, round, padding = 0) {
  const w = round && width ? Math.max(width, height) : width
  const h = round ? w : height
  return {
    width: w ? `${w + padding}px` : null,
    height: h ? `${h + padding}px` : null,
  }
}

/**
 * Convert the value to a pixel or percentage string.
 * @param {number} value
 * @param {boolean} pixels
 * @returns {string}
 */
function position(value, pixels) {
  return pixels ? `${value}px` : `${value}%`
}

/**
 * The white halo around the marker to help it stand out from the map.
 * @param {object} props
 * @param {string} props.type
 * @param {number} [props.x]
 * @param {number} [props.y]
 * @param {number} [props.width]
 * @param {number} [props.height]
 * @param {number} [props.scale]
 * @param {boolean} [props.round] - Whether to use a circle shape.
 * @param {number} [props.delay]
 * @param {boolean} [props.inPixels] - true = marker location is in pixels, false = marker location is in percent
 * @param {boolean} [props.show]
 */
export function MarkerHalo({
  x = 0,
  y = 0,
  width = 0,
  height = 0,
  scale = 1,
  round = true,
  type,
  inPixels,
  delay = 0,
  show,
}) {
  const d = markerDimensions(width, height, round)

  return (
    <div
      data-testid="MarkerHalo"
      data-type={type}
      className={combineClasses(
        styles.MarkerHalo,
        round ? styles.round : null,
        show ? styles.ready : null
      )}
      style={{
        left: position(x, inPixels),
        top: position(y, inPixels),
        transform: `translate(-50%, -50%) scale(${scale})`,
      }}
    >
      <div
        data-testid="content"
        className={styles.markerContent}
        style={{
          width: d.width,
          height: d.height,
          transitionDelay: `${delay}ms`,
        }}
      />
    </div>
  )
}

/**
 * The colored background behind the marker.
 * @param {object} props
 * @param {number} [props.x]
 * @param {number} [props.y]
 * @param {number} [props.width]
 * @param {number} [props.height]
 * @param {number} [props.scale]
 * @param {string} [props.type] - One of the DESTINATION_TYPES
 * @param {boolean} [props.round] - Whether to use a circle shape.
 * @param {number} [props.delay]
 * @param {boolean} [props.inPixels] - true = marker location is in pixels, false = marker location is in percent
 * @param {boolean} [props.show]
 * @param {string} [props.className]
 */
export function MarkerBackground({
  x = 0,
  y = 0,
  width = 0,
  height = 0,
  scale = 1,
  type,
  round = true,
  inPixels,
  delay = 0,
  show,
  className,
}) {
  const d = markerDimensions(width, height, round, haloPadding * -1)

  return (
    <div
      data-testid="MarkerBackground"
      className={combineClasses(
        styles.MarkerBackground,
        className,
        round ? styles.round : null,
        show ? styles.ready : null,
        styles[type.toLowerCase()]
      )}
      style={{
        left: position(x, inPixels),
        top: position(y, inPixels),
        transform: `translate(-50%, -50%) scale(${scale})`,
      }}
    >
      <div
        data-testid="content"
        className={styles.markerContent}
        style={{
          width: d.width,
          height: d.height,
          transitionDelay: `${delay}ms, ${delay}ms, 0ms`,
        }}
      />
    </div>
  )
}

/**
 * The foreground text or icon of the marker.
 * @type React.FC<MarkerForegroundProps>
 * @typedef {object} MarkerForegroundProps
 * @property {number} [x]
 * @property {number} [y]
 * @property {number} [scale]
 * @property {string} [type] - One of the DESTINATION_TYPES
 * @property {number} [delay]
 * @property {string} [label] - The text label to display.
 * @property {boolean} [inPixels] - true = marker location is in pixels, false = marker location is in percent
 * @property {boolean} [show]
 * @property {function} [props.onClick]
 * @property {function} [props.onOver]
 * @property {function} [props.onOut]
 * @property {function} [props.onFocus]
 * @property {function} [props.onBlur]
 * @property {*} [ref]
 */
export const MarkerForeground = React.forwardRef(
  (
    {
      x = 0,
      y = 0,
      scale = 1,
      type,
      label = type,
      inPixels,
      delay = 0,
      show,
      onClick,
      onOver,
      onOut,
      onFocus,
      onBlur,
    },
    ref
  ) => {
    const Wrapper = onClick ? 'button' : 'div'

    return (
      <Wrapper
        ref={ref}
        data-testid="MarkerForeground"
        data-type={type}
        className={combineClasses(
          styles.MarkerForeground,
          type === DESTINATION_TYPES.LEASING_OFFICE ? styles.icon : null,
          onClick ? styles.link : null,
          show ? styles.ready : null
        )}
        style={{
          left: position(x, inPixels),
          top: position(y, inPixels),
          transform: `translate(-50%, -50%) scale(${scale})`,
        }}
        onClick={onClick}
        onMouseOver={onOver}
        onMouseOut={onOut}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <span
          data-testid="content"
          className={styles.markerContent}
          arial-label={`${label} Map Marker`}
          style={{
            transitionDelay: `${delay}ms`,
          }}
        >
          {type === DESTINATION_TYPES.LEASING_OFFICE ? (
            <StarIcon data-testid="starIcon" />
          ) : (
            label
          )}
        </span>
      </Wrapper>
    )
  }
)

/**
 * @type React.FC<MarkerProps>
 * @typedef {object} MarkerProps
 * @property {object} [haloLayerRef]
 * @property {object} [backgroundLayerRef]
 * @property {object} [foregroundLayerRef]
 * @property {object} [marker]
 * @property {number} [scale]
 * @property {number} [delay]
 * @property {boolean} [inPixels] - true = marker location is in pixels, false = marker location is in percent
 * @property {function} [onReady]
 * @property {function} [onClick]
 * @property {*} [ref]
 */
export const Marker = React.forwardRef(
  (
    {
      haloLayerRef,
      backgroundLayerRef,
      foregroundLayerRef,
      marker,
      scale = 1,
      delay = 0,
      inPixels,
      onReady,
      onClick,
    },
    ref
  ) => {
    const foregroundRef = React.useRef()
    const [ready, setReady] = React.useState(false)
    const [dimensions, setDimensions] = React.useState()
    const [active, setActive] = React.useState(false)
    const round = marker.type !== DESTINATION_TYPES.UNIT

    const handleClick = onClick
      ? () => {
          if (onClick) onClick(marker)
        }
      : null

    const handleOver = onClick ? () => setActive(true) : null
    const handleOut = onClick ? () => setActive(false) : null

    // Wait until the marker layer DOM nodes are rendered
    // before we try to create portals using them.
    React.useEffect(() => setReady(true), [])

    // Once the foreground marker has been rendered,
    // measure it's dimensions so we know how large
    // to render the background elements.
    React.useEffect(() => {
      if (ready) {
        const bounds = foregroundRef.current.getBoundingClientRect()
        setDimensions({
          width: bounds.width,
          height: bounds.height,
        })
      }
    }, [ready])

    // Emit the ready event after the dimensions have been set.
    React.useEffect(() => {
      if (ready && dimensions && onReady) onReady()
    }, [ready, dimensions, onReady])

    // Render each element of the marker into one
    // of the marker layers. We do this so the marker
    // backgrounds can overlap without obscuring the
    // the marker foreground.
    // TODO Which would be better: createPortal or render?
    return (
      <>
        {ready &&
          ReactDOM.createPortal(
            <MarkerHalo
              x={marker.x}
              y={marker.y}
              width={dimensions?.width}
              height={dimensions?.height}
              scale={scale}
              type={marker.type}
              round={round}
              delay={delay}
              inPixels={inPixels}
              show={!!dimensions}
            />,
            haloLayerRef.current
          )}
        {ready &&
          ReactDOM.createPortal(
            <MarkerBackground
              className={active ? styles.active : null}
              x={marker.x}
              y={marker.y}
              width={dimensions?.width}
              height={dimensions?.height}
              scale={scale}
              type={marker.type}
              round={round}
              delay={delay}
              inPixels={inPixels}
              show={!!dimensions}
            />,
            backgroundLayerRef.current
          )}
        {ready &&
          ReactDOM.createPortal(
            <MarkerForeground
              ref={composeRefs(foregroundRef, ref)}
              x={marker.x}
              y={marker.y}
              scale={scale}
              type={marker.type}
              label={marker.label}
              delay={delay}
              inPixels={inPixels}
              show={!!dimensions}
              onClick={handleClick}
              onOver={handleOver}
              onOut={handleOut}
              onFocus={handleOver}
              onBlur={handleOut}
            />,
            foregroundLayerRef.current
          )}
      </>
    )
  }
)
