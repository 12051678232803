import React from 'react'
import PropTypes from 'prop-types'
import toastedNotes from 'toasted-notes'

import { Action } from '../buttons'
import { combineClasses } from '~/util'

import styles from './Toasts.module.scss'

import XIcon from '~/assets/icons/x.svg?react'

const DEFAULT_DURATION = 3000

const toast = {
  notify(message, duration = DEFAULT_DURATION) {
    toastedNotes.notify(
      ({ onClose }) => <InfoToast message={message} onClose={onClose} />,
      { duration: duration }
    )
  },

  info(message, duration = DEFAULT_DURATION) {
    toastedNotes.notify(
      ({ onClose }) => <InfoToast message={message} onClose={onClose} />,
      { duration: duration }
    )
  },

  success(message, duration = DEFAULT_DURATION) {
    toastedNotes.notify(
      ({ onClose }) => <SuccessToast message={message} onClose={onClose} />,
      { duration: duration }
    )
  },

  warn(message, duration = DEFAULT_DURATION) {
    toastedNotes.notify(
      ({ onClose }) => <WarningToast message={message} onClose={onClose} />,
      { duration: duration }
    )
  },

  error(message, duration = DEFAULT_DURATION) {
    toastedNotes.notify(
      ({ onClose }) => <ErrorToast message={message} onClose={onClose} />,
      { duration: duration }
    )
  },

  closeAll() {
    toastedNotes.closeAll()
  },
}

export default toast

function Toast({ children, display, onClose, className, ...rest }) {
  return (
    <div className={combineClasses(styles.Toast, className)} {...rest}>
      <div className={styles.message}>{children}</div>
      <Action
        button
        feel="button"
        display={display}
        transparent
        onClick={onClose}
        className={styles.closeButton}
        size="s"
        icon={<XIcon />}
      />
    </div>
  )
}

export function InfoToast({ message, onClose }) {
  return (
    <Toast className={styles.InfoToast} onClose={onClose} data-test="infoToast">
      {message}
    </Toast>
  )
}

InfoToast.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export function SuccessToast({ message, onClose }) {
  return (
    <Toast
      className={styles.SuccessToast}
      onClose={onClose}
      data-test="successToast"
      display="success"
    >
      {message}
    </Toast>
  )
}

SuccessToast.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export function WarningToast({ message, onClose }) {
  return (
    <Toast
      className={styles.WarningToast}
      onClose={onClose}
      data-test="warningToast"
      display="warn"
    >
      {message}
    </Toast>
  )
}

WarningToast.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export function ErrorToast({ message, onClose }) {
  return (
    <Toast
      className={styles.ErrorToast}
      onClose={onClose}
      data-test="errorToast"
      display="error"
    >
      {message}
    </Toast>
  )
}

ErrorToast.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}
