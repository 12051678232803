import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import useLockBodyScroll from 'react-use/lib/useLockBodyScroll'

import { combineClasses } from '~/util'
import { CloseAction } from '../buttons'

import styles from './Modal.module.scss'

// Setting the app element is already taken care of by
// the app but exposing it for easy use in storybook stories.
/**
 * @param {HTMLElement|string} el
 */
export function setAppElement(el) {
  ReactModal.setAppElement(el)
}

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {function} props.setIsOpen
 * @param {string} props.label
 * @param {function} [props.onAfterOpen]
 * @param {function} [props.onAfterClose]
 * @param {string} [props.className]
 * @param {boolean} [props.closeOnBackgroundClick]
 * @param {object} [props.analytics]
 * @param {string} [props.analytics.category]
 * @param {string} [props.analytics.action]
 * @param {string} [props.analytics.label]
 * @param {number} [props.analytics.value]
 * @param {boolean} [props.light]
 * @param {boolean} [props.fullscreen]
 * @param {boolean} [props.lockBodyScroll] - Whether to lock body scrolling when
 *   the modal is open.
 * @param {*} props.children
 */
export function Modal({
  'data-testid': tid,
  testId = tid,
  isOpen,
  setIsOpen,
  onAfterOpen,
  onAfterClose,
  className,
  overlayClassName,
  closeButtonClassName,
  label,
  closeOnBackgroundClick,
  analytics = {},
  light,
  fullscreen,
  lockBodyScroll = true,
  children,
  ...rest
}) {
  const handleClose = () => {
    setIsOpen && setIsOpen(false)
    // For now we call this immediately but eventually it
    // should be called after animating out.
    onAfterClose && onAfterClose()
  }

  // Ensure that the background page does not scroll.
  useLockBodyScroll(lockBodyScroll && isOpen)

  return (
    <ReactModal
      testId={testId}
      className={combineClasses(
        styles.Modal,
        light === true ? styles.whiteBackGround : ''
      )}
      overlayClassName={combineClasses(
        styles.overlay,
        light === true && styles.blackText,
        fullscreen && styles.fullscreen,
        overlayClassName
      )}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={handleClose}
      contentLabel={label}
      {...rest}
    >
      <div
        data-testid="Modal"
        className={styles.backdrop}
        onClick={() => closeOnBackgroundClick && handleClose()}
      >
        {setIsOpen && (
          <CloseAction
            data-testid="closeButton"
            button
            transparent
            size="l"
            feel="link"
            className={styles.closeButton}
            onClick={handleClose}
            {...analytics}
          />
        )}
        <div className={combineClasses(styles.contentWrapper, className)}>
          {children}
        </div>
      </div>
    </ReactModal>
  )
}

Modal.propTypes = {
  /**
   * Whether or not the modal is currently open.
   * You should always render the modal and use this
   * prop to specify whether the modal is shown or not.
   * This allows for animation of the modal.
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * A function that allows the modal to set the
   * isOpen state so the modal can be closed when the
   * x button is pressed.
   */
  setIsOpen: PropTypes.func.isRequired,
  /**
   * A callback that the modal will call once the
   * modal has finished animating in. This can be
   * useful in tests.
   */
  onAfterOpen: PropTypes.func,
  /**
   * A callback that the modal will call once the
   * modal has finished animating out. This can be
   * useful in tests.
   */
  onAfterClose: PropTypes.func,
  /**
   * The name of this modal that will be read to screen readers.
   * This will be used as the aria-label of the modal.
   */
  label: PropTypes.string.isRequired,
  /**
   * Allow the modal to be closed by click on the the background
   * behind the content.
   */
  closeOnBackgroundClick: PropTypes.bool,
}
