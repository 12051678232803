import React from 'react'
import PropTypes from 'prop-types'

import { combineClasses } from '~/util'

import { Label } from '../../labels'
import { ItemName } from '../../titles'
import { Card } from '../Card'

import StarIcon from '~/assets/icons/star.svg?react'

import styles from './LeasingOfficeCard.module.scss'

/**
 * Displays summary information about a leasing office.
 *
 * @param {object} props
 * @param {string} [props.name] - The name of this leasing office.
 *   Defaults to 'Leasing Office' if not specified.
 * @param {string} [props.className]
 * @param {boolean} [props.transparent] - Using this flag will remove
 *   the default background color and borders.
 */
export function LeasingOfficeCard({
  name = 'Leasing Office',
  transparent,
  className,
  ...rest
}) {
  let cardRef = React.useRef()

  return (
    <Card
      ref={cardRef}
      data-testid="LeasingOfficeCard"
      className={combineClasses(styles.LeasingOfficeCard, className)}
      transparent={transparent}
      {...rest}
    >
      <Label
        className={styles.label}
        size="s"
        children={<StarIcon />}
        display="error"
      />
      <ItemName className={styles.title} data-testid="leasingOfficeCardTitle">
        {name}
      </ItemName>
    </Card>
  )
}

LeasingOfficeCard.propTypes = {
  /**
   * A more specific name for this leasing office.
   */
  name: PropTypes.string,
  /**
   * When true, the card background will be transparent.
   */
  transparent: PropTypes.bool,
}
