import React from 'react'
import PropTypes from 'prop-types'

import { formatPhoneNumberURL, formatLink, combineClasses } from '~/util'
import {
  Action,
  SubTitle,
  CallAction,
  EmailAction,
  ExternalLinkAction,
  SMSAction,
} from '~/components'
import { ANALYTICS_CATEGORIES } from '~/service'

import QuestionsIcon from '~/assets/icons/questions.svg?react'

import styles from './Footer.module.scss'

/**
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} props.communityName
 * @param {string} [props.communityPhoneNumber]
 * @param {string} [props.communityEmail]
 * @param {string} [props.communityWebsite]
 * @param {string} [props.licenseNumber]
 * @param {string} [props.communityContactName]
 * @param {string} [props.communitySmsPhoneNumber]
 */
export function Footer({
  communityName,
  communityPhoneNumber,
  communityEmail,
  communityWebsite,
  licenseNumber,
  communityContactName,
  communitySmsPhoneNumber,
  className,
  ...rest
}) {
  const date = new Date()
  const year = date.getFullYear()

  return (
    <div
      data-testid="Footer"
      className={combineClasses(styles.Footer, className)}
      {...rest}
    >
      <div className={styles.contactContainer}>
        <QuestionsIcon className={styles.questionsIcon} />

        <SubTitle className={styles.title}>
          Anything else we can help you with today?
        </SubTitle>
        {/* Phone Link */}
        {communityPhoneNumber && (
          <CallAction
            className={styles.contactLink}
            href={formatPhoneNumberURL(communityPhoneNumber, 'tel')}
            unrouted
            category={ANALYTICS_CATEGORIES.CONTACT}
            action="Call Community"
            label="Footer Call Button"
          >
            {communityPhoneNumber}
          </CallAction>
        )}

        {/* EMail Link */}
        {communityEmail && (
          <EmailAction
            className={styles.contactLink}
            href={`mailto:${communityEmail}`}
            blank
            category={ANALYTICS_CATEGORIES.CONTACT}
            action="Email Community"
            label="Footer Email Button"
          >
            {communityEmail}
          </EmailAction>
        )}

        {/* SMS Link */}
        {communitySmsPhoneNumber && (
          <SMSAction
            className={styles.contactLink}
            href={formatPhoneNumberURL(communitySmsPhoneNumber, 'sms')}
            unrouted
            category={ANALYTICS_CATEGORIES.CONTACT}
            action="Text Community"
            label="Footer Text Button"
          >
            Message {communityContactName} {communitySmsPhoneNumber}
          </SMSAction>
        )}

        {/* Website Link */}
        {communityWebsite && (
          <ExternalLinkAction
            className={styles.contactLink}
            href={formatLink(communityWebsite)}
            blank
          >
            {communityWebsite}
          </ExternalLinkAction>
        )}
      </div>

      <div className={styles.communityLine}>
        <Action
          href="http://portal.hud.gov/hudportal/HUD?src=/program_offices/fair_housing_equal_opp"
          className={styles.accessibilityLink}
          title="Fair Housing and Equal Opportunity"
          blank
        >
          <img
            src="https://s3.amazonaws.com/powerpro/email/images/EHO_Logo_White.png"
            alt="Fair Housing and Equal Opportunity"
          />
        </Action>
        <Action
          href="http://portal.hud.gov/hudportal/HUD"
          className={styles.accessibilityLink}
          title="Housing And Urban Development Website"
          blank
        >
          <img
            src="https://s3.amazonaws.com/powerpro/email/images/WheelchairSymbol_White.png"
            alt="Housing And Urban Development Website"
          />
        </Action>
        {licenseNumber && (
          <span className={styles.licenseNumber}>
            {`License #: ${licenseNumber}`}
          </span>
        )}
        <div className={styles.copyrightLine}>
          <Action href="http://www.powerproleasing.com" blank>
            www.powerproleasing.com
          </Action>
          &nbsp;&copy;{year} Power Pro{' '}
          <span className={styles.tagLine}>Leasing Elevated</span>
          <p className={styles.allRightsReserved}>All rights reserved.</p>
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  communityName: PropTypes.string,
  communityPhoneNumber: PropTypes.string,
  communityEmail: PropTypes.string,
  communityWebsite: PropTypes.string,
  licenseNumber: PropTypes.string,
}
