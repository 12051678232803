import React from 'react'
import composeRefs from '@seznam/compose-react-refs'

import { combineClasses } from '~/util'
import { DESTINATION_TYPES } from '~/store'
import { Marker } from './Marker'

import styles from './MarkerLayer.module.scss'

/**
 * Returns intersecting part of two rectangles
 * @param  {object} r1 bounding client rect 1
 * @param  {object} r2 bounding client rect 2
 * @returns {boolean|object} False if there's no intersecting part
 *   4 coordinates in form of {x1, y1, x2, y2} object
 */
export function getIntersectingRectangle(r1, r2) {
  ;[r1, r2] = [r1, r2].map((r) => {
    return { x: [r.left, r.right].sort(), y: [r.top, r.bottom].sort() }
  })

  const noIntersect =
    r2.x[0] > r1.x[1] ||
    r2.x[1] < r1.x[0] ||
    r2.y[0] > r1.y[1] ||
    r2.y[1] < r1.y[0]

  return noIntersect
    ? false
    : {
        x1: Math.max(r1.x[0], r2.x[0]), // _[0] is the lesser,
        y1: Math.max(r1.y[0], r2.y[0]), // _[1] is the greater
        x2: Math.min(r1.x[1], r2.x[1]),
        y2: Math.min(r1.y[1], r2.y[1]),
      }
}

/* Calculate overlapping markers.
   * Come back to this when there's time.
   * I tried using an intersection observer but that didn't seem
   * to work. It might have been my code though and might be worth
   * another try because it would run in a separate thread.
  const [offsets, setOffsets] = React.useState([]);
  React.useEffect(() => {
    if (ready) {
      const offsets = markerRefs.current.map((ref, i) => {
        const element = ref.current;
        const b = element.getBoundingClientRect();

        // Return the intersection of this element with
        // all other elements.
        return markerRefs.current
          .filter(r => r !== ref)
          .map(r => {
            if (r.current) {
              const b2 = r.current?.getBoundingClientRect();
              const inX = (b.top > b2.top && b.top < b2.bottom) ||
                          (b.bottom > b2.top && b.bottom < b2.bottom);
              const inY = (b.left > b2.left && b.left < b2.right) ||
                          (b.right > b2.left && b.right < b2.left);
              if (inX && inY) {
                console.log('intersecting elements:', element, r.current);
                return getIntersectingRectangle(b, b2);
              }
            }

            return null;
          });
      });

      console.log('offsets:', offsets);

      // Check if the offsets have changed since the last time.
      // If changed, set the new offsets.
      // Use the new offsets to shift the markers around.
    }
  }, [ready, scale]);
  */

/**
 * @param {object} props
 * @param {object[]} [props.markers]
 * @param {number} [props.markerScale] - The scale at which to render the markers.
 * @param {number} [props.markerDelay] - The amount of time to delay revealing each marker.
 * @param {number} [props.initialDelay] - The amount of time to wait before the first marker is shown.
 * @param {string} [props.className]
 * @param {object} [props.firstFocusableRef] - The first element in this marker layer that can receive
 *   keyboard focus. Useful if this layer is part of a focus trap.
 * @param {boolean} [props.inPixels] - true = marker location is in pixels, false = marker location is in percent
 * @param {function} [props.onReady] - Called after all markers have been rendered.
 * @param {function} [props.onUnitClick]
 * @param {function} [props.onAmenityClick]
 * @param {boolean} [props.animated]
 */
export function MarkerLayer({
  animated = true,
  markers,
  markerScale,
  markerDelay = animated ? 100 : 0,
  initialDelay = 0,
  inPixels,
  onReady,
  onUnitClick,
  onAmenityClick,
  className,
  firstFocusableRef,
  ...rest
}) {
  // Ensure the initial delay is removed if animations are disabled.
  if (!animated) initialDelay = 0

  const [ready, setReady] = React.useState(false)
  const markerRefs = React.useRef([])
  const haloLayerRef = React.useRef()
  const backgroundLayerRef = React.useRef()
  const foregroundLayerRef = React.useRef()

  // Generate a list of refs tracking each of the markers.
  markerRefs.current = markers.map((m, i) => {
    return markerRefs.current.length > i
      ? markerRefs.current[i]
      : React.createRef()
  })

  // handleReady will get called many times in a short period of time
  // and we don't want to rerender that frequently so we use a
  // ref (instead of a state variable) to avoid unnecessary renders.
  const readyCountRef = React.useRef(0)
  const handleReady = () => {
    if (!ready) {
      readyCountRef.current += 1
      if (readyCountRef.current === markers.length) {
        setReady(true)
        if (onReady) onReady()
      }
    }
  }

  // If the marker list is empty, call the ready callback immediately.
  React.useEffect(() => {
    if (!ready && markers.length === 0) {
      setReady(true)
      if (onReady) onReady()
    }
  }, [markers, ready, onReady])

  return (
    <div
      data-testid="MarkerLayer"
      className={combineClasses(styles.MarkerLayer, className)}
      {...rest}
    >
      <div
        data-testid="haloLayer"
        className={combineClasses(styles.layer, styles.haloLayer)}
        ref={haloLayerRef}
      />
      <div
        data-testid="backgroundLayer"
        className={combineClasses(styles.layer, styles.backgroundLayer)}
        ref={backgroundLayerRef}
      />
      <div
        data-testid="foregroundLayer"
        className={combineClasses(styles.layer, styles.foregroundLayer)}
        ref={foregroundLayerRef}
      />
      {markers.map((m, i) => (
        <Marker
          key={m.item.id}
          haloLayerRef={haloLayerRef}
          backgroundLayerRef={backgroundLayerRef}
          foregroundLayerRef={foregroundLayerRef}
          marker={m}
          scale={markerScale}
          delay={initialDelay + i * markerDelay}
          inPixels={inPixels}
          ref={
            i === 0
              ? composeRefs(firstFocusableRef, markerRefs.current[i])
              : markerRefs.current[i]
          }
          onReady={handleReady}
          onClick={
            m.type === DESTINATION_TYPES.UNIT && onUnitClick
              ? onUnitClick
              : m.type === DESTINATION_TYPES.AMENITY && onAmenityClick
              ? onAmenityClick
              : undefined
          }
        />
      ))}
    </div>
  )
}
